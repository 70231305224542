import * as Sentry from "@sentry/browser"
import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"

export default class extends ApplicationController {
  static targets = ["klarnaPlaceholder"]

  connect() {
    this._fetchKlarnaUI(this.data.get("subscription"))
  }

  _fetchKlarnaUI(product) {
    const data = {
      org_number: this.data.get("orgNumber"),
      product: product,
      current_url: window.location.href,
      user_id: this.data.get("userId"),
      source: this.data.get("source"),
    }

    const response = this.railsCreate(this.data.get("klarnaUrl"), data)
    this._handleResponse(response)
  }

  _handleResponse(response) {
    response.then((value) => {
      try {
        $("#klarna-placeholder").html(value.html)
      } catch (error) {
        Sentry.captureException(error)
        this.klarnaPlaceholderTarget.innerHTML =
          "Något gick fel, prova att ladda om sidan."
      }
    })
  }
}

export default function apartmentsFiltering() {
  /*
      Setup apartment filtering at a given adress.
    */
  const filterApartments = (address) => {
    $("#apartment-valuation-apartment").val("Välj lägenhet")
    $("#monthly-fee").val("")

    $("#apartment-valuation-apartment option").each((index, element) => {
      const currentOption = $(element).attr("data")

      // Safari - Chrome - Fallback
      if (currentOption === address) {
        if ($(element).parent().is("span")) $(element).unwrap()
        $(element).removeAttr("hidden")
        $(element).removeAttr("disabled")
      } else if ($(element).attr("value") !== "Välj lägenhet") {
        if (!$(element).parent().is("span")) $(element).wrap("<span>")
        $(element).attr("hidden", "true")
        $(element).attr("disabled", "true")
      }
    })
  }

  const updateMonthlyFee = (selection) => {
    const apartmentSize = $(selection).attr("size")
    const feePerM2 = $("#monthly-fee").data().yearlyFee
    const apartmentFee = Math.round((apartmentSize * feePerM2) / 12)

    if (!feePerM2) {
      return
    }

    $("#monthly-fee").val(apartmentFee)
    $("#monthly-fee-comparison").val(apartmentFee)
  }

  /*
      Checks if there is only one address in the organization, includes default option.
    */
  if ($("#apartment-valuation-address option").length == 2) {
    $("#apartment-valuation-address option")[1].selected = true
    const address = $("#apartment-valuation-address option").eq(1).attr("data")
    filterApartments(address)
  }

  $("#apartment-valuation-address").change((e) => {
    const selection = $(e.currentTarget.options[e.currentTarget.selectedIndex])
    const address = selection.attr("data")
    filterApartments(address)
  })

  $("#apartment-valuation-apartment").change((e) => {
    const selection = $(e.currentTarget.options[e.currentTarget.selectedIndex])
    updateMonthlyFee(selection)
  })

  $(document).ready(() => {
    const address = $("#apartment-valuation-apartment option:selected").last()
    if (address.val() != "Välj lägenhet") {
      updateMonthlyFee(address)
    }
  })
}

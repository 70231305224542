import { ApplicationController } from "../../../../../../../app/webpack_assets/support/application_controller"
import { TabulatorFull as Tabulator } from "tabulator-tables"
import { tabulatorSV } from "../../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"

export default class extends ApplicationController {
  static targets = ["subscriptionCancel"]

  connect() {
    this._handleSubscriptionInfo()
  }

  cancelSubscription() {
    const payload = { user_email: this.data.get("userEmail") }

    this.railsCreate(this.data.get("cancelUrl"), payload).then(() => {
      location.reload()
    })
  }

  _handleSubscriptionInfo() {
    const payload = { user_email: this.data.get("userEmail") }

    this.railsFetch(this.data.get("productsUrl"), payload).then((response) => {
      if (response.status === "ok") {
        this._initializeTabulator(response)
        response.subscription_status === "active"
          ? this._showCancelButton()
          : this._showPurchaseButton()
      } else {
        this._showPurchaseButton()
      }
    })
  }

  _initializeTabulator(subscriptionData) {
    var tableData = [subscriptionData]
    tableData[0].type = "Abonnemang av BRF-analyser"

    new Tabulator("#brfanalys-subscription-table", {
      data: tableData,
      layout: "fitDataFill",
      printRowRange: "selected",
      addRowPos: "top",
      initialSort: [{ column: "start_date", dir: "asc" }],
      columns: [
        {
          title: "Period start",
          field: "start_date",
          cssClass: "centerAlignedContent",
        },
        {
          title: "Period slut",
          field: "expiration_date",
          cssClass: "centerAlignedContent",
        },
        {
          title: "Typ av abonnemang",
          field: "type",
          cssClass: "centerAlignedContent",
        },
        {
          title: "Status",
          field: "subscription_status",
          cssClass: "centerAlignedContent",
          formatter: function (cell) {
            const data = cell.getData()
            switch (data.subscription_status) {
              case "active":
                return "Aktiv"
              case "cancelled":
                return "Förlängning avslutad"
              case "expired":
                return "Avslutad"
              case "failed":
                return "Förlängning misslyckad"
              default:
                return ""
            }
          },
        },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })
  }

  _showCancelButton() {
    var button = document.getElementById("subscription-cancel")
    button.style.display = "block"
  }

  _showPurchaseButton() {
    var button = document.getElementById("subscription-purchase")
    button.style.display = "block"
  }
}

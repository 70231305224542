import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import { inquiryFormHandler } from "../modules/inquiry_form"

export default class extends ApplicationController {
  connect() {
    this._sideMenu()

    document
      .getElementById("hamburger")
      .addEventListener("click", this._toggleNav)
    window.addEventListener("resize", this._closeNav)
  }

  _toggleNav() {
    document.getElementById("sideNav").classList.toggle("active")
    document.querySelector("main").classList.toggle("menu-opened")
    document.querySelector("footer").classList.toggle("menu-opened")
    document.getElementById("hamburger").classList.toggle("hamburger-close")
    document.querySelector("body").classList.toggle("hide-overflow")
  }

  _closeNav() {
    document.getElementById("sideNav").classList.remove("active")
    document.querySelector("nav").classList.toggle("menu-opened")
    document.querySelector("main").classList.toggle("menu-opened")
    document.querySelector("footer").classList.toggle("menu-opened")
    document.getElementById("hamburger").classList.remove("hamburger-close")
    document.querySelector("body").classList.remove("hide-overflow")
  }

  _sideMenu() {
    const sideMenuItems = document.querySelectorAll(".sideCategory")

    sideMenuItems.forEach((sideMenuItem) => {
      sideMenuItem.addEventListener("click", function () {
        sideMenuItems.forEach(function (otherItems) {
          if (otherItems != sideMenuItem) {
            otherItems.classList.remove("submenu-active")
          }
        })

        sideMenuItem.classList.toggle("submenu-active")
      })
    })
  }

  inquirySubmission(event) {
    const [data, status, xhr] = event.detail
    inquiryFormHandler(event.target, JSON.parse(data.response))
  }
}

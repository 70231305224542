import { ApplicationController } from "../support/application_controller"
import * as Sentry from "@sentry/browser"

export default class extends ApplicationController {
  static targets = ["form", "statusMessage"]

  create(e) {
    e.preventDefault()
    const form = this.formTarget

    // Trigger html5 validations
    if (!form.checkValidity()) {
      // Create the temporary button, click and remove it
      const tmpSubmit = document.createElement("button")
      form.appendChild(tmpSubmit)
      tmpSubmit.click()
      form.removeChild(tmpSubmit)
    } else {
      const formData = new FormData(form)
      const url = form.action
      const response = this.railsCreate(url, formData, true)

      this._handleSaveResponse(response)
    }
  }

  _handleSaveResponse(response) {
    response.then((value) => {
      try {
        if (value.message) {
          this.statusMessageTarget.innerHTML = value.message
          this.statusMessageTarget.classList.remove("hidden")
        }

        if (value.location) {
          setTimeout(window.location.replace(value.location), 1)
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    })
  }
}

import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import * as Sentry from "@sentry/browser"

export default class extends ApplicationController {
  static targets = ["alertContainer", "form", "formButton"]

  connect() {
    setupModal("contact-modal")
  }

  submit(e) {
    e.preventDefault()

    // ensuring that form won't be submitted multiple times
    this._disableFormButton()

    const formData = new FormData(this.formTarget)
    const response = this.railsCreate(
      this.data.get("message-url"),
      formData,
      true
    )

    this._handleResponse(response)
  }

  _handleResponse(response) {
    response.then((value) => {
      try {
        switch (value.status) {
          case "done":
            this.formTarget.innerHTML =
              '<div class="body"><div class="modal_checkmark--green">&#10003;<div/>' +
              "<p>" +
              value.message +
              "</p></div>"
            break
          case "error":
            this.alertContainerTarget.innerHTML = value.message
            this.alertContainerTarget.classList.remove("hidden")

            // re-enabling the button in case of errors
            this._enableFormButton()
            break
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    })
  }

  _disableFormButton() {
    this.formButtonTarget.classList.add("not-clickable")
    this.formButtonTarget.setAttribute("disabled", "true")
  }

  _enableFormButton() {
    this.formButtonTarget.classList.remove("not-clickable")
    this.formButtonTarget.removeAttribute("disabled")
  }
}

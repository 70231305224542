import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"

export default class extends ApplicationController {
  static targets = [
    "form",
    "formSuccess",
    "formError",
    "name",
    "ssNumber",
    "address",
    "zipcode",
    "town",
  ]

  prefillBoardMemberInfo(event) {
    const dataSet = event.currentTarget.selectedOptions[0].dataset
    if ("ss_number" in dataSet) {
      this.clearAndDisableNameField()
    } else {
      this.enableNameField()
    }

    this.ssNumberTarget.value = dataSet.ss_number || ""
    this.addressTarget.value = dataSet.address || ""
    this.zipcodeTarget.value = dataSet.postal_code || ""
    this.townTarget.value = dataSet.city || ""
  }

  clearAndDisableNameField() {
    this.nameTarget.value = ""
    this.nameTarget.disabled = true
  }

  enableNameField() {
    this.nameTarget.disabled = false
  }

  submitSuccess() {
    this.formTarget.reset()
    this.formTarget.classList.add("hidden")
    this.formErrorTarget.classList.add("hidden")
    this.formSuccessTarget.classList.remove("hidden")

    setTimeout(() => {
      window.location.reload()
    }, 5000)
  }

  submitError() {
    this.formErrorTarget.classList.remove("hidden")
    window.scrollTo(0, 0)
  }
}

import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  calculateTotalPages,
  tabulatorSV,
} from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { TabulatorFull as Tabulator } from "tabulator-tables"
import * as Sentry from "@sentry/browser"
import * as XLSX from "xlsx"

export default class extends ApplicationController {
  static targets = [
    "lastUpdatePlaceholder",
    "totalsContainer",
    "totalsCountPlaceholder",
    "totalsSumPlaceholder",
    "emptyDatePlaceholder",
  ]

  connect() {
    this._fetchCustomerData()
    this._initializeTabulator()
  }

  _fetchCustomerData() {
    const url = this.data.get("customer-api-url")
    this.railsFetch(url).then((value) => {
      try {
        const customerData = value.data.attributes
        if (customerData.invoices_updated_at) {
          this.lastUpdatePlaceholderTarget.querySelector("b").innerHTML =
            new Date(customerData.invoices_updated_at).toLocaleDateString(
              "sv-se"
            )
          this.lastUpdatePlaceholderTarget.classList.remove("hidden")
          this.emptyDatePlaceholderTarget.classList.add("hidden")
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    })
  }

  _initializeTabulator() {
    const pageSize = 50

    this.invoicelistTable = new Tabulator("#invoice-table", {
      layout: "fitDataFill",
      addRowPos: "top",
      ajaxURL: this.data.get("api-url"),
      ajaxParams: { status: "overdue", page_size: pageSize },
      ajaxResponse: (_url, _params, response) => {
        this._printTotals(response)
        this.table_rowCount = response["meta"].total
        return this._transformResponse(response, pageSize)
      },
      pagination: true,
      paginationMode: "remote",
      paginationSize: pageSize,
      initialSort: [{ column: "period", dir: "asc" }],
      columns: [
        { title: "Period", field: "period", widthGrow: 0.8, frozen: true },
        { title: "Namn", field: "resident_name", widthGrow: 2.6 },
        {
          title: "Belopp (SEK)",
          field: "price",
          widthGrow: 0.8,
          formatter: (cell, _formattedParams, _onRendered) => {
            const value = parseInt(cell.getValue())
            return value.toLocaleString("sv-se", { useGrouping: true })
          },
        },
        {
          title: "Status",
          field: "status",
          widthGrow: 0.8,
          formatter: (cell, _formattedParams, _onRendered) => {
            switch (cell.getValue()) {
              case "overdue":
                return "<span class='overdue'></span><span>Försenad</span>"
              case "unpaid":
                return "<span class='unpaid'></span><span>Obetald</span>"
              case "paid":
                return "Betald"
              default:
                return cell.getValue()
            }
          },
        },
        {
          title: "Avi",
          field: "pdf",
          headerSort: false,
          download: false,
          formatter: (cell, _formattedParams, _onRendered) => {
            return `<a href='${cell.getValue()}' target='_blank'><span class='invoice'></span></a>`
          },
        },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })
    window.XLSX = XLSX
  }

  exportTable(event) {
    event.preventDefault()

    let table = this.invoicelistTable
    let page = table.getPage()
    let pageSize = table.getPageSize()
    table.setPageSize(this.table_rowCount)

    table.setPage(1).then(function () {
      table.download("xlsx", "Obetalda_Avgifter.xlsx", {
        documentProcessing: function (workbook) {
          var sheet = workbook.Sheets.Sheet1

          XLSX.utils.sheet_add_aoa(
            sheet,
            [["Period", "Namn", "Belopp(SEK)", "Status"]],
            {
              origin: 0,
            }
          )

          var range = XLSX.utils.decode_range(sheet["!ref"])
          for (var Row = range.s.r; Row <= range.e.r; ++Row) {
            for (var Column = range.s.c; Column <= range.e.c; ++Column) {
              var cellref = XLSX.utils.encode_cell({
                c: Column,
                r: Row,
              })

              if (!sheet[cellref]) continue

              var cell = sheet[cellref]

              if (!(cell.t == "s" || cell.t == "str")) continue
              if (cell.v === "overdue") cell.v = "Försenad"
              if (cell.v === "paid") cell.v = "Betald"
              if (cell.v === "unpaid") cell.v = "Obetald"
            }
          }

          return workbook
        },
      })
      table.clearData()
      table.setPageSize(pageSize)
      table.setPage(page)
      table.restoreRedraw()
    })
  }

  _printTotals(response) {
    let sum = response.meta.invoice_sum
    sum = sum.toLocaleString("sv-se", { useGrouping: true })

    this.totalsContainerTarget.classList.remove("hidden")
    this.totalsCountPlaceholderTarget.innerHTML = response.meta.total
    this.totalsSumPlaceholderTarget.innerHTML = `Totalt: <b>${sum}</b> SEK`
  }

  _transformResponse(response, pageSize) {
    const arr = []

    response.data.forEach((row) => {
      ;(row.attributes.id = row.id),
        (row.attributes.pdf =
          this.data.get("apiUrl") + "/" + row.id + "/preview")

      arr.push(row.attributes)
    })

    return { last_page: calculateTotalPages(response, pageSize), data: arr }
  }
}

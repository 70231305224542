import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  nullToEmptyString,
  selectColumns,
  tabulatorSV,
} from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { loadActiveTabContents } from "../modules/register/register_tabs"
import { buildPaginatedResidentTable } from "../modules/register/register_resident_functions"
import * as XLSX from "xlsx"
import { TabulatorFull as Tabulator } from "tabulator-tables"
import { sanitizeXlsxExport } from "../../../../../shared_assets/app/webpack_assets/modules/sanitize_xlsx_export"

export default class extends ApplicationController {
  connect() {
    loadActiveTabContents(
      "[data-tab=register-members]",
      "#register-members-table",
      this._conditionalConnect.bind(this)
    )
  }

  _conditionalConnect() {
    if (window.residentsTable) {
      window.residentsTable.redraw(true)
      return
    }

    this._initializeTabulator()
    this._fetchData()
  }

  async _fetchData() {
    const pageSize = 25
    const url =
      this.data.get("api-url") +
      `?resident_types=member,board_member&active=true&page_size=${pageSize}`

    await buildPaginatedResidentTable(url, window.residentsTable, pageSize)
  }

  _initializeTabulator() {
    const headerMenu = function () {
      const columns = this.getColumns()
      return selectColumns(columns)
    }

    window.residentsTable = new Tabulator("#register-members-table", {
      layout: "fitDataFill",
      initialSort: [{ column: "name", dir: "asc" }],
      height: "100vh",
      columns: [
        {
          title: "Namn",
          field: "name",
          accessorDownload: nullToEmptyString,
          headerContextMenu: headerMenu,
        },
        {
          title: "Pnr.",
          field: "ss_number",
          accessorDownload: nullToEmptyString,
          headerContextMenu: headerMenu,
        },
        {
          title: "Adress",
          field: "address",
          accessorDownload: nullToEmptyString,
          headerContextMenu: headerMenu,
        },
        {
          title: "E-post",
          field: "combined_email",
          accessorDownload: nullToEmptyString,
          headerContextMenu: headerMenu,
        },
        {
          title: "Tel.",
          field: "phone_number",
          accessorDownload: nullToEmptyString,
          headerContextMenu: headerMenu,
        },
        {
          title: "Objekt",
          field: "property_ids_with_types_and_shares",
          accessorDownload: nullToEmptyString,
          headerContextMenu: headerMenu,
        },
        {
          title: "Andelstal",
          field: "organization_shares",
        },
        {
          title: "Kontraktsdatum",
          field: "move_in_date",
          visible: false,
          accessorDownload: nullToEmptyString,
          headerContextMenu: headerMenu,
        },
        {
          title: "Tillträde",
          field: "member_move_in_date",
          accessorDownload: nullToEmptyString,
          headerContextMenu: headerMenu,
        },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })
    window.XLSX = XLSX
  }

  exportTable(event) {
    event.preventDefault()
    var todaysDate = new Date().toISOString().slice(0, 10);
    var filename = 'medlemsregister-export-' + todaysDate + '.xlsx'
    var headerColumns = ['Namn', 'Personnummer', 'Adress', 'E-post', 'Telefonnummer', 'Objekt', 'Andelstal', 'Tillträde']

    window.residentsTable.download('xlsx', filename, {
      sheetName: 'Medlemmar',
      documentProcessing: function (workbook) {
        var sheet = workbook.Sheets.Medlemmar
        sanitizeXlsxExport(sheet, headerColumns)
        return workbook
      }
    })
  }
}

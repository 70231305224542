export class FileInputDecorator {
  #eventNames

  constructor() {
    this.#eventNames = ["change"]
  }

  init(targets) {
    targets.forEach((fileInput) => {
      const label = fileInput.parentElement.querySelector("label")

      this.#setExistingFilenameAsDefaultLabel(fileInput, label)

      this.#eventNames.forEach((eventName) =>
        fileInput.addEventListener(eventName, (event) =>
          this.#decorate(event.currentTarget, label)
        )
      )
    })
  }

  #setExistingFilenameAsDefaultLabel(fileInput, label) {
    if (fileInput.title != "" && label != undefined) {
      label.innerHTML = fileInput.title
    }
  }

  #decorate(fileInput, label) {
    label.dataset.defaultText = label.dataset.defaultText || label.innerHTML
    const template = label.dataset.template || "${filename}"

    let labelText, filename
    if (fileInput.files.length == 0) {
      labelText = fileInput.title || label.dataset.defaultText
    } else {
      filename = fileInput.files[0].name
      labelText = eval(`\`${template}\``)
    }

    label.innerHTML = labelText
  }
}

import { ApplicationController } from "../support/application_controller"
import * as Sentry from "@sentry/browser"
import Cookies from "js-cookie"

export default class extends ApplicationController {
  static targets = [
    "form",
    "spinnerContainer",
    "statusMessage",
    "errorContainer",
    "errorMessage",
  ]

  start() {
    this.spinnerContainerTarget.classList.remove("hidden")
    this.formTarget.classList.add("hidden")
    this._setRedirectCookie()

    const url = this.data.get("statusUrl")
    const response = this.railsFetch(url)

    this._handleResponse(response)
  }

  cancel() {
    this.spinnerContainerTarget.classList.add("hidden")
    this.errorContainerTarget.classList.add("hidden")
    this.formTarget.classList.remove("hidden")
  }

  switchToBankID(e) {
    e.preventDefault()

    document
      .querySelector(".modal-authentication-email")
      .classList.add("hidden")
    document
      .querySelector(".modal-authentication-bankid")
      .classList.remove("hidden")
  }

  _handleResponse(response) {
    response.then((value) => {
      try {
        switch (value.status) {
          case "cancelled":
          case "failed":
          case "error":
            this._showErrorMessage(value.message)
            break
          default:
            this.statusMessageTarget.innerHTML =
              "Vi har skickat ett epostmeddelande till den angivna epostadressen. Vänligen följ de instruktioner som ges för att logga in."
            break
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    })
  }

  _setRedirectCookie() {
    Cookies.set("auth_source", this.data.get("sectionName"), {
      expires: 365,
      path: "/",
    })
  }

  _showErrorMessage(message) {
    this.spinnerContainerTarget.classList.add("hidden")
    this.errorContainerTarget.classList.remove("hidden")

    this.errorMessageTarget.innerHTML = message
  }
}

import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"

export default class extends ApplicationController {
  update() {
    const toggle = event.currentTarget

    const response = this._saveChange(toggle)
    this._handleUpdate(response, toggle)
  }

  delete() {
    const cross = event.currentTarget

    const url = cross.getAttribute("data-url")
    const response = this.railsDelete(url)

    this._handleDelete(response, cross)
  }

  _saveChange(toggle) {
    const url = toggle.getAttribute("data-url")
    const payload = {}

    payload[toggle.value] = toggle.checked

    return this.railsUpdate(url, payload)
  }

  _handleUpdate(response, toggle) {
    response.then((value) => {
      if (value.status == "ok") {
        if (toggle.checked) {
          this._showVisualNotification("Bevakning aktiverad.")
        } else {
          this._showVisualNotification("Bevakning avaktiverad.")
        }
      }
    })
  }

  _handleDelete(_response, cross) {
    cross.parentElement.classList.add("pre-hidden-animation")
    setTimeout(() => {
      cross.parentElement.classList.add("hidden")
    }, 1000)
  }

  async _showVisualNotification(message) {
    const targetDiv = document.getElementById("notification")

    targetDiv.querySelector("p").innerHTML = message
    targetDiv.classList.add("display")

    setTimeout(() => {
      targetDiv.classList.remove("display")
      targetDiv.querySelector("p").innerHTML = ""
    }, 1000)
  }
}

/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */

/* The method chain starts at getPosition and continues from there.
 * If Geolocation is not supported by browser, nothing happens and no modal is shown.
 */

function currentPosition() {
  if ($("#nearest-coops-modal").length) {
    if (window.innerWidth < 768) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(getPosition, () => {
          $("form.new-search").append(
            '<div class="text-center"><p class="geolocation-warning">Aktivera telefonens positionstjänst för att se närliggande BRF</p></div>'
          )
        })
      }
    }
  }
}

document.addEventListener("DOMContentLoaded", currentPosition)

// utility function
function unique(list) {
  const result = []
  $.each(list, (i, e) => {
    if ($.inArray(e, result) == -1) result.push(e)
  })
  return result
}

function transformData(organizationRawArray) {
  const responseArray = organizationRawArray.items
  const bucketedOrganizationNameArray = []
  const organizationArray = []
  let count = 0 // fuling för att hitta rätt index

  $.each(responseArray, (index, organization) => {
    const indexOfOrganization = bucketedOrganizationNameArray.indexOf(
      organization.organization_name
    )
    if (indexOfOrganization >= 0) {
      organizationArray[indexOfOrganization].adressBucket.push(
        organization.address
      )
    } else {
      bucketedOrganizationNameArray.push(organization.organization_name)
      organizationArray[count] = {
        organization_name: organization.organization_name,
        rating_year: organization.rating_year,
        rating_url: organization.rating_url,
        organization_rating: organization.organization_rating,
        organization_url: organization.organization_url,
        distance: organization.distance,
        adressBucket: [organization.address],
      }
      count += 1
    }
  })

  return $.map(organizationArray, (organization) => {
    const adressParts = []
    const adressesStrings = {}
    const adressesString = []

    $.each(organization.adressBucket, (bucketIndex, adress) => {
      adress = adress.trim()
      const match = /(.*)\s+\d+\w?/.exec(adress)
      const firstNumberInAdressOccurenceIndex = match[1].length
      const adressPart = adress.substring(0, firstNumberInAdressOccurenceIndex)
      const numberPart = adress.substring(
        firstNumberInAdressOccurenceIndex + 1,
        adress.length
      )
      adressParts.push({
        adress: adressPart,
        number: numberPart,
      })
    })

    $.each(adressParts, (index, adressPart) => {
      const key = adressPart.adress
      if (adressesStrings[key]) {
        adressesStrings[
          key
        ].number = `${adressesStrings[key].number}, ${adressPart.number}`
      } else {
        adressesStrings[key] = adressPart
      }
    })

    for (const objKey in adressesStrings) {
      adressesString.push(
        `${adressesStrings[objKey].adress} ${adressesStrings[objKey].number}`
      )
    }

    return {
      organization_name: organization.organization_name,
      rating_year: organization.rating_year,
      rating_url: organization.rating_url,
      organization_rating: organization.organization_rating,
      organization_url: organization.organization_url,
      distance: organization.distance,
      adressBucket: adressesString,
    }
  })
}

/* getCooperatives(position: object) returns and ajax handler
 * for JSON request. When calling this function, use .done(function(data) { code... })
 * to access response. if responseCode is not 200, an error is logged.
 *
 * Parameters:
 * position, object:
 *
 */
function getOrganization(position) {
  const lat = position.coords.latitude
  const long = position.coords.longitude

  return $.ajax({
    type: "GET",
    url: "/items/nearby",
    data: {
      latitude: lat,
      longitude: long,
      margin: 500,
      limit: 10,
    },
  })
    .pipe((data) => data)
    .fail((data) => {
      if (data.responseCode) {
        console.log("Error! ResponseCode: ", data.responseCode)
      }
    })
}

/* getPosition(position: object) calls getCooperatives(position: object).
 * If getCooperatives(position: object) ajax returns a success, the data is returned
 * through a .done(function(responseBody: object)) callback.
 *
 * Descriptive modal text is dynamic with the length of the list.
 *
 * A .map (supported down to ie 9, is this good enough?) appends the
 * cooperative element to the list-group.
 *
 */
function getPosition(position) {
  getOrganization(position).done((responseBody) => {
    if (!responseBody.items) {
      return // itemslist is empty
    }

    // messy code that just fills the mainList in the base function
    // used for limiting list length

    const mainList = transformData(responseBody)
    renderList(mainList, 10)
  })
}

function renderList(mainList, listLimit) {
  const uniqueorganizationchecker = []

  if (listLimit > mainList.length) {
    listLimit = mainList.length
  }

  for (var i = 0; i < listLimit; i += 1) {
    uniqueorganizationchecker.push(mainList[i].organization_name)
  }

  const coopLength = unique(uniqueorganizationchecker).length

  let organization

  if (coopLength == 0) {
    return
  }
  if (coopLength == 1) {
    organization = "en bostadsrättsförening"
  } else {
    organization = `${coopLength} bostadsrättsföreningar`
  }

  // Set modal text with variable length of list
  $("#nearest-coops-modal .dialog-big-text").html(
    `<h4 class="infotext text-center">Du är i närheten av ${organization}.<br>Klicka på den adress du är intresserad av.</h4>`
  )

  for (var i = 0; i < listLimit; i += 1) {
    $("#nearest-coops-modal .nearest-list").append(fillCoopList(mainList[i]))
  }

  if (mainList.length <= listLimit) {
    $("#show-more-text").hide()
  }

  setupModal("nearest-coops-modal")

  document.getElementById("nearest-coops-modal").classList.toggle("falldown")
  document.body.classList.toggle("falldown")
}

function fillCoopList(organization) {
  let ratingCode

  if (organization.rating_url) {
    ratingCode =
      `<img class='rating-image' src='${organization.rating_url}'` +
      `alt='betyg ${organization.organization_rating}år ${organization.rating_year}'>`
  } else {
    ratingCode =
      "<div class='rating-image'>" +
      "<div class='text-muted question'>?</div>" +
      "</div>"
  }

  let adressesList = []
  $.each(organization.adressBucket, (index, adresses) => {
    adressesList = `${adressesList}<li>${adresses}</li>`
  })

  let listItem =
    `<a class='list-group-item' href='${organization.organization_url}'>` +
    `<h4 class='name'>${organization.organization_name}`

  listItem =
    `${listItem}</h4>${ratingCode}<div class='name-container'>` +
    `<ul class='list-unstyled adress-name'>${adressesList}</ul>` +
    "</div>" +
    "</div>" +
    "</a>"
  return listItem
}

import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  boolFormatter,
  currencyFormatter,
  floatFormatter,
  selectColumns,
  tabulatorSV,
} from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { loadActiveTabContents } from "../modules/register/register_tabs"
import { TabulatorFull as Tabulator } from "tabulator-tables"
import * as XLSX from "xlsx"
import { sanitizeXlsxExport } from "../../../../../shared_assets/app/webpack_assets/modules/sanitize_xlsx_export"
import { initTableWithPagedData } from "../modules/register/register_resident_functions"

export default class extends ApplicationController {
  static targets = ["lastUpdatePlaceholder"]

  connect() {
    // This is the first tab, so we load data immediately
    this._conditionalConnect()

    loadActiveTabContents(
      "[data-tab=register-properties]",
      "#register-properties-table",
      this._conditionalConnect.bind(this)
    )
  }

  _conditionalConnect() {
    if (window.propertiesTable) {
      window.propertiesTable.redraw(true)
      return
    }

    this._initializeTabulator()
    this._fetchData()
  }

  _fetchData() {
    const pageSize = 50
    const url =
      this.data.get("api-url") +
      `?include=residents,property_shares&page_size=${pageSize}`

    const transformResponse = (response) => {
      const arr = []

      response.data.forEach((row) => {
        row.attributes.id = row.id
        row.attributes.resident_names = this._extractResidentNames(
          response.included,
          row.id
        )
        row.attributes.resident_phone_numbers =
          this._extractResidentPhoneNumbers(response.included, row.id)
        row.attributes.fee = this._calculateFee(row)

        arr.push(row.attributes)
      })

      return arr
    }

    initTableWithPagedData(
      window.propertiesTable,
      url,
      transformResponse,
      pageSize
    )
  }

  _initializeTabulator() {
    const headerMenu = function () {
      const columns = this.getColumns()
      const menu = selectColumns(columns)

      return menu
    }

    const table = new Tabulator("#register-properties-table", {
      layout: "fitDataFill",
      layoutColumnsOnNewData: true,
      height: "100vh",
      initialSort: [{ column: "localized_property_type", dir: "asc" }],
      columns: [
        { title: "Objekt", field: "property_number" },
        {
          title: "LGH (LM)",
          field: "lm_property_number",
          headerContextMenu: headerMenu,
          visible: false,
        },
        {
          title: "Adress",
          field: "address",
          headerContextMenu: headerMenu,
        },
        {
          title: "Area (m2)",
          field: "area",
          formatter: floatFormatter,
          headerContextMenu: headerMenu,
        },
        {
          title: "Rum",
          field: "rooms",
          formatter: floatFormatter,
          headerContextMenu: headerMenu,
        },
        {
          title: "Våning",
          field: "floor",
          formatter: floatFormatter,
          headerContextMenu: headerMenu,
        },
        {
          title: "Avgift (SEK)",
          field: "combined_fee",
          formatter: currencyFormatter,
          hozAlign: "right",
          headerContextMenu: headerMenu,
        },
        {
          title: "Andelstal",
          field: "organization_share",
          formatter: floatFormatter,
          headerContextMenu: headerMenu,
        },
        {
          title: "Typ",
          field: "localized_property_type",
          headerContextMenu: headerMenu,
        },
        {
          title: "Hyresrätt",
          field: "lease",
          formatter: boolFormatter,
          headerContextMenu: headerMenu,
        },
        {
          title: "Innehavare",
          field: "resident_names",
          headerSort: false,
          headerContextMenu: headerMenu,
        },
        {
          title: "Telefonnummer",
          field: "resident_phone_numbers",
          headerSort: false,
          headerContextMenu: headerMenu,
        },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })

    window.propertiesTable = table
    window.XLSX = XLSX
  }

  exportTable(event) {
    event.preventDefault()
    var todaysDate = new Date().toISOString().slice(0, 10);
    var filename = 'objekt-export-' + todaysDate + '.xlsx'
    var headerColumns = ['Objekt', 'Adress', 'Area (m2)', 'Rum', 'Våning', 'Avgift (SEK)', 'Andelstal', 'Typ', 'Hyresrätt', 'Innehavare', 'Telefonnummer']

    window.propertiesTable.download('xlsx', filename, {
      sheetName: 'Objekt',
      documentProcessing: function (workbook) {
        var sheet = workbook.Sheets.Objekt
        sanitizeXlsxExport(sheet, headerColumns)
        return workbook
      }
    })
  }

  _calculateFee(row) {
    return row.attributes.lease
      ? row.attributes.annual_lease_fee
      : row.attributes.annual_fee
  }

  _extractResidentNames(included_data, id) {
    const residents = this._filterResidents(included_data, id)
    if (residents.length) {
      return residents.map((resident) => resident.attributes.name)
    }
  }

  _extractResidentPhoneNumbers(included_data, id) {
    const residents = this._filterResidents(included_data, id)
    if (residents.length) {
      return residents.map((resident) => resident.attributes.phone_number)
    }
  }

  _filterResidents(included_data, id) {
    return included_data.filter((entry) => {
      return (
        entry.type === "resident" &&
        entry.relationships.properties.data.filter((connected_entry) => {
          return connected_entry.id === id
        }).length > 0
      )
    })
  }
}

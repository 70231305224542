import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  calculateTotalPages,
  tabulatorSV,
} from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { TabulatorFull as Tabulator } from "tabulator-tables"

export default class extends ApplicationController {
  static targets = ["lastUpdatePlaceholder", "unpaidInvoices", "paidInvoices"]

  connect() {
    this._initializeUnpaidTabulator()
    this._initializePaidTabulator()
    this._redrawOnActiveTab()
  }

  _initializeUnpaidTabulator() {
    const unPaidTable = this._tabulatorSkeleton(
      "unpaid",
      this.unpaidInvoicesTarget
    )

    window.unPaidTable = unPaidTable
  }

  _initializePaidTabulator() {
    const paidTable = this._tabulatorSkeleton("paid", this.paidInvoicesTarget)

    window.paidTable = paidTable
  }

  _tabulatorSkeleton(invoiceStatus, container) {
    const pageSize = 50

    const table = new Tabulator(container, {
      layout: "fitDataFill",
      layoutColumnsOnNewData: true,
      history: false,
      ajaxURL: this.data.get("api-url"),
      ajaxParams: {
        status: invoiceStatus,
        role: "resident",
        page_size: pageSize,
      },
      ajaxResponse: (_url, _params, response) => {
        return this._transformResponse(response, pageSize)
      },
      pagination: true,
      paginationMode: "remote",
      paginationSize: pageSize,
      initialSort: [{ column: "period", dir: "asc" }],
      columns: this._invoiceColumns(invoiceStatus),
      locale: "sv",
      langs: tabulatorSV(),
    })

    return table
  }

  _invoiceColumns(invoiceStatus) {
    var formatter = new Intl.NumberFormat("se-SE", {
      style: "currency",
      currency: "SEK",

      // These options are needed to round to whole numbers if that's what you want.
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
    })
    const arr = [
      { title: "Period", field: "period", frozen: true },
      {
        title: "Förfallodatum",
        field: "due_date",
        formatter: (cell, _formatterParams, _onRendered) => {
          const value = new Date(cell.getValue())
          return value
            .toLocaleString("sv-se", { dateStyle: "short" })
            .split(" ")[0]
        },
      },
      { title: "BG-nummer", field: "bg_number" },
      { title: "OCR-nummer", field: "ocr" },
      {
        title: "Belopp (SEK)",
        field: "price",
        formatter: (cell, _formattedParams, _onRendered) => {
          return formatter
            .format(parseFloat(cell.getValue()))
            .replace(/[a-z]{3}/i, "")
            .trim()
        },
      },
      {
        title: "Status",
        field: "status",
        formatter: (cell, _formattedParams, _onRendered) => {
          switch (cell.getValue()) {
            case "overdue":
              return "<span class='overdue'></span><span>Försenad</span>"
            case "unpaid":
              return "<span class='unpaid'></span><span>Obetald</span>"
            case "paid":
              return "Betald"
            default:
              return cell.getValue()
          }
        },
      },
      {
        title: "Avi",
        field: "pdf",
        headerSort: false,
        formatter: (cell, _formattedParams, _onRendered) => {
          return `<a href='${cell.getValue()}' target='_blank'><span class='invoice'></span></a>`
        },
      },
    ]

    if (invoiceStatus == "paid") {
      arr.filter((element) => {
        return element.title != "Status"
      })
    }

    return arr
  }

  _transformResponse(response, pageSize) {
    const arr = []

    response.data.forEach((row) => {
      row.attributes.id = row.id
      row.attributes.pdf =
        this.data.get("apiUrl") + "/" + row.id + "/preview?role=resident"
      arr.push(row.attributes)
    })

    return { last_page: calculateTotalPages(response, pageSize), data: arr }
  }

  _redrawOnActiveTab() {
    document
      .querySelector("[data-tab=unpaid]")
      .addEventListener("click", () => {
        setTimeout(() => {
          window.unPaidTable.redraw(true)
        }, 30)
      })

    document.querySelector("[data-tab=paid]").addEventListener("click", () => {
      setTimeout(() => {
        window.paidTable.redraw(true)
      }, 30)
    })
  }
}

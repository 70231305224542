const inputClonedEvent = new window.CustomEvent("ClonableInputChanged")
const inputCloningStartedEvent = new window.CustomEvent(
  "ClonableInputWillChange"
)

const lastCLonableNode = () => {
  const nodes = document.querySelectorAll("[data-clonable]")
  return nodes[nodes.length - 1]
}

const clonablesCount = () => {
  return document.querySelectorAll("[data-clonable]").length
}

const replaceIndex = (node, attribute, oldValue, newValue) => {
  node.attributes[attribute] &&
    node.setAttribute(
      attribute,
      node.getAttribute(attribute).replace(oldValue, newValue)
    )
}

export const clonableInputs = () => {
  const lastClonableNode = lastCLonableNode()
  if (!lastClonableNode) {
    return
  }

  const container = lastClonableNode.parentNode
  let link = container.querySelector("[data-clonable-trigger]")
  if (!link) {
    link = document.createElement("a")
    link.href = "#"
    link.innerHTML = "Add new"
    link.dataset.clonableTrigger = "true"
    lastClonableNode.after(link)
  }

  document.querySelectorAll("[data-clonable-trigger]").forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault()

      document.dispatchEvent(inputCloningStartedEvent)

      let clonableContainer
      if (el.dataset.clonableTemplateSelector) {
        clonableContainer = container.querySelector(
          el.dataset.clonableTemplateSelector
        )
      } else {
        clonableContainer = e.currentTarget.previousElementSibling
      }
      const clone = clonableContainer.cloneNode(true)

      clone.querySelectorAll("select").forEach((selectField) => {
        selectField.id = Math.random().toString().substr(2, 5)
      })
      clone.querySelectorAll("[value]").forEach((field) => {
        field.value = ""
      })
      // Rails params processor expects enumerated collection for nested fields
      // to work.
      const nodes = clone.querySelectorAll("[name],[for]")
      const matches = (
        nodes[0].getAttribute("name") || nodes[0].getAttribute("for")
      ).match(/(.*\[(\d+)\].*)|(.*_(\d+)_.*)/)
      if (matches) {
        const index = parseInt(matches[2] || matches[4])
        nodes.forEach((node) => {
          replaceIndex(node, "name", `[${index}]`, `[${index + 1}]`)
          replaceIndex(node, "id", `_${index}_`, `_${index + 1}_`)
          replaceIndex(node, "for", `_${index}_`, `_${index + 1}_`)
        })
      }

      clonableContainer.insertAdjacentElement("afterend", clone)
      document.dispatchEvent(inputClonedEvent)
    })
  })
}

export const initClonedRemovalLinks = () => {
  document.querySelectorAll("[data-clonable]").forEach((el) => {
    const link = document.createElement("a")

    link.href = "#"
    link.innerHTML = "Remove item"
    link.dataset.clonableRemove = "true"
    link.classList.add("m-right-8")

    el.insertAdjacentElement("beforeend", link)
  })
}

export const removeClonedInputs = () => {
  document.querySelectorAll("[data-clonable-remove]").forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault()

      const removableContainer = e.currentTarget.parentNode
      if (removableContainer) {
        if (clonablesCount() == 1) {
          removableContainer.nextSibling.remove()
          flushInputs(
            removableContainer,
            removableContainer.getAttribute("data-name")
          )
        }

        el.remove()
        removableContainer.remove()

        document.dispatchEvent(inputClonedEvent)
      }
    })
  })
}

const flushInputs = (container, name) => {
  const removalFlag = document.createElement("input")

  removalFlag.type = "hidden"
  removalFlag.name = name
  removalFlag.value = "remove"

  container.insertAdjacentElement("afterend", removalFlag)
}

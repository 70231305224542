import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import { buildPaginatedResidentTable } from "../modules/register/register_resident_functions"

import {
  nullToEmptyString,
  selectColumns,
  tabulatorSV,
} from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { loadActiveTabContents } from "../modules/register/register_tabs"

import { TabulatorFull as Tabulator } from "tabulator-tables"
import { sanitizeXlsxExport } from "../../../../../shared_assets/app/webpack_assets/modules/sanitize_xlsx_export"

export default class extends ApplicationController {
  connect() {
    loadActiveTabContents(
      "[data-tab=register-tenants]",
      "#register-tenants-table",
      this._conditionalConnect.bind(this)
    )
  }

  _conditionalConnect() {
    if (window.tenantsTable) {
      window.tenantsTable.redraw(true)
      return
    }

    this._initializeTabulator()
    this._fetchData()
  }

  async _fetchData() {
    const pageSize = 50
    const url =
      this.data.get("api-url") +
      `?resident_types=tenant&active=true&include=properties,property_shares&page_size=${pageSize}`

    await buildPaginatedResidentTable(url, window.tenantsTable, pageSize)
  }

  _initializeTabulator() {
    const headerMenu = function () {
      const columns = this.getColumns()
      return selectColumns(columns)
    }

    window.tenantsTable = new Tabulator("#register-tenants-table", {
      layout: "fitDataFill",
      initialSort: [{ column: "name", dir: "asc" }],
      columns: [
        {
          title: "Namn",
          field: "name",
          accessorDownload: nullToEmptyString,
          headerContextMenu: headerMenu,
        },
        {
          title: "Pnr.",
          field: "ss_number",
          accessorDownload: nullToEmptyString,
          headerContextMenu: headerMenu,
        },
        {
          title: "E-post",
          field: "combined_email",
          accessorDownload: nullToEmptyString,
          headerContextMenu: headerMenu,
        },
        {
          title: "Tel.",
          field: "phone_number",
          accessorDownload: nullToEmptyString,
          headerContextMenu: headerMenu,
        },
        {
          title: "Objekt",
          field: "objects",
          accessorDownload: nullToEmptyString,
          headerContextMenu: headerMenu,
        },
        {
          title: "Adress",
          field: "address",
          accessorDownload: nullToEmptyString,
          headerContextMenu: headerMenu,
        },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })
  }

  exportTable(event) {
    event.preventDefault()
    var todaysDate = new Date().toISOString().slice(0, 10);
    var filename = 'hyresgaster-export-' + todaysDate + '.xlsx'
    var headerColumns = ['Namn', 'Personnummer', 'E-post', 'Telefonnummer', 'Objekt']

    window.tenantsTable.download('xlsx', filename, {
      sheetName: 'Hyresgaster',
      documentProcessing: function (workbook) {
        var sheet = workbook.Sheets.Hyresgaster
        sanitizeXlsxExport(sheet, headerColumns)
        return workbook
      }
    })
  }
}

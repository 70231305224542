import { ApplicationController } from "../support/application_controller"
import * as Sentry from "@sentry/browser"
import Cookies from "js-cookie"

export default class extends ApplicationController {
  static targets = [
    "form",
    "statusContainer",
    "statusMessage",
    "qrCode",
    "errorContainer",
    "errorMessage",
  ]

  start(event) {
    this.statusMessageTarget.innerHTML = "Vänta"

    const token = event.detail[0].auto_start_token

    if (token) {
      window.hideQrCode = true
      this._redirectToBankID(token)
    }

    this.statusContainerTarget.classList.remove("hidden")
    this.formTarget.classList.add("hidden")
    this._setRedirectCookie()
    this._pollAuthStatus()
  }

  cancel() {
    clearInterval(window.bankIDPolling)

    this.statusContainerTarget.classList.add("hidden")
    this.errorContainerTarget.classList.add("hidden")
    this.formTarget.classList.remove("hidden")
  }

  switchToEmail(swapLabels = "keep") {
    document
      .querySelector(".modal-authentication-bankid")
      .classList.add("hidden")
    document
      .querySelector(".modal-authentication-email")
      .classList.remove("hidden")

    if (swapLabels === "swap") {
      document.querySelector(".modal-authentication-email h2").innerHTML =
        "Vi behöver bara bekräfta din e-postadress"
      document.querySelector(".modal-authentication-email button").innerHTML =
        "Verifiera e-postadress"
    }
  }

  _pollAuthStatus() {
    const statusUrl = this.data.get("statusUrl")

    window.bankIDPolling = setInterval(() => {
      const response = this.railsFetch(statusUrl)
      this._handleResponse(response)
    }, 1000)
  }

  _handleResponse(response) {
    response.then((value) => {
      try {
        switch (value.status) {
          case "verification":
            this.statusMessageTarget.innerHTML = value.message

            if (!window.hideQrCode) {
              this.qrCodeTarget.innerHTML = value.qrCode
            }
            break
          case "idle":
            this.statusMessageTarget.innerHTML = "Vänta"
            break
          case "verified":
            clearInterval(window.bankIDPolling)

            this.qrCodeTarget.innerHTML = ""
            this.switchToEmail("swap")
            break
          case "signedin":
            clearInterval(window.bankIDPolling)

            this.statusMessageTarget.innerHTML = value.message
            this.qrCodeTarget.innerHTML = ""

            if (value.location) {
              setTimeout(() => {
                window.location.assign(value.location)
              }, 100)
            }
            break
          case "cancelled":
          case "failed":
            clearInterval(window.bankIDPolling)

            this._showErrorMessage(value.message)
            break
          default:
            break
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    })
  }

  _redirectToBankID(token) {
    const path = `autostarttoken=${token}&redirect=null`
    if (/Android|iPhone/i.test(navigator.userAgent)) {
      window.location.assign("https://app.bankid.com/?" + path)
    } else {
      window.location.assign("bankid:///?" + path)
    }
  }

  _showErrorMessage(message) {
    this.statusContainerTarget.classList.add("hidden")
    this.errorContainerTarget.classList.remove("hidden")

    this.errorMessageTarget.innerHTML = message
  }

  _setRedirectCookie() {
    Cookies.set("auth_source", this.data.get("sectionName"), {
      expires: 365,
      path: "/",
    })
  }
}

import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  calculateTotalPages,
  dateFormatter,
  tabulatorSV,
} from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { TabulatorFull as Tabulator } from "tabulator-tables"
import * as XLSX from "xlsx"

export default class extends ApplicationController {
  connect() {
    this._initializeTabulator()
  }

  _initializeTabulator() {
    const table = new Tabulator("#expenses-history-table", {
      layout: "fitData",
      layoutColumnsOnNewData: true,
      ajaxURL: this.data.get("api-url"),
      dataSendParams: { size: "page_size" },
      ajaxResponse: (_url, _params, response) => {
        this.table_rowCount = response["meta"].total
        return this._transformResponse(response)
      },
      width: "100%",
      pagination: true,
      paginationMode: "remote",
      paginationSize: 50,
      paginationSizeSelector: [50, 100, 150],
      columns: [
        {
          title: "Kvitto",
          field: "url_for_file_attachment",
          download: false,
          headerSort: false,
          formatter: (cell, _formattedParams, _onRendered) => {
            return `<a href='${cell.getValue()}' target='_blank'><span class='expenses-history__receipt'></span></a>`
          },
        },
        {
          title: "Datum",
          field: "created_at",
          headerSort: false,
          formatter: dateFormatter,
        },
        {
          title: "Skapad av",
          field: "user",
          headerSort: false,
        },
        {
          title: "Utbetald till",
          field: "name",
          headerSort: false,
        },

        {
          title: "Summa",
          field: "amount",
          headerSort: false,
          hozAlign: "right",
          formatter: "money",
          formatterParams: {
            symbol: " SEK",
            symbolAfter: true,
            precision: false,
            thousand: " ",
          },
        },
        {
          title: "Beskrivning",
          field: "description",
          headerSort: false,
        },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })

    this.expensesHistoryTable = table
    window.XLSX = XLSX
  }

  exportTable(event) {
    event.preventDefault()

    let table = this.expensesHistoryTable
    let page = table.getPage()
    let pageSize = table.getPageSize()
    table.setPageSize(this.table_rowCount)
    table.setPage(1).then(function () {
      table.download("xlsx", "Historik.xlsx", {
        sheetName: "Historik",
      })
      table.clearData()
      table.setPageSize(pageSize)
      table.setPage(page)
      table.restoreRedraw()
    })
  }

  _transformResponse(response) {
    const pageSize = this.expensesHistoryTable.getPageSize()
    const arr = []

    response.data.forEach((row) => {
      arr.push(row.attributes)
    })

    return { last_page: calculateTotalPages(response, pageSize), data: arr }
  }
}

import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  canAddObject,
  resetInfiniteScroll,
  switchClass,
} from "../../modules/board_room/switch_icon"
import Sortable from "sortablejs"

export default class extends ApplicationController {
  static targets = [
    "mainContainer",
    "form",
    "docmentsList",
    "reorderIcon",
    "orderName",
    "orderDate",
  ]

  connect() {
    this.orderByName, (this.orderByDate = false)
    this.SortableDisabled = true
  }

  onIndex(e) {
    const [data, status, xhr] = e.detail

    const template = data.html
    this.mainContainerTarget.innerHTML = template
    this._initiateSortable()
    this.pages = data.pagy.pages
    resetInfiniteScroll(this, data.pagination)
  }

  onCreateDocument(e) {
    const [data, status, xhr] = e.detail

    if (data.status == 201 && canAddObject(this, this.docmentsListTarget)) {
      this.docmentsListTarget.innerHTML += JSON.parse(data.response).html
    }
  }

  onChange(e) {
    const input = e.currentTarget

    if (input.files.length) {
      Rails.fire(this.formTarget, "submit")
    }
  }

  onOrderByName(e) {
    this.orderByName = !this.orderByName
    this.orderByDate = false

    const order = this._getOrder(this.orderByName, "name")
    const url = e.currentTarget.getAttribute("data-url") + `&order=${order}`

    this.railsFetch(url).then((result) => {
      this._orderHandler(result)
    })
  }

  onOrderByDate(e) {
    this.orderByDate = !this.orderByDate
    this.orderByName = false

    const order = this._getOrder(this.orderByDate, "updated_at")
    const url = e.currentTarget.getAttribute("data-url") + `&order=${order}`

    this.railsFetch(url).then((result) => {
      this._orderHandler(result)
    })
  }

  onSwitchReorder(e) {
    this.SortableDisabled = !this.SortableDisabled

    switchClass(this.reorderIconTarget.classList, !this.SortableDisabled)
    this.sortable.option("disabled", this.SortableDisabled)
  }

  _orderHandler(result) {
    this.mainContainerTarget.innerHTML = result.html
    switchClass(this.orderDateTarget.classList, this.orderByDate)
    switchClass(this.orderNameTarget.classList, this.orderByName)
    this._initiateSortable()
    resetInfiniteScroll(this, result.pagination)
  }

  _endSortable(e) {
    const url = e.item.dataset.url
    const newIndex = e.newIndex + 1
    const payload = {}
    payload["position"] = newIndex

    return this.railsUpdate(url, payload)
  }

  _initiateSortable(e) {
    this.sortable = Sortable.create(this.docmentsListTarget, {
      onEnd: this._endSortable.bind(this),
      disabled: this.SortableDisabled,
    })
    switchClass(this.reorderIconTarget.classList, !this.SortableDisabled)
  }

  _getOrder = (attr, name) => (attr === true ? name : "")
}

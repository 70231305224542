import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"

export default class extends ApplicationController {
  static targets = ["secondsToRedirect"]
  static values = {
    secondsToRedirect: Number,
    redirectToUrl: String,
    timeUnitName: Object,
  }

  connect() {
    this.setTimeToRedirectText()
    this.intervalId = window.setInterval(
      this.redirectMessageTick.bind(this),
      1000
    )
  }

  redirectMessageTick() {
    this.secondsToRedirectValue--
    if (this.secondsToRedirectValue > 0) {
      this.setTimeToRedirectText()
    } else {
      window.clearInterval(this.intervalId)
      window.location.href = this.redirectToUrlValue
    }
  }

  setTimeToRedirectText() {
    if (this.secondsToRedirectValue > 1) {
      this.secondsToRedirectTarget.innerHTML =
        this.timeUnitNameValue.other.replace(
          "%{count}",
          this.secondsToRedirectValue
        )
    } else if (this.secondsToRedirectValue > 0) {
      this.secondsToRedirectTarget.innerHTML = this.timeUnitNameValue.one
    }
  }
}

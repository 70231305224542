import { BRFSearch } from "../../../../shared_assets/app/web_components/searchbar/search"

export default class extends BRFSearch {
  constructor(selector, options) {
    super()

    this.searchbarButton = document.querySelector(
      ".broker-property-autocomplete"
    )
    this.selector = selector
    this.onSelection = options.onSelection
    this.src = options.src
    this.reset = options.reset
    this.newSearch = options.newSearch
  }

  _inputChanged(event) {
    if (event.data == null) {
      this.reset()
    }
  }

  _onResultSelection(inputElement, value) {
    inputElement.value = value
  }

  _onResultsEvents(eventType) {
    return false
  }

  _dropdownEvents(inputElement) {
    if (this.newSearch === true) {
      return false
    }
    inputElement.addEventListener("keypress", () => {
      if (this.btnSwitch === 1) {
        this.searchbarButton.classList.toggle("active")
        this.btnSwitch = 2
      }
    })

    inputElement.addEventListener(
      "focus",
      this._onResultsEvents.bind(this, "focus")
    )
    inputElement.addEventListener(
      "blur",
      this._onResultsEvents.bind(this, "blur")
    )
  }
}

import { ApplicationController } from "../../../../../../../app/webpack_assets/support/application_controller"
import { otherTemplate, residenceTemplate } from "./invoice_template"

export default class extends ApplicationController {
  static targets = [
    "switchBankTabs",
    "propertiesPlaceholder",
    "selectedBank",
    "selectedBankControls",
    "selectedBankForm",
    "bankIdInfo",
    "validBankAccounts",
    "invoiceEmail",
    "invoiceMailAddress",
    "invoiceMailZipcode",
    "invoiceMailTown",
    "paymentSection",
    "autogiroSection",
    "einvoiceSection",
    "postalSection",
    "emailSection",
    "banksList",
    "bankAccounts",
    "eInvoiceBanksList",
    "manualBank",
    "manualClearingNr",
    "manualAccountNr",
    "selectedBankSigning",
    "makeBillable",
  ]

  connect() {
    window.bankIdCalled = false
    this._fetchResidentInfo()
    this._banksListListener()
  }

  switchBankTabs() {
    const value = this.switchBankTabsTarget.value

    document.querySelectorAll("[data-switchtarget]").forEach((el) => {
      el.classList.remove("always-visible")
      el.classList.add("hidden")
    })

    const selectedTab = document.querySelector(`[data-switchtarget=${value}]`)
    selectedTab.classList.add("always-visible")
    selectedTab.classList.remove("hidden")
  }

  submitSelectedBank(event) {
    if (event.params.disabled) {
      return
    }

    const selectedBank = this.banksListTarget.value

    if (selectedBank === "custom") {
      this.selectedBankFormTarget.insertAdjacentHTML(
        "afterbegin",
        "<p class='margin-b-16'>Skriv in kontouppgifter till din bank:</p>"
      )
      this._displayManualAccountForm()
      return
    }

    const url = this.data.get("baseUrl") + "profil/bankkonto/accounts"
    const payload = {
      resident_id: this.data.get("residentWithCustomer").split("-")[0],
      customer_id: this.data.get("residentWithCustomer").split("-")[1],
      bank: selectedBank,
    }

    this._showBankIDWaitingMessage(true)
    this.railsCreate(url, payload).then((response) => {
      if (response.hasOwnProperty("public_id")) {
        this._fetchBankAccountStatus(payload.customer_id, response.public_id)
      } else {
        this.selectedBankFormTarget.insertAdjacentHTML(
          "afterbegin",
          "<p class='margin-b-16'>Vi kunde inte hämta dina kontouppgifter, vänligen skriv in dina kontouppgifter manuellt.</p>"
        )
        this._displayManualAccountForm()
      }
    })
  }

  submitSelectedAccount() {
    this._showBankIDWaitingMessage(false)

    const url = this.data.get("baseUrl") + "profil/autogiro/sign"
    // [clearing, account]
    const selectedAccount = this.bankAccountsTarget.value.split(",")
    const bank = this.selectedBankSigningTarget.innerText
    const payload = {
      resident_id: this.data.get("residentWithCustomer").split("-")[0],
      clearing: selectedAccount[0],
      account: selectedAccount[1],
    }

    this.validBankAccountsTarget.innerHTML = ""
    this.railsCreate(url, payload).then((response) => {
      const customerId = this.data.get("residentWithCustomer").split("-")[1]
      const signAutogiroLoop = setInterval(() => {
        this._fetchSignAutogiroStatus(
          customerId,
          response.reference_token,
          signAutogiroLoop,
          payload,
          bank
        )
      }, 1000)
    })
  }

  handleManualAccount() {
    const url = this.data.get("baseUrl") + "profil/autogiro/sign"
    const payload = {
      bank: this.manualBankTarget.value,
      clearing: this.manualClearingNrTarget.value,
      account: this.manualAccountNrTarget.value,
      resident_id: this.data.get("residentWithCustomer").split("-")[0],
    }

    // Hide button and display a message
    document.getElementById("manual-submit").style.display = "none"
    this.autogiroSectionTarget.innerHTML +=
      "<p class='text-center'>Väntar på svar från BankID</p>"
    this.validBankAccountsTarget.innerHTML = ""

    this.railsCreate(url, payload).then((response) => {
      const customerId = this.data.get("residentWithCustomer").split("-")[1]

      const signAutogiroLoop = setInterval(() => {
        this._fetchSignAutogiroStatus(
          customerId,
          response.reference_token,
          signAutogiroLoop,
          payload
        )
      }, 1000)
    })
  }

  handleEinvoice() {
    const url = this.data.get("baseUrl") + "profil/faktura/einvoice"
    const payload = {
      resident_id: this.data.get("residentWithCustomer").split("-")[0],
      properties: this._collectCheckboxes(),
      bank: this.eInvoiceBanksListTarget.value,
    }

    this.railsCreate(url, payload).then((response) => {
      this._handleInvoicePromise(response.status, this.einvoiceSectionTarget)
    })
  }

  handleEmailInvoicing() {
    const url = this.data.get("baseUrl") + "profil/faktura/email"
    const payload = {
      resident_id: this.data.get("residentWithCustomer").split("-")[0],
      properties: this._collectCheckboxes(),
      email: this.invoiceEmailTarget.value,
    }

    this.railsCreate(url, payload).then((response) => {
      this._handleInvoicePromise(response.status, this.emailSectionTarget)
    })
  }

  handlePostalInvoicing() {
    const url = this.data.get("baseUrl") + "profil/faktura/postal"
    const payload = {
      resident_id: this.data.get("residentWithCustomer").split("-")[0],
      properties: this._collectCheckboxes(),
      address: this.invoiceMailAddressTarget.value,
      zipcode: this.invoiceMailZipcodeTarget.value,
      town: this.invoiceMailTownTarget.value,
    }

    this.railsCreate(url, payload).then((response) => {
      this._handleInvoicePromise(response.status, this.postalSectionTarget)
    })
  }

  _fetchBankAccountStatus(customer_id, public_id) {
    const url = this.data.get("baseUrl") + "profil/bankkonto/status"
    const payload = {
      customer_id: customer_id,
      public_id: public_id,
    }

    this.railsCreate(url, payload).then((response) => {
      if (response.status === "error") {
        this.selectedBankControlsTarget.innerHTML = `<p class='text-center'>${response.message}</p>`
      }
      if (response.bank_id_autostart_token !== null) {
        this.bankIdInfoTarget.innerHTML = `
          <img src="${response.qr}" class='always-visible margin-auto' alt="BankID QR-code" />
          <p class='text-center'>Starta din BankID säkerhetsapp.</p>
          `
        if (!window.bankIdCalled) {
          this._autostartBankIdMobile(response.bank_id_autostart_token)
          window.bankIdCalled = true
        }
      }

      if (response.status.toLowerCase() === "waiting") {
        setTimeout(
          () => this._fetchBankAccountStatus(customer_id, public_id),
          1000
        )
      } else {
        this._hideBankIDControls()
        this._displayAccounts(response)
      }
    })
  }

  _fetchSignAutogiroStatus(
    customerId,
    refToken,
    signAutogiroInterval,
    accountInfo,
    bank
  ) {
    const url = this.data.get("baseUrl") + "profil/autogiro/status"
    const payload = {
      customer_id: customerId,
      ref_token: refToken,
    }

    this.railsCreate(url, payload).then((response) => {
      if (response.status.toLowerCase() === "complete") {
        clearInterval(signAutogiroInterval)
        this._createAutogiroApproval(accountInfo, refToken, bank)
      } else if (response.status.toLowerCase() === "error") {
        clearInterval(signAutogiroInterval)
        this.autogiroSectionTarget.innerHTML =
          "<p class='text-center'>Något gick fel, vänligen försök igen.</p>"
      }
    })
  }

  _createAutogiroApproval(accountInfo, refToken, bank) {
    const url = this.data.get("baseUrl") + "profil/autogiro/approval"
    const payload = {
      bankid_reference: refToken,
      resident_id: accountInfo.resident_id,
      clearing: accountInfo.clearing,
      account: accountInfo.account,
      bank: bank,
      properties: this._collectCheckboxes(),
    }

    this.railsCreate(url, payload).then((response) => {
      this._handleInvoicePromise(response.status, this.autogiroSectionTarget)
    })
  }

  _displayAccounts(data) {
    if (data.status.toLowerCase() === "success") {
      if (!data.account_numbers.length) {
        // Display manual account form
        this.selectedBankTarget.innerHTML =
          "<p>Vi kan inte hitta några bankkonton, vänligen skriv in dina kontouppgifter manuellt</p>"
        this._displayManualAccountForm()
      } else {
        this._hideBankIDsection()

        const accountsList = data.account_numbers
        this.validBankAccountsTarget.innerHTML += `<div class='form__group--modal'>
              <p hidden data-target='resident-payments.selectedBankSigning'>${data.bank}</p>
              <div class='select__wrapper'>
                <select data-target='resident-payments.bankAccounts'></select>
              </div>
          </div>`
        const accountsForm = this.bankAccountsTarget
        // accounts appended to list (select tag)
        accountsList
          .filter((a) => a.autogiro_compatible.toLowerCase() === "approved")
          .forEach((a) => {
            accountsForm.innerHTML += `<option value="${a.clearing_no},${a.account_no}">${a.name}: ${a.clearing_no} - ${a.account_no}</option>`
          })
        this.validBankAccountsTarget.innerHTML +=
          "<a class='margin-auto button--primary' data-action='resident-payments#submitSelectedAccount'>Välj konto</a>"
      }
    } else {
      this._hideBankIDsection()
      this.selectedBankFormTarget.insertAdjacentHTML(
        "afterbegin",
        "<p class='margin-b-16'>Vi kunde inte hämta dina kontouppgifter, vänligen skriv in dina kontouppgifter manuellt.</p>"
      )
      this._displayManualAccountForm()
    }
  }

  _hideBankIDsection() {
    this.bankIdInfoTarget.innerHTML = ""
  }

  _showBankIDWaitingMessage(system = true) {
    this._showBankIDControls()

    if (system) {
      this.selectedBankControlsTarget.innerHTML =
        "<p class='text-center'>Väntar på BankID...</p>"
    } else {
      this.selectedBankControlsTarget.innerHTML =
        "<p class='text-center'>Väntar på att du signerar med BankID...</p>"
    }
  }

  _showBankIDControls() {
    this.selectedBankControlsTarget.classList.remove("hidden")
  }

  _hideBankIDControls() {
    this.selectedBankControlsTarget.classList.add("hidden")
  }

  _displayManualAccountForm() {
    this._hideBankIDControls()
    this.selectedBankFormTarget.classList.remove("hidden")
  }

  _fetchResidentInfo() {
    const residentIds = this.data.get("residentWithCustomer").split("-")
    const residentUrl =
      this.data.get("baseUrl") +
      `register/customers/${residentIds[1]}/residents/${residentIds[0]}`

    this.railsFetch(residentUrl).then((response) => {
      this._fetchPropertyInfo(response)
    })
  }

  _fetchPropertyInfo(resident) {
    resident.data.relationships.properties.data.forEach((pid) => {
      const customerId = resident.data.attributes.customer_id
      const propertiesUrl =
        this.data.get("baseUrl") +
        `register/customers/${customerId}/properties/${pid.id}?include=residents`

      this.railsFetch(propertiesUrl).then((response) => {
        if (resident) {
          this._displayPropertyInfo(resident, response)
        }
      })
    })
  }

  _displayPropertyInfo(resident, property) {
    if (property.data.attributes.property_type === "residence") {
      this.propertiesPlaceholderTarget.innerHTML += residenceTemplate(
        resident,
        property
      )
    } else {
      this.propertiesPlaceholderTarget.innerHTML += otherTemplate(
        resident,
        property
      )
    }
  }

  _handleInvoicePromise(status, target) {
    if (status === "ok") {
      target.innerHTML = "<p class='text-center'>Klart!</p>"
    } else {
      target.innerHTML =
        "<p class='text-center'>Något gick fel, vänligen försök igen.</p>"
    }
  }

  _collectCheckboxes() {
    const checkboxes = this.makeBillableTargets
    const properties = []

    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked === true) {
        properties.push(checkboxes[i].dataset.propertyId)
      }
    }

    return properties
  }

  _autostartBankIdMobile(autostartToken) {
    if (/Mobile|Android/i.test(navigator.userAgent)) {
      // a mobile device
      const url = `bankid:///?autostarttoken=${autostartToken}&redirect=null`
      window.open(url)
    } else {
      // not a mobile device
    }
  }

  _banksListListener() {
    this.banksListTarget.addEventListener("click", () => {
      const button = document.getElementById("submit-button")
      if (button) {
        if (this.banksListTarget.value === "custom") {
          button.innerHTML = "Fortsätt"
        } else {
          button.innerHTML = "Hämta konto med BankID"
        }
      }
    })
  }
}

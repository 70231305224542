export class InsuranceGrid {
  constructor(gridOne, gridTwo, data) {
    this.gridOne = gridOne
    this.gridTwo = gridTwo
    this.data = data
  }

  _translate(word) {
    return word === true ? "Ja" : "Nej"
  }

  _toLocale(int) {
    return `${int.toLocaleString("sv-se", { useGrouping: true })} SEK`
  }

  _hasValue(input) {
    return input ? input : "Saknas"
  }

  build() {
    const entry = this.data[this.data.length - 1]
    const isFirstColumn = [true, false]
    const label = {
      company: "Bolag",
      premium: "Premie",
      end_date: "Försäkrad till",
      deductible: "Självrisk grund",
      deductible_water: "Självrisk vatten",
      brf_supplement: "BRF-tillägg",
    }

    isFirstColumn.map((column) => {
      this._buildColumns(column, label, entry)
    })
  }

  _buildColumns(firstColumn, label, entry) {
    const build = `
        ${
          firstColumn
            ? `
        <div class="column __50 __xs __brand no-margin">
          <img src='${entry.company_logo_url ? entry.company_logo_url : ""}'>
        </div>`
            : `<div class="column __50 __xs no-margin ${
                firstColumn ? "" : "__gridAligned"
              }">
          <div class="list--wrapper">
            <ul>
              <li>
                <span>${label.brf_supplement}:</span>
                <span>${this._hasValue(
                  this._translate(entry.brf_supplement)
                )}</span>
              </li>
              <li>
                <span>${label.end_date}:</span>
                <span>${entry.ends_at}</span>
              </li>
            </ul>
          </div>
        </div>`
        }
      </div>
      <div class="column __50 __xs no-margin ${
        firstColumn ? "__divider" : "__columnAligned"
      }">
        <div class="list--wrapper">
          <ul>
            <li>
              <span>${firstColumn ? label.company : label.deductible}:</span>
              <span>${
                firstColumn
                  ? this._hasValue(entry.company)
                  : this._hasValue(this._toLocale(entry.deductible))
              }</span>
            </li>
            <li>
              <span>${
                firstColumn ? label.premium : label.deductible_water
              }:</span>
              <span>${
                firstColumn
                  ? this._toLocale(entry.premium)
                  : this._hasValue(this._toLocale(entry.deductible_water))
              }</span>
            </li>
          </ul>
        </div>
      </div>
    `
    firstColumn
      ? (this.gridOne.innerHTML = build)
      : (this.gridTwo.innerHTML = build)
  }
}

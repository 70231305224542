$(function () {
  //inactive buttons
  var button = $(".js-edit-button")
  button.on("click", function (e) {
    if (button.hasClass("unauthorized-button")) {
      e.preventDefault()
      return false
    }
  })

  if ($(".form-group.file").length) {
    $('input[type="file"]').change(function (e) {
      var fileLabel = $(".js-file-label")
      if (fileLabel.length == 0 || e.target.files.length == 0) {
        return
      }

      var fileName = e.target.files[0].name
      fileLabel.text(fileName + " har valts.")
    })
  }

  const allowedTypesForDescription = [
    "economic_plan",
    "photo",
    "energy_report",
    "maintenance_plan",
    "other",
    "general_document",
    "form_templates",
    "economy_document",
    "property_document",
    "rules_and_advices",
    "newsletter",
    "monthly_protocol",
    "annual_protocol",
  ]

  function toggleDocumentDescriptionVisibility() {
    if ($("#file-kind-select").length) {
      if (allowedTypesForDescription.includes($("#file-kind-select").val())) {
        $("#document-description").removeClass("hidden")
      } else {
        $("#document-description").addClass("hidden")
      }
    }
  }

  toggleDocumentDescriptionVisibility()

  if ($("#file-kind-select").length) {
    $("#file-kind-select").change(function () {
      toggleDocumentDescriptionVisibility()
    })
  }

  if ($(".profile-table")) {
    var tbody = $(".profile-table tbody")

    if (tbody.children().length == 0) {
      $(".profile-table").remove()
    }
  }

  //COUNTS CHARACTERS
  if ($(".profile-organization-content").length) {
    window.setupModal("example-modal")

    var subheading = $(this).find(".description-heading").text()
    var subNum = "160"
    if (subheading.indexOf(subNum) != -1) {
      //prints out 160
      var maxChar = $(".js-text-remain").attr("data-max-length")
      $(".js-text-remain").text(maxChar)

      //checks if textarea is already filled
      var textValue = $(".textarea-field").val()
      if (textValue !== " ") {
        var trimText = textValue.replace(/ /g, "").length
        var remainChar = maxChar - trimText
        $(".js-text-remain").text(remainChar)
        $(".js-text-remain").addClass("green")
      }

      //when user is typing
      $(".textarea-field").on("input propertychange", function () {
        $(this).keyup(function () {
          var desc = $(this),
            remain = $(".js-text-remain"),
            len = $(desc).val().length
          len = maxChar - len

          remain.text(len > 0 ? len : "0")

          if (len < 21 || len > maxChar) {
            remain.removeClass("green").addClass("red")
          } else {
            remain.removeClass("red").addClass("green")
          }

          $(desc).val($(desc).val().substring(0, maxChar))
        })
      })
    } else {
      $(".profile-remaining-chars").remove()
    }
  }

  if ($(".profile-table").length) {
    var targetItem = $(".js-delete-file").find(".profile-tool-tip")
    var trigger = $(".js-delete-file").find("a")
    trigger.on("mouseenter", function () {
      if (targetItem !== "0") {
        targetItem.css("display", "block")
      }
    })
    trigger.on("mouseleave", function () {
      if (targetItem !== "0") {
        targetItem.css("display", "none")
      }
    })
  }

  //checks if user uses a mobile device
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
    // Do something
    if ($(".form-group.file label").hasClass("js-file-label")) {
      $(".form-group.file label").text("Klicka här för att bifoga fil")
    } else if ($(".form-group.file label").hasClass("js-image-label")) {
      $(".form-group.file label").text("Klicka här för att bifoga bild")
    }
  }
})

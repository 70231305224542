import { BRFSearch } from "../../../../shared_assets/app/web_components/searchbar/search"

export class AnbudskollenSearch extends BRFSearch {
  _onResultSelection(inputElement, valueObj) {
    inputElement.value = valueObj.name.replace(/<\/?em>/g, "")

    if (document.querySelector("#organization-description")) {
      document.querySelector("#organization-description").innerHTML =
        valueObj.name
    }

    if (document.querySelector("#brfmedlem_org_nam")) {
      document.querySelector("#brfmedlem_org_name").value =
        valueObj.name.replace(/<\/?em>/g, "")
    }

    document.querySelector("#brfmedlem_org_number").value = valueObj.org_number
  }
}

import { ApplicationController } from "../support/application_controller"
import Cookies from "js-cookie"

export default class extends ApplicationController {
  static targets = [
    "modalTitle",
    "modalBody",
    "modalFooter",
    "modalCloseConfirmation",
    "form",
    "campaignId",
    "slideIndex",
    "next",
    "previous",
    "currentSlide",
    "slidesCount",
  ]

  connect() {
    if (window.location.pathname.match(/valuation|brfanalys/)) {
      return
    }

    if (!Cookies.get("onboarding_displayed")) {
      this._fetchCampaign()
    }
  }

  next() {
    // If this is the latest slide, modal will be closed.
    // Otherwise - we proceed to the next slide.
    if (this._currentSlideCounter() == this._lastSlideCounter()) {
      this._toggleModal()

      Rails.fire(this.formTarget, "submit")
      return
    }

    this._toNextSlide()
    this._updateSlideIndex()

    if (this._currentSlideCounter() >= 1) {
      this.previousTarget.classList.remove("hidden")
    }

    if (this._currentSlideCounter() == this._lastSlideCounter()) {
      this.nextTarget.innerHTML = "Avsluta"
    } else {
      this.nextTarget.innerHTML = "Nästa"
    }
  }

  previous() {
    this._toPrevSlide()
    this._updateSlideIndex()

    this.nextTarget.innerHTML = "Nästa"
    this.nextTarget.classList.remove("hidden")

    if (this._currentSlideCounter() <= 1) {
      this.previousTarget.classList.add("hidden")
    }
  }

  skip(e) {
    e.preventDefault()

    if (document.getElementById("campaign_status").checked) {
      Rails.fire(this.formTarget, "submit")
    }

    this._toggleConfirmationForm()
  }

  openInnerModal(e) {
    e.preventDefault()
    this._toggleConfirmationForm()
  }

  closeInnerModal(e) {
    e.preventDefault()
    this._toggleConfirmationForm()
  }

  _toggleModal() {
    document.getElementById("onboarding-modal").classList.toggle("falldown")
    document.body.classList.toggle("falldown")
  }

  _fetchCampaign() {
    const url = this.data.get("fetchUrl")
    const response = this.railsFetch(url)

    this._handleResponse(response)
  }

  _handleResponse(response) {
    response.then((value) => {
      if (value.hasOwnProperty("content")) {
        this.modalBodyTarget.innerHTML = ""

        value.content.forEach((element, i) => {
          if (!element.title) {
            element.title = ""
          }

          const klass = i > 0 ? "hidden" : "active"
          const slideHTML = `<div class='onboarding_modal__body-slide ${klass}' data-title='${element.title}'>${element.contents}</div>`

          this.modalBodyTarget.insertAdjacentHTML("beforeend", slideHTML)
        })

        this._updateTitle(0)

        if (!value.close_confirmation) {
          this._disableCloseConfirmation()
        }

        if (parseInt(value.slide_count) == 1) {
          this.modalFooterTarget.classList.add("hidden")
        }

        this.slidesCountTarget.innerHTML = value.slide_count
        this.campaignIdTarget.value = value.id

        setupModal("onboarding-modal", {
          preventClosingFromOutside: true,
          preventClosingByKey: true,
        })
        this._toggleModal()

        Cookies.set("onboarding_displayed", "true", { expires: 365, path: "/" })
      }
    })
  }

  _updateTitle(slideIndex) {
    const element = this._slideByIndex(slideIndex)
    this.modalTitleTarget.innerHTML = element.getAttribute("data-title")
  }

  _toNextSlide() {
    if (this._currentSlideCounter() < this._lastSlideCounter()) {
      const nextSlide = this._slideByIndex(this._currentSlideCounter())
      const currentSlide = this._slideByIndex(this._currentSlideCounter() - 1)

      this._toggleSlide(nextSlide)
      this._toggleSlide(currentSlide)

      this._updateTitle(this._currentSlideCounter())
      this.currentSlideTarget.innerHTML = this._currentSlideCounter() + 1
    }
  }

  _toPrevSlide() {
    if (this._currentSlideCounter() >= 1) {
      const prevSlide = this._slideByIndex(this._currentSlideCounter() - 2)
      const currentSlide = this._slideByIndex(this._currentSlideCounter() - 1)

      this._toggleSlide(prevSlide)
      this._toggleSlide(currentSlide)

      this._updateTitle(this._currentSlideCounter() - 2)
      this.currentSlideTarget.innerHTML = this._currentSlideCounter() - 1
    }
  }

  _toggleSlide(slide) {
    slide.classList.toggle("active")
    slide.classList.toggle("hidden")
  }

  _toggleConfirmationForm() {
    document
      .querySelector(".onboarding_modal__close-confirmation")
      .classList.toggle("falldown")
  }

  _slideByIndex(slideIndex) {
    return document.getElementsByClassName("onboarding_modal__body-slide")[
      slideIndex
    ]
  }

  _currentSlideCounter() {
    return parseInt(this.currentSlideTarget.innerHTML)
  }

  _lastSlideCounter() {
    return parseInt(this.slidesCountTarget.innerHTML)
  }

  _updateSlideIndex() {
    this.slideIndexTarget.value = this._currentSlideCounter()
  }

  _disableCloseConfirmation() {
    this.modalBodyTarget.removeAttribute("data-action")
    this.modalCloseConfirmationTarget.removeAttribute("data-action")
    this.modalCloseConfirmationTarget.classList.add("onboarding-modal__close")
  }
}

import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import { BrokerSnapshotsBrfSearch } from "../modules/broker_snapshots_search_extension"
import Litepicker from "litepicker"

export default class extends ApplicationController {
  static targets = [
    "form",
    "propertySearch",
    "submitButton",
    "confirmationCheckbox",
    "payment",
    "klarnaPlaceholder",
    "klarnaOrderId",
    "contractLabel",
    "membershipApplicationLabel",
  ]

  connect() {
    this._disableFields()
    this._setupBrfSearch()
    this._setupLitePickes()
  }

  confirmation() {
    if (this.confirmationCheckboxTarget.checked) {
      this.submitButtonTarget.classList.remove("button--tertiary")
      this.submitButtonTarget.classList.add("button--primary")
    } else {
      this.submitButtonTarget.classList.add("button--tertiary")
      this.submitButtonTarget.classList.remove("button--primary")
    }
  }

  switchBuyerRequiredFields(e) {
    const fields = Array.from(
      e.target.closest(".fields-holder").querySelectorAll("input")
    )
    const isRequired = fields.filter((item) => item.value !== "").length > 0

    fields.forEach((field) => {
      field.required = isRequired
    })
  }

  onShareChange(e) {
    const field = e.target
    const fields = field
      .closest(".buyer-items-holder")
      .querySelectorAll(".buyer-share")
    let sumShare = 0

    if (!field.dataset.mainBuyer) {
      this.switchBuyerRequiredFields(e)
    }

    fields.forEach((el) => {
      const value = parseInt(el.value)

      if (Number.isInteger(value)) {
        sumShare += value
      }
    })

    if (sumShare > 100) {
      field.setCustomValidity("andel uppgår till mer än 100 %")
      field.reportValidity()

      return false
    } else {
      fields.forEach((el) => {
        el.setCustomValidity("")
      })
    }
  }

  changePayment(e) {
    const payment = e.target.dataset.payment
    this.paymentTarget.value = payment

    this._removeSelectPaymnet(e.target)
    this._addSelectPayment(e.target)
  }

  validateForm(reportValidity = false) {
    let isValid = true
    const requiredFieldSelectors = "textarea:required, input:required"
    const requiredFields = this.formTarget.querySelectorAll(
      requiredFieldSelectors
    )

    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        isValid = false
      }
    })

    const invalidFields = this.formTarget.querySelectorAll("input:invalid")

    invalidFields.forEach((field) => {
      if (!field.disabled) {
        if (reportValidity) {
          field.focus()
          field.reportValidity()
        }

        isValid = false
      }
    })

    return isValid
  }

  removeOwner(e) {
    let owner = e.target.closest(".buyer-item")

    owner.remove()
  }

  appendOwner(e) {
    e.preventDefault()

    const ownersCount = document.querySelectorAll(".buyer-item").length

    document
      .querySelector(".buyer-items-holder")
      .insertAdjacentHTML("beforeend", this._ownerTemplate(ownersCount))
  }

  showPayment(e) {
    e.preventDefault()

    const isValid = this.validateForm(false)

    if (isValid && this.confirmationCheckboxTarget.checked) {
      this._fetchKlarnaUI()
    } else {
      $("#klarna-placeholder").html("")
    }
  }

  submitForm(e) {
    e.preventDefault()

    const isValid = this.validateForm(true)

    if (isValid && this.confirmationCheckboxTarget.checked) {
      this.formTarget.submit()
    }
  }

  updateContractLabel(e) {
    if (e.target.files.length === 0) {
      this.contractLabelTarget.innerHTML =
        "Ladda upp överlåtelseavtal (drag och släpp filen här)"
    } else {
      this.contractLabelTarget.innerHTML = e.target.files[0].name
    }
  }

  updateMembershipApplicationLabel(e) {
    if (e.target.files.length === 0) {
      this.membershipApplicationLabelTarget.innerHTML =
        "Ladda upp medlemsansökan (drag och släpp filen här)"
    } else {
      this.membershipApplicationLabelTarget.innerHTML = e.target.files[0].name
    }
  }

  _disableFields() {
    this.propertySearchTarget.setAttribute("disabled", "true")

    if (this.submitButton) {
      this.submitButtonTarget.setAttribute("disabled", "true")
    }
  }

  _fetchKlarnaUI() {
    const data = {
      org_number: document.querySelector("#brfmedlem_org_number").value,
      product: "broker_snapshot",
      current_url: window.location.href,
      payment: this.paymentTarget.value,
      user_id: this.data.get("userId"),
      source: this.data.get("source"),
    }

    const response = this.railsCreate(this.data.get("klarnaUrl"), data)

    this._handleResponse(response)
  }

  _handleResponse(response) {
    response.then((value) => {
      try {
        this.klarnaOrderIdTarget.value = value.order_id
        const url = this.data.get("order-url")
        const formData = new FormData(this.formTarget)

        $("#klarna-placeholder").html(value.html)
        this.railsCreate(url, formData, true)
      } catch (error) {
        Sentry.captureException(error)
        this.klarnaPlaceholderTarget.innerHTML =
          "Något gick fel, prova att ladda om sidan."
      }
    })
  }

  _setupLitePickes() {
    if (document.querySelector("#litepickerStart")) {
      this._setUpLitePickerStart()
      this._setUpLitePickerEnd()
    }
  }

  _setUpLitePickerStart() {
    new Litepicker({
      element: document.getElementById("litepickerStart"),
      lang: "sv-SV",
      firstDay: 1,
      minDays: 1,
      numberOfMonths: 1,
      numberOfColumns: 1,
      singleMode: true,
      showTooltip: false,
      mobileFriendly: true,
      zIndex: 9999999,
      setup: (picker) => {
        picker.on("selected", (date) => {
          document.getElementById("litepickerStart").value =
            this._toLocateDateString(date.dateInstance)
        })
      },
    })
  }

  _setUpLitePickerEnd() {
    new Litepicker({
      element: document.getElementById("litepickerEnd"),
      lang: "sv-SV",
      firstDay: 1,
      minDays: 1,
      numberOfMonths: 1,
      numberOfColumns: 1,
      singleMode: true,
      showTooltip: false,
      mobileFriendly: true,
      zIndex: 9999999,
      setup: (picker) => {
        picker.on("selected", (date) => {
          document.getElementById("litepickerEnd").value =
            this._toLocateDateString(date.dateInstance)
        })
      },
    })
  }

  _toLocateDateString(date) {
    return date.toLocaleDateString("sv-se")
  }

  _removeSelectPaymnet(target) {
    let parent = target.closest(".cards")

    parent.querySelectorAll(".card").forEach((el) => {
      el.classList.remove("selected")
    })
  }

  _addSelectPayment(target) {
    let card = target.closest(".card")

    card.classList.add("selected")
  }

  _setupBrfSearch() {
    const propertiesUrl = this.data.get("propertiesUrl")
    const search = new BrokerSnapshotsBrfSearch({
      property: this.propertySearchTarget,
      propertiesUrl: propertiesUrl,
    })

    search.append()
  }

  _ownerTemplate(ownersCount) {
    return `<div class="buyer-item">
        <div class="buyer-title">
        Köpare ${ownersCount + 1}
        <span class="buyer-cancel" data-action="click->broker-transfer#removeOwner"></span>
        </div>
        <div class="fields-holder">
          <input type="text" name="transfer[buyers_attributes][${ownersCount}][name]" placeholder="Fullständigt namn" class="field-full" data-action="change->broker-transfer#switchBuyerRequiredFields">
          <input type="text" name="transfer[buyers_attributes][${ownersCount}][ss_number]" placeholder="Personnr" class="field-half" data-action="change->broker-transfer#switchBuyerRequiredFields">
          <input type="number" name="transfer[buyers_attributes][${ownersCount}][share]" min="0" max="100" step="1" placeholder="Ägarandel" class="field-half buyer-share" data-action="change->broker-transfer#onShareChange">
          <input type="text" name="transfer[buyers_attributes][${ownersCount}][phone_number]" placeholder="Telefon" class="field-half">
          <input type="email" name="transfer[buyers_attributes][${ownersCount}][email]" placeholder="Epost" class="field-half">
        </div>
      </div>
      `
  }
}

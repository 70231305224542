import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"

export default class extends ApplicationController {
  static targets = ["buttonPlaceholder"]

  connect() {
    this._selectButton()
  }

  _selectButton() {
    const userBroker = this.data.get("broker")
    if (userBroker) {
      this._showBrokerButton()
      return
    }
    const payload = { user_email: this.data.get("email") }
    const today = new Date()
    this.railsFetch(this.data.get("productsUrl"), payload).then((response) => {
      const expDate = new Date(response.expiration_date)

      if (response.subscription_status === "active" || expDate >= today) {
        this._showDownloadButton()
      } else {
        this._showPurchaseButton()
      }
    })
  }

  _showBrokerButton() {
    const html = `<a class="button--primary" href="${this.data.get(
      "brokerPath"
    )}" rel="nofollow">
    Hämta analys på brfdata.se
    </a>`

    this.buttonPlaceholderTarget.innerHTML = html
  }

  _showDownloadButton() {
    const html = `<a class="button--primary" href="${this.data.get(
      "downloadPath"
    )}" rel="nofollow">
    Hämta analys
    </a>`

    this.buttonPlaceholderTarget.innerHTML = html
  }

  _showPurchaseButton() {
    const html = `<a class="button--primary" href="${this.data.get(
      "purchasePath"
    )}" rel="nofollow">
    Köp analys (99 kr)
    </a>`

    this.buttonPlaceholderTarget.innerHTML = html
  }
}

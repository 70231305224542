import Handlebars from "handlebars/dist/cjs/handlebars"
import * as Sentry from "@sentry/browser"
import { setupApartmentsChart } from "./modules/setup_apartments_chart"
import { setupModal } from "./shared/setup_modal"
import { updateCSRFToken } from "./shared/update_csrf_token"
import {
  clonableInputs,
  initClonedRemovalLinks,
  removeClonedInputs,
} from "./shared/clonableInputs"

window.Handlebars = Handlebars
window.setupModal = setupModal
window.setupApartmentsChart = setupApartmentsChart

$(document).ajaxComplete((_event, xhr, _settings) => {
  updateCSRFToken(xhr)
})

Sentry.init({
  dsn: "https://46382f000443a9936e67b5c8d4aea449@o44841.ingest.us.sentry.io/4506939221671936",
  allowUrls: [/allabrf/, /anbudskollen/, /brfdata/],
  ignoreErrors: [
    "Blocked a frame with origin",
    "Load denied by X-Frame-Options",
    "Page is iframe",
    "Unable to get property 'x' of undefined or null reference",
    "a[b].target.className.indexOf",
    "ResizeObserver loop limit exceeded",
    /freed script/,
    /Cannot access rules/,
    /UnhandledRejection/,
    "Invalid scheme",
    /fetch/,
  ],
  beforeSend(event) {
    if (window.navigator.userAgent.indexOf("BingPreview") !== -1) return null

    return event
  },
})

const targetEvents = ["DOMContentLoaded", "InitClonableInputs"]
targetEvents.forEach((targetEvent) =>
  document.addEventListener(targetEvent, (_event) => {
    clonableInputs()
    initClonedRemovalLinks()
    removeClonedInputs()
  })
)

document.addEventListener("ClonableInputChanged", (_event) => {
  removeClonedInputs()
})

import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import { AnbudskollenSearch } from "../modules/anbudskollen_search_extension"

export default class extends ApplicationController {
  connect() {
    this._setupSearch()
  }

  _setupSearch() {
    const search = new AnbudskollenSearch()
    search.append()
  }
}

import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import { DynamicLabelDecorator } from "../../../../shared_assets/app/webpack_assets/shared/dynamic_label_decorator"
import { FileInputDecorator } from "../../../../shared_assets/app/webpack_assets/shared/file_input_decorator"

export default class extends ApplicationController {
  static targets = [
    "input",
    "fileInput",
    "documentTemplate",
    "documentsContainer",
    "documentItem",
    "deleteDocument",
    "sort",
  ]
  static values = {
    url: String,
  }

  #dynamicLabelDecorator
  #fileInputDecorator
  #sortIndex
  #sortDirection
  #sortClasses
  #adjustInputIndexes
  #lockSort

  initialize() {
    this.#dynamicLabelDecorator = new DynamicLabelDecorator()
    this.#fileInputDecorator = new FileInputDecorator()
    this.#sortIndex = 0
    this.#sortDirection = ["default", "asc"]
    this.#sortClasses = {
      default: undefined,
      asc: "sort-asc",
    }
  }

  connect() {
    this.#dynamicLabelDecorator.init(this.inputTargets)
    this.#fileInputDecorator.init(this.fileInputTargets)
    this.#adjustInputIndexes = new InputIndexesAdjustment(
      (template, currentIndex) =>
        (template.querySelector("input.order").value = currentIndex)
    )
  }

  sort(event) {
    if (this.#lockSort) {
      return
    }

    if (this.#sortClasses[this.#sortDirection[this.#sortIndex]]) {
      event.currentTarget.classList.remove(
        this.#sortClasses[this.#sortDirection[this.#sortIndex]]
      )
    }
    this.#sortIndex = (this.#sortIndex + 1) % this.#sortDirection.length
    if (this.#sortClasses[this.#sortDirection[this.#sortIndex]]) {
      event.currentTarget.classList.add(
        this.#sortClasses[this.#sortDirection[this.#sortIndex]]
      )
    }

    let sorter
    switch (this.#sortDirection[this.#sortIndex]) {
      case "asc":
        sorter = (a, b) =>
          a
            .querySelector('input[name*="title"]')
            .value.localeCompare(b.querySelector('input[name*="title"]').value)
        break
      case "desc":
        sorter = (a, b) =>
          b
            .querySelector('input[name*="title"]')
            .value.localeCompare(a.querySelector('input[name*="title"]').value)
        break
      default:
        sorter = (a, b) => {
          const aVal = parseInt(
            a
              .querySelector('input[name*="title"]')
              .name.match(/.*\[(\d+)\].*/)[1]
          )
          const bVal = parseInt(
            b
              .querySelector('input[name*="title"]')
              .name.match(/.*\[(\d+)\].*/)[1]
          )
          return aVal < bVal ? -1 : aVal > bVal ? 1 : 0
        }
    }

    const sortedDocumentElements = this.documentItemTargets.sort(sorter)
    this.documentsContainerTarget.replaceChildren(...sortedDocumentElements)
  }

  addDocument(event) {
    const newDocumentTemplate =
      this.documentTemplateTarget.content.cloneNode(true)
    this.#adjustInputIndexes.perform(newDocumentTemplate)

    const newInputs = newDocumentTemplate.querySelectorAll("input")
    this.#dynamicLabelDecorator.init(newInputs)

    const newFileInputs =
      newDocumentTemplate.querySelectorAll("input[type=file]")
    this.#fileInputDecorator.init(newFileInputs)

    this.documentsContainerTarget.appendChild(newDocumentTemplate)
    this.lockSort()
  }

  edit(event) {
    const documentContainer =
      event.currentTarget.parentElement.parentElement.parentElement
    this.#unlockForEditing(documentContainer)
  }

  deleteDocument(event) {
    const documentContainer =
      event.currentTarget.parentElement.parentElement.parentElement
    if (window.confirm(event.currentTarget.dataset.message)) {
      documentContainer.remove()
    }
  }

  destroy(event) {
    if (window.confirm(event.currentTarget.dataset.message)) {
      const documentContainer =
        event.currentTarget.parentElement.parentElement.parentElement
      documentContainer.classList.add("destroy")

      documentContainer
        .querySelectorAll("[type=text],[type=file]")
        .forEach((input) => (input.disabled = true))

      const destroyHiddenInput =
        event.currentTarget.querySelector("template").content.children[0]
      event.currentTarget.append(destroyHiddenInput)
      event.currentTarget.hidden = true
    }
  }

  lockSort(event) {
    this.sortTarget.classList.add("disabled")
    this.#lockSort = true
  }

  #unlockForEditing(documentContainer) {
    if (documentContainer.classList.contains("edit")) {
      return
    }

    documentContainer.classList.add("edit")
    const documentInputs = this.inputTargets.filter((input) =>
      documentContainer.contains(input)
    )
    documentInputs.forEach((input) => {
      input.parentElement.classList.remove("disabled")
      input.disabled = !input.disabled
    })
  }
}

export class InputIndexesAdjustment {
  #fnCustomAdjustments
  #templateIndex
  #currentIndex

  constructor(fnCustomAdjustments) {
    this.#fnCustomAdjustments = fnCustomAdjustments
  }

  perform(template) {
    const inputs = template.querySelectorAll("input,textarea")

    this.#templateIndex =
      this.#templateIndex || parseInt(inputs[0].name.match(/.*\[(\d+)\].*/)[1])
    this.#currentIndex = this.#currentIndex || this.#templateIndex

    inputs.forEach((input) => {
      input.name = input.name.replace(
        `[${this.#templateIndex}]`,
        `[${this.#currentIndex}]`
      )
      input.id = input.id.replace(
        `_${this.#templateIndex}_`,
        `_${this.#currentIndex}_`
      )
    })

    const labels = template.querySelectorAll("label")
    labels.forEach((label) => {
      label.setAttribute(
        "for",
        label
          .getAttribute("for")
          .replace(`_${this.#templateIndex}_`, `_${this.#currentIndex}_`)
      )
    })

    if (this.#fnCustomAdjustments) {
      this.#fnCustomAdjustments(template, this.#currentIndex)
    }

    this.#currentIndex++
  }
}

import autoComplete from "@tarekraafat/autocomplete.js"

export class BRFSearch {
  constructor() {
    this.searchbarButton = document.querySelector(".search-button")
    this.btnSwitch = 1

    this.useDropdownEvents = false
    this.useNavbarEvents = false
    this.selector = ".brf-autocomplete"
  }

  dropdownEvents() {
    this.useDropdownEvents = true
  }

  navbarEvents() {
    this.useNavbarEvents = true
  }

  append() {
    document.querySelectorAll(this.selector).forEach((inputElement) => {
      this._initAutoComplete(inputElement)

      if (this.useDropdownEvents) {
        this._dropdownEvents(inputElement)
      }

      if (this.useNavbarEvents) {
        this._navbarEvents(inputElement)
      }
    })
  }

  _navbarEvents(inputElement) {
    const searchButton = document.querySelector(".searchicon")

    if (searchButton) {
      searchButton.addEventListener("click", () => {
        inputElement.closest(".search-box").classList.add("active")
        inputElement.focus()
      })
    }

    const closeButton = document.querySelector(".search-close")

    if (closeButton) {
      closeButton.addEventListener("click", () => {
        inputElement.closest(".search-box").classList.remove("active")
      })
    }
  }

  _dropdownEvents(inputElement) {
    inputElement.addEventListener("keypress", () => {
      if (this.btnSwitch === 1) {
        this.searchbarButton.classList.toggle("active")
        this.btnSwitch = 2
      }
    })
  }

  _initAutoComplete(element) {
    const autocompleteJS = new autoComplete({
      selector: () => {
        return element
      },
      data: this._dataSource(),
      searchEngine: "loose",
      debounce: 250,
      resultsList: {
        render: true,
        tag: "ul",
        id: "autoComplete_list",
        class: "autoComplete_list",
        position: "afterend",
        noResults: true,
        element: (list, data) => {
          if (!data.results.length) {
            const message = document.createElement("li")

            message.setAttribute("class", "no_result")
            message.setAttribute("tabindex", "1")
            message.innerHTML = "Inga resultat hittades."

            list.prepend(message)
          }
        },
      },
      resultItem: {
        tag: "li",
        element: (item, data) => {
          if (data.key === "org_number") {
            data.match = `<em>${data.value.name}`
          }

          item.innerHTML = data.match.replace(
            /Bostadsföreningen|Bostadsrättsföreningen|Bostadsrättsförening/g,
            "BRF"
          )
        },
      },
    })

    autocompleteJS.input.addEventListener("selection", (event) => {
      if (typeof this.onSelection !== "undefined") {
        return this.onSelection(
          autocompleteJS.input,
          event.detail.selection.value
        )
      } else {
        return this._onResultSelection(
          autocompleteJS.input,
          event.detail.selection.value
        )
      }
    })
  }

  _dataSource() {
    if (typeof this.src === "undefined") {
      return {
        src: async (query) => {
          const resp = await fetch(
            `${this._host()}/items/names?query=${encodeURIComponent(query)}`
          )
          const data = await resp.json()
          return data.items
        },
        keys: ["name", "org_number"],
        cache: false,
      }
    } else {
      return {
        src: this.src,
        cache: false,
      }
    }
  }

  _host() {
    const locationHost = window.location.host
    const locationPort = window.location.port
    const engines = [
      "staging.brfdata",
      "brfdata",
      "staging.anbudskollen",
      "anbudskollen",
      "staging.maklarportal.allabrf",
      "maklarportal.allabrf",
      "staging.allabrf",
      "allabrf",
    ]

    const tildaPages = "sv.allabrf.se"

    const hostExists = engines.some((engine) => {
      return locationHost.includes(engine) && !locationHost.includes(tildaPages)
    })

    if (hostExists) {
      return window.location.origin
    } else {
      return `https://www.allabrf.se:${locationPort}`
    }
  }

  _onResultSelection(inputElement, valueObj) {
    const container = inputElement.closest(".search-box")
    switch (container.dataset.placement) {
      case "landing":
      case "navbar":
        inputElement.value = valueObj.name.replace(/<\/?em>/g, "")

        if (valueObj.type === "area") {
          location.href = `${this._host()}/items/summaries?query=${encodeURIComponent(
            valueObj.name.replace(/<\/?em>/g, "")
          )}`
        } else {
          location.href = `${this._host()}/${encodeURIComponent(valueObj.slug)}`
        }

        break
      case "brfdata":
        inputElement.value = valueObj.name.replace(/<\/?em>/g, "")

        if (valueObj.type === "area") {
          location.href = `/brfdata/search?query=${encodeURIComponent(
            valueObj.name.replace(/<\/?em>/g, "")
          )}`
        } else {
          location.href = `/brfdata/${encodeURIComponent(valueObj.slug)}`
        }

        break
      case "tilda":
        inputElement.value = valueObj.name.replace(/<\/?em>/g, "")

        const orgNumberInputElement = inputElement
          .closest("form")
          .querySelector(".orgnumber-autocomplete")
        if (orgNumberInputElement) {
          orgNumberInputElement.value = valueObj.org_number
        }
      default:
        break
    }
  }
}

import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"

export default class extends ApplicationController {
  static targets = [
    "leaveQueueForm",
    "leavePlaceForm",
    "addPlaceForm",
    "addQueueForm",
    "headerDialog",
    "bodyDialog",
    "mainFooterDialog",
    "switchFooterDialog",
    "dialogContainer",
    "bankContent",
    "parkingInfoContainer",
  ]

  connect() {
    this.residentEmail = this.data.get("resident-email")
  }

  onCloseQueue(e) {
    e.preventDefault()
    this.leaveQueueFormTarget.classList.toggle("falldown")
  }

  onClosePlace(e) {
    e.preventDefault()
    this.leavePlaceFormTarget.classList.toggle("falldown")
  }

  onHidePlace(e) {
    e.preventDefault()

    e.target.closest(".dialog-container").innerHTML = ""
  }

  onSubmitForm(e) {
    const form = e.target.closest("form")

    Rails.fire(form, "submit")
  }

  getPartial(e) {
    e.preventDefault()

    const url = e.currentTarget.getAttribute("data-url")
    const modal = e.currentTarget.getAttribute("data-modal")
    this.currentContainer = e.target.closest(".parking-item-container")
    const response = this.railsFetch(url)

    this._handleDialogResponse(response, modal)
  }

  _handleDialogResponse(response, dialog) {
    response.then((value) => {
      this.dialogContainerTarget.innerHTML = value.html

      setupModal(dialog)
      this._toggleDialog(dialog)
    })
  }

  onAddPlaceTrigger(e) {
    e.preventDefault()
    this._setResponseContainer()
    this.addPlaceFormTarget.classList.toggle("falldown")
  }

  onAddQueue(e) {
    e.preventDefault()

    const url = e.currentTarget.getAttribute("data-url")
    const formData = new FormData(e.target.closest("form"))
    const response = this.railsCreate(url, formData, true)

    this._handleQueueResponse(e, response)
  }

  _handleQueueResponse(e, response) {
    response.then((value) => {
      this.currentContainer.innerHTML = value.html

      this.closeQueueDialog(e)
    })
  }

  onLeaveQueue(e) {
    const url = e.currentTarget.getAttribute("data-url")
    const formData = new FormData(e.target.closest("form"))
    const response = this.railsUpdate(url, formData, true)

    this._handleLeaveQueueResponse(e, response)
  }

  _handleLeaveQueueResponse(e, response) {
    response.then((value) => {
      this.currentContainer.innerHTML = value.html

      this._toggleDialog("tenant-leave-queue-modal")
    })
  }

  onLeavePlace(e) {
    e.preventDefault()

    const url = e.currentTarget.getAttribute("data-url")
    const response = this.railsDelete(url, true)

    this._handleLeavePlaceResponse(e, response)
  }

  _handleLeavePlaceResponse(e, response) {
    response.then((value) => {
      this.currentContainer.innerHTML = value.html

      this._toggleDialog("tenant-add-place-modal")
    })
  }

  onDeletePlace(e) {
    const url = e.currentTarget.getAttribute("data-url")
    const response = this.railsDelete(url, true)

    this._handleDeletePlaceResponse(response)
  }

  _handleDeletePlaceResponse(response) {
    response.then((value) => {
      this.headerDialogTarget.innerHTML = "Ditt avtal har avslutats"
      this.bodyDialogTarget.innerHTML = `Ett bekfräftelsemail har skickats till ${this.residentEmail}.`

      this.mainFooterDialogTarget.classList.add("hidden")
      this.switchFooterDialogTarget.classList.remove("hidden")
      this._remove_or_set_card(value)
    })
  }

  closeQueueDialog(e) {
    e.preventDefault()
    this.addQueueFormTarget.classList.toggle("falldown")
  }

  _openDialog(e, modal, form) {
    this.currentContainer = e.target.closest(".parking-item-container")
    const [data, status, xhr] = e.detail

    if (status == "OK") {
      this.dialogContainerTarget.innerHTML = data.html

      setupModal(modal)
      this._toggleDialog(modal)
    }
  }

  _toggleDialog(modal) {
    switch (modal) {
      case "tenant-leave-place-modal":
        this.leavePlaceFormTarget.classList.toggle("falldown")
        break
      case "tenant-leave-queue-modal":
        this.leaveQueueFormTarget.classList.toggle("falldown")
        break
      case "tenant-add-queue-modal":
        this.addQueueFormTarget.classList.toggle("falldown")
        break
      case "tenant-add-place-modal":
        this.addPlaceFormTarget.classList.toggle("falldown")
        break
      default:
        break
    }
  }

  onBankId(e) {
    const url = e.currentTarget.getAttribute("data-url")

    const response = this.railsFetch(url)
    window.currentContainer = this.currentContainer
    window.dialog = this.addPlaceFormTarget

    this._handleResponse(response)
  }

  toggleParkingInfo(e) {
    e.preventDefault()

    this.parkingInfoContainerTarget.classList.toggle("hidden")
    this.bankContentTarget.classList.toggle("hidden")
  }

  _setResponseContainer() {
    if (this.responsePlace) {
      this.currentContainer.innerHTML = this.responsePlace
      this.responsePlace = null
    }
  }

  _showResponseContainer(e, form) {
    const [data, status, xhr] = e.detail

    if (status == "OK") {
      this.currentContainer.innerHTML = data.html

      this._toggleDialog(form)
    }
  }

  _handleResponse(response) {
    response.then((value) => {
      this.bankContentTarget.innerHTML = value.activeElement.innerHTML
    })
  }

  _remove_or_set_card(data) {
    this.currentContainer.innerHTML = data.html
    const has_card =
      document.querySelectorAll(`.kind-button-${data.id}`).length > 1

    if (has_card) {
      this.currentContainer.remove()
    }
  }
}

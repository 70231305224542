export class SpecificationsGraph {
  constructor(container) {
    this.sortedData
    this.container = container
    this.arrayOfData = [
      ["Kategori", "Utgifter", { label: "Kategori", role: "tooltip" }],
    ]
  }

  plot() {
    google.charts
      .load("visualization", { packages: ["corechart"] })
      .then(() => {
        this._drawChart()
      })
  }

  assemble(arr) {
    const dataArray = []
    const expensesData = []

    arr.map((el) => {
      if (el.kind === "expense") {
        expensesData.push(el)

        dataArray.push({
          category: el["account_group_name"],
          value: 0,
        })
      }
    })

    this.sortedData = dataArray.reduce((arr, item) => {
      if (!arr.some((obj) => obj.category === item.category)) {
        arr.push(item)
      }
      return arr
    }, [])

    this.sortedData.forEach((el) => {
      let expenses = 0

      expensesData
        .filter((cost) => cost.account_group_name === el["category"])
        .forEach((_el) => (expenses += _el["amount"]))

      el["value"] = expenses
    })
  }

  _drawChart() {
    this.sortedData.map((el) => {
      const noNegatives = Math.abs(el["value"])
      this.arrayOfData.push([
        el["category"],
        noNegatives,
        `${this._toUpperCase(el["category"])}: ${el["value"].toLocaleString(
          "sv-se",
          { useGrouping: true }
        )} SEK`,
      ])
    })

    const data = new google.visualization.arrayToDataTable(this.arrayOfData)
    const chart = new google.visualization.PieChart(this.container)

    chart.draw(data, this._options())
  }

  _toUpperCase(key) {
    return key.toString().charAt(0).toUpperCase() + key.slice(1)
  }

  _options() {
    return {
      pieHole: 0.6,
      width: 240,
      height: 210,
      pieSliceText: "none",
      colors: ["#59277e", "#7a5298", "#9b7db2", "#bda9cb"],
      legend: "none",
      chartArea: {
        top: "9%",
        width: "100%",
        height: "85%",
      },
    }
  }
}

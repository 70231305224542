export function hideNavbar() {
  _toggleNavbar(false)
}

export const toggleNavbar = () => {
  const menuSwitch = _getMenuSwitch()
  if (menuSwitch) {
    menuSwitch.addEventListener("click", (e) => {
      e.preventDefault()

      _toggleNavbar()
    })
  }
}

function _getMenuSwitch() {
  return document.querySelector(".menu--toggler")
}

function _toggleNavbar(force) {
  _getMenuSwitch().classList.toggle("__active", force)

  document.querySelectorAll(".nav--main").forEach((el) => {
    el.classList.toggle("__active", force)
  })
}

import * as Sentry from "@sentry/browser"
import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import apartmentsFiltering from "../modules/apartment_valuation_filtering"

export default class extends ApplicationController {
  static targets = ["form"]

  connect() {
    apartmentsFiltering()
  }

  create() {
    if (this.formTarget.checkValidity()) {
      this._createValuation()
      this.formTarget.classList.add("hide")
    }
  }

  _handleResponse(response) {
    response.then((value) => {
      try {
        $("#klarna-placeholder").html(value.html)
      } catch (error) {
        Sentry.captureException(error)
        this.klarnaPlaceholderTarget.innerHTML =
          "Något gick fel, prova att ladda om sidan."
      }
    })
  }

  _createValuation() {
    const url = this.data.get("purchaseUrl")
    const formData = new FormData(this.formTarget)
    const response = this.railsCreate(url, formData, true)

    this._handleResponse(response)
  }
}

export function setupApartmentsChart() {
  if (
    !document.querySelectorAll("#module-lagenheter, #apartments-chart").length
  ) {
    return
  }

  // group together all apartments bigger than 5 rooms
  var roomsDistribution = [
    ["1 rum", 0, 0],
    ["2 rum", 0, 0],
    ["3 rum", 0, 0],
    ["4 rum", 0, 0],
    ["5+ rum", 0, 0],
  ]

  $("#apartments-chart")
    .data("rooms-distribution")
    .forEach(function (keyVal) {
      var key = Math.min(keyVal[0], 5)
      var val = keyVal[1]

      roomsDistribution[key - 1][1] += val
      roomsDistribution[key - 1][2] += val
    })

  google.charts.load("current", {
    packages: ["corechart"],
    language: "sv",
    callback: drawChart,
  })

  function drawChart() {
    var data = new google.visualization.DataTable()
    data.addColumn("string", "NumbRooms")
    data.addColumn("number", "Antal")
    data.addColumn({ type: "number", role: "annotation" })
    data.addRows(roomsDistribution)

    var options = {
      annotations: {
        textStyle: {
          color: "#666666",
          fontName: "Lato",
          fontSize: 14,
        },
      },
      legend: "none",
      vAxis: {
        textPosition: "none",
        viewWindow: {
          min: 0,
        },
        gridlines: {
          color: "transparent",
        },
        textStyle: {
          color: "#666666",
          fontName: "Lato",
          fontSize: "14",
        },
        formatOptions: { groupingSymbol: " " },
        baselineColor: "#cccccc",
      },
      hAxis: {
        textStyle: {
          color: "#666666",
          fontName: "Lato",
          fontSize: "14",
        },
      },
      colors: ["#59277e", "#d7d3c8"],
      animation: {
        startup: true,
        duration: 500,
        easing: "out",
      },
      fontSize: "16",
      fontName: "Lato",
      tooltip: { isHtml: true },
      chartArea: {
        left: "14%",
        width: "75%",
      },
      bar: {
        groupWidth: "45%",
      },
    }

    var chart = new google.visualization.ColumnChart(
      document.getElementById("apartments-chart")
    )
    chart.draw(data, options)

    window.onresize = function () {
      chart.draw(data, options)
    }
  }
}

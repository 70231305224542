import * as Sentry from "@sentry/browser"
import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import { setupScrollTop } from "../modules/setup_scroll"
import { setupModal } from "../../../../shared_assets/app/webpack_assets/shared/setup_modal"
import { BRFSearch } from "../../../../shared_assets/app/web_components/searchbar/search"
import {
  hideNavbar,
  toggleNavbar,
} from "../../../../shared_assets/app/web_components/navbar/navbar.js"

import { DynamicLabelDecorator } from "../../../../shared_assets/app/webpack_assets/shared/dynamic_label_decorator"

export default class extends ApplicationController {
  connect() {
    toggleNavbar()
    this._setupScrollTop()
    this._setupDynamicLabels()

    if (document.querySelector(".searchicon")) {
      this._setupSearch()
    }

    setupModal("authentication-modal")
    document.addEventListener("partial-fetched", () => {
      this._setupDynamicLabels()
    })
  }

  _setupScrollTop() {
    const scrollButton = document.querySelector(".scrollTop")
    if (scrollButton) {
      setupScrollTop(scrollButton)
    }
  }

  _setupDynamicLabels() {
    try {
      const inputs = document.querySelectorAll(".value-size-check input")
      if (inputs) {
        const dynamicLabelDecorator = new DynamicLabelDecorator(inputs)
        dynamicLabelDecorator.init()
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  // Used on the organization page (mobile)
  removeMenu() {
    hideNavbar()
  }

  _setupSearch() {
    const search = new BRFSearch()

    search.navbarEvents()
    search.dropdownEvents()
    search.append()
  }
}

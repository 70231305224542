export function inquiryFormHandler(form, result) {
  if (result.status == "bad_request") {
    document.querySelector(".signup__form h1").innerHTML = result.message
  } else {
    document.querySelector(".container__signup").innerHTML = result.form

    if (result.trigger_ga_event && typeof ga != "undefined") {
      ga("send", "event", "Upphandling", "Förfrågan")
    }
  }
}

import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import { setupModal } from "../../../../shared_assets/app/webpack_assets/shared/setup_modal"
import { AnbudskollenSearch } from "../modules/anbudskollen_search_extension"
import "slick-carousel"

export default class extends ApplicationController {
  static targets = ["requestModalBody"]

  connect() {
    this._setupSlider()
    this._highlightProcessSteps()

    setupModal("free-cost-analysis-modal")
    setupModal("request-modal")
  }

  _setupSlider() {
    $(".aboutus__slider").slick({
      infinite: true,
      dots: true,
      arrows: false,
      autoplay: true,
    })

    $(".slider__recent").slick({
      infinite: true,
      dots: false,
      arrows: false,
      autoplay: true,
    })
  }

  _highlightProcessSteps() {
    const processSteps = document.querySelectorAll(".process__step")
    const processFeatures = document.querySelectorAll(".process__features")

    processSteps[0].classList.add("process__step--active")
    processFeatures[0].classList.add("process__features--active")

    Array.prototype.slice.call(processSteps).forEach((element, index) => {
      element.addEventListener("mouseover", () => {
        processSteps.forEach((step) => {
          step.classList.remove("process__step--active")
        })
        processFeatures.forEach((feature) => {
          feature.classList.remove("process__features--active")
        })

        processSteps[index].classList.add("process__step--active")
        processFeatures[index].classList.add("process__features--active")
      })
    })
  }

  fetchRequestModal() {
    this._trackEvent("navigation", "requests", "create request")

    if (!this.requestModalBodyTarget.innerHTML) {
      this.railsFetch("/init_request_modal").then((result) => {
        this.requestModalBodyTarget.innerHTML = result.response
        this._requestModalActions()
      })
    } else {
      this._requestModalActions()
    }
  }

  fetchAnalysisModal() {
    this._trackEvent("navigation", "requests", "cost analysis")

    this.railsFetch("/init_free_cost_analysis_modal").then((result) => {
      document.querySelector(".free-cost-analysis-modal-body").innerHTML =
        result.response
      this._setupSearch()
      this._analysisModalActions()
    })
  }

  _requestModalActions() {
    const modalServices = Array.prototype.slice.call(
      document.querySelectorAll(".service__selection")
    )
    modalServices.forEach((service) => {
      service.addEventListener("click", () => {
        this.railsFetch(
          `${this.data.get("serviceUrl")}/select_service?id=${
            service.dataset.slug
          }`
        ).then((result) => {
          document.querySelector(
            ".modal-title"
          ).innerHTML = `Skapa förfrågan för ${service.dataset.title}!`
          document.querySelector(".modal-description").innerHTML = ""
          document.querySelector(".modal-container").innerHTML = result.form

          this._setupSearch()
        })
      })
    })
  }

  _analysisModalActions() {
    const form = document.querySelector("#kostnadsanalys__form")
    form.addEventListener("submit", (e) => {
      e.preventDefault()

      const data = new FormData(form)
      this.railsCreate(form.getAttribute("action"), data, true).then(
        (result) => {
          document.querySelector(".free_cost_analysis-content").innerHTML =
            result.response
        },
        (rejection) => {
          document.querySelector(
            ".free_cost_analysis_error_messages"
          ).innerHTML = JSON.parse(rejection.responseText).message
        }
      )
    })
  }

  _setupSearch() {
    const search = new AnbudskollenSearch()
    search.append()
  }

  _trackEvent(category, action, label) {
    if (typeof ga == "undefined") {
      return
    }

    ga("send", "event", category, action, label, {
      transport: "beacon",
      hitCallback: function () {
        console.log(action + " saved at " + label)
      },
    })
  }
}

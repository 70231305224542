export class ExpensesGraph {
  constructor(container) {
    this.container = container
    this.sortedData = []
  }

  assemble = (earnings, expenses) => {
    const dataArray = []
    const joinedArr = earnings.concat(expenses)
    const months = [
      "jan",
      "feb",
      "mars",
      "apr",
      "maj",
      "juni",
      "juli",
      "aug",
      "sep",
      "okt",
      "nov",
      "dec",
    ]

    joinedArr.map(([date, _value]) => {
      dataArray.push({
        month: date,
        earnings: 0,
        expenses: 0,
      })
    })

    const removeDuplicates = dataArray.reduce((arr, item) => {
      if (!arr.some((obj) => obj.month === item.month)) {
        arr.push(item)
      }
      return arr
    }, [])

    removeDuplicates.forEach((el) => {
      let sumEarnings = 0
      let sumExpenses = 0

      earnings
        .filter((dd) => dd[0] == el["month"])
        .forEach((_el) => (sumEarnings += _el[1]))
      expenses
        .filter((dd) => dd[0] == el["month"])
        .forEach((_el) => (sumExpenses += _el[1]))

      el["earnings"] = sumEarnings
      el["expenses"] = sumExpenses
    })

    this.sortedData = removeDuplicates.sort((a, b) => {
      return months.indexOf(a.month) - months.indexOf(b.month)
    }, [])
  }

  plot() {
    google.charts
      .load("visualization", { packages: ["corechart"] })
      .then(() => {
        this._drawChart()
      })
  }

  _toUpperCase(key) {
    return key.toString().charAt(0).toUpperCase() + key.slice(1)
  }

  _drawChart() {
    const labelsArr = [["MÅN", "Intäkter", "Kostnader"]]

    this.sortedData.map((el) => {
      const negativeExpenses =
        el["expenses"] > 0 ? -el["expenses"] : el["expenses"]
      const positiveEarnings = Math.abs(el["earnings"])

      labelsArr.push([
        this._toUpperCase(el["month"]),
        positiveEarnings,
        negativeExpenses,
      ])
    })

    const data = google.visualization.arrayToDataTable(labelsArr)
    const formatter = new google.visualization.NumberFormat({
      fractionDigits: 0,
      groupingSymbol: " ",
    })
    formatter.format(data, 1)
    formatter.format(data, 2)

    const chart = new google.visualization.ColumnChart(this.container)

    chart.draw(data, this._options())
  }

  _options() {
    return {
      width: 280,
      height: 275,
      isStacked: "true",
      colors: ["#3cc466", "#dc3545"],
      legend: "none",
      bar: {
        groupWidth: "50%",
      },
      vAxis: { format: "short" },
      seriesType: "bars",
      chartArea: {
        top: "8%",
        width: "70%",
        height: "65%",
      },
    }
  }
}

import * as Sentry from "@sentry/browser"

export function setupPremiseMap(id, options, localMapboxKey = null) {
  options = options || {}
  options.minZoom = 15

  const element = document.getElementById(id)
  let markers = {}

  try {
    markers = JSON.parse(element.dataset.markers) || options.markers
  } catch (_e) {
    markers = options.markers
  }

  if (element && element.dataset.boundaries) {
    options.maxBounds = JSON.parse(element.dataset.boundaries)
  }

  if (element && markers) {
    try {
      L.mapbox.accessToken = localMapboxKey || window.mapboxKey

      const markerIcon = {
        icon: L.icon({
          iconUrl: element.dataset.icon,
        }),
      }

      const mapboxContainer = L.mapbox.map(id, "mapbox.streets", options)

      mapboxContainer.setView(markers[0], 9)

      markers.forEach((marker) => {
        L.marker(marker, markerIcon).addTo(mapboxContainer)
      })

      mapboxContainer.fitBounds(markers)
      window.mapboxContainer = mapboxContainer
    } catch (error) {
      Sentry.captureException(error)
    }
  }
}

import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import * as Sentry from "@sentry/browser"

export default class extends ApplicationController {
  static targets = [
    "stageVerification",
    "stageDownload",
    "stageManualProcessing",
    "stageEmailDelivery",
    "artifactUrl",
    "artifactTitle",
  ]

  connect() {
    setupModal("brfanalys-modal")
    window.brfAnalysPolling = null
  }

  disconnect() {
    clearInterval(brfAnalysPolling)
  }

  download() {
    event.stopPropagation()

    this._downloadAnalys()
    this._clearPollingInterval()
  }

  create() {
    event.stopPropagation()

    this._createAnalys()
    this._downloadAnalys()
    this._clearPollingInterval()
  }

  _handleResponse(response) {
    response.then((value) => {
      try {
        switch (value.status) {
          case "idle":
          case "pending":
          case "scheduled":
          case "running":
            break
          case "delayed":
            clearInterval(brfAnalysPolling)

            this.stageVerificationTarget.classList.add("hidden")
            this.stageManualProcessingTarget.classList.remove("hidden")
            break
          case "done":
            clearInterval(brfAnalysPolling)

            this.stageVerificationTarget.classList.add("hidden")
            this.stageDownloadTarget.classList.remove("hidden")
            this.artifactUrlTarget.setAttribute("href", value.url)
            this.artifactTitleTarget.innerHTML = value.title
            break
          default:
            clearInterval(brfAnalysPolling)

            this.stageVerificationTarget.innerHTML = value.message
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    })
  }

  _downloadAnalys() {
    const url = this.data.get("statusUrl")

    window.brfAnalysPolling = setInterval(() => {
      const response = this.railsFetch(url)
      this._handleResponse(response)
    }, 1000)
  }

  _createAnalys() {
    const url = this.data.get("createUrl")
    const orgSlug = this.data.get("friendlyId")
    const response = this.railsCreate(url, { brfanalys_id: orgSlug })

    this._handleResponse(response)
  }

  _clearPollingInterval() {
    setTimeout(() => {
      clearInterval(window.brfAnalysPolling)

      if (!this.stageVerificationTarget.classList.contains("hidden")) {
        this.stageVerificationTarget.classList.add("hidden")
        this.stageEmailDeliveryTarget.classList.remove("hidden")
      }
    }, 20000)
  }
}

export function setupBrokerModal() {
  setupModal("broker-modal")
  const parsleyForm = $("#lead_generator-form").parsley()

  const fadeAndReplace = function (replaceWith) {
    $("#content-broker").fadeOut("slow", function () {
      $(this).replaceWith(replaceWith)
      $("#content-broker").fadeIn("slow")
    })
  }

  $("#lead_generator-form").on("submit", function (event) {
    const formData = $(event.target).serializeArray()
    if (!parsleyForm.isValid()) {
      parsleyForm.validate()
      return false
    }

    $.post(window.internal_broker_request_path, formData)
      .done(function (response) {
        const sucessMessage = $(
          "<div class='body'><p>Tack för din förfrågan<p><div class='modal_checkmark--green'>&#10003<div/>" +
            "<p>" +
            response.message +
            "</p></div>"
        )
        fadeAndReplace(sucessMessage)
      })
      .fail(function (response) {
        if (response.description == null) {
          const errorMessage = $(
            "<div class='component__body'><h3> Något gick fel </h3></div>"
          )
          fadeAndReplace(errorMessage)
          return
        }

        const errorMessage = $(
          "<div class='component__body'><h3>" + response.message + "</h3></div>"
        )
        fadeAndReplace(errorMessage)
      })

    return false
  })
}

import { BRFSearch } from "../../../../shared_assets/app/web_components/searchbar/search"
import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import { default as BrokerPropertySearch } from "../modules/broker_snapshots_properties_search"

export class BrokerSnapshotsBrfSearch extends BRFSearch {
  constructor(options) {
    super()

    this.propertyField = options.property
    this.propertiesUrl = options.propertiesUrl
  }

  _onResultSelection(inputElement, valueObj) {
    const orgNumber = valueObj.org_number
    document.querySelector("#brfmedlem_org_number").value = orgNumber
    inputElement.value = valueObj.name.replace(/<\/?em>/g, "")
    this.propertyField.value = ""
    const newSearch = this.propertySeach != null

    this._setupPropertySearch(newSearch, orgNumber)
  }

  _filter(_input, _value) {
    return false
  }

  _reset() {
    this._filter({
      selection: {
        value: "",
      },
    })
  }

  _setupPropertySearch(newSearch, orgNumber) {
    const url = this.propertiesUrl + `?org_number=${orgNumber}`

    new ApplicationController().railsFetch(url).then((result) => {
      if (result.data) {
        if (newSearch) {
          document
            .querySelectorAll(".autoComplete_listProperties")
            .forEach((el) => {
              el.remove()
            })
        }
        this.propertyField.disabled = false
        this.propertySeach = new BrokerPropertySearch(
          ".broker-property-autocomplete",
          {
            src: result.data,
            reset: this._reset.bind(this),
            disabled: false,
            newSearch: newSearch,
          }
        )
        this.propertySeach.dropdownEvents()
        this.propertySeach.append()
      }
    })
  }
}

import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  calculateTotalPages,
  tabulatorSV,
} from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { TabulatorFull as Tabulator } from "tabulator-tables"
import * as Sentry from "@sentry/browser"

import Litepicker from "litepicker"
import { format } from "date-fns"
import "ie11-custom-properties"

export default class extends ApplicationController {
  static targets = [
    "orgNumber",
    "alertContractsContainer",
    "togglerItem",
    "formSuccess",
    "formError",
    "deleteMessage",
    "loadingMessage",
    "failedToLoad",
    "submitButton",
  ]

  connect() {
    this._initializeTabulator()
    this._setUpLitePicker()
    this._toggleDocuments()
  }

  fileLoad(e) {
    const input = e.currentTarget

    if (input.files.length) {
      this.loadingMessageTarget.innerText = input.files[0].name + " har valts"
    } else {
      this.loadingMessageTarget.innerText =
        "välj fil eller dra och släpp fil här"
    }
  }

  beforeSubmit() {
    this.submitButtonTarget.disabled = true
    this.submitButtonTarget.classList.toggle("button--primary")
    this.submitButtonTarget.classList.toggle("button--tertiary")
  }

  addNewComplete() {
    this.submitButtonTarget.disabled = false
    this.submitButtonTarget.classList.toggle("button--primary")
    this.submitButtonTarget.classList.toggle("button--tertiary")
  }

  submitSuccess() {
    this.formSuccessTarget.classList.remove("hidden")

    setTimeout(() => {
      this.formSuccessTarget.classList.add("hidden")
    }, 10000)

    document.getElementById("contract-form").reset()
    this.loadingMessageTarget.innerText = "välj fil eller dra och släpp fil här"

    window.contractsTable.setData()
    this.datepicker.clearSelection()
  }

  submitError(event) {
    const [xhr] = event.detail
    const parsed = JSON.parse(xhr.response)
    const parsedResponse = parsed.errors[0].title
    this.formErrorTarget.querySelector("p").innerHTML += parsedResponse
    this.formErrorTarget.classList.remove("hidden")

    setTimeout(() => {
      this.formErrorTarget.classList.add("hidden")
    }, 10000)
  }

  deleteContract(e) {
    e.preventDefault()

    if (
      confirm(
        "Är du säker på att du vill ta bort avtalet från avtalsöversikten?"
      )
    ) {
      const url = e.currentTarget.getAttribute("href")
      const rowNumber = e.currentTarget.getAttribute("data-table-row")

      const response = this.railsDelete(url)

      response
        .then((value) => {
          if (value.message) {
            window.contractsTable.deleteRow(rowNumber)
            this.deleteMessageTarget.classList.add("success")
            this.deleteMessageTarget.classList.remove("hidden")
            this.deleteMessageTarget.querySelector("p").innerHTML =
              value.message

            document
              .querySelector(`[data-tabulator-row-id='${rowNumber}']`)
              .remove()

            if (!document.querySelectorAll("[data-tabulator-row-id]").length) {
              this.alertContractsContainerTarget.classList.add("hidden")
            }
          }
        })
        .catch((error) => {
          Sentry.captureException(error)
          this.deleteMessageTarget.classList.add("error")
          this.deleteMessageTarget.classList.remove("hidden")
          this.deleteMessageTarget.querySelector("p").innerHTML =
            error.errors[0]
        })

      setTimeout(() => {
        this.deleteMessageTarget.classList.add("hidden")
      }, 10000)
    }
  }

  _initializeTabulator() {
    const pageSize = 50

    const columnsLayout = [
      { title: "Leverantör", field: "description", frozen: true, width: 197 },
      { title: "Avtalstyp", field: "category" },
      {
        title: "Bundet till",
        field: "end_date",
        formatter: function (cell) {
          const data = cell.getData()
          return `<span style="${data.impending_renewal ? "color: red" : ""}">${
            data.end_date
          }</span>`
        },
      },
      {
        title: "Avtalat pris (ink moms)",
        field: "price",
        cssClass: "centerAlignedContent",
        formatter: function (cell) {
          const data = parseInt(cell.getValue())
          if (data) {
            return data.toLocaleString("sv-se", { useGrouping: true })
          } else {
            return "Rörligt"
          }
        },
      },
      {
        title: "Uppsägningstid",
        field: "notice_period",
        cssClass: "centerAlignedContent",
        formatter: function (cell) {
          const data = cell.getData()
          return data.notice_period + " mån"
        },
      },
      {
        title: "Dokument",
        field: "file",
        headerSort: false,
        cssClass: "centerAlignedContent",
        formatter: (cell, _formattedParams, _onRendered) => {
          const data = cell.getData()

          if (!data.contract_file.match(/#/)) {
            return `<a href='${data.contract_file}' target='_blank'><i class='icon--download'></i></a>`
          }
        },
      },
      {
        title: "",
        field: "destroy_path",
        headerSort: false,
        cssClass: "centerAlignedContent",
        formatter: (cell, _formattedParams, _onRendered) => {
          const data = cell.getData()
          const rowData = cell.getRow()

          return `<a href=${
            data.destroy_path
          } data-action='org-contract#deleteContract' data-table-row='${rowData.getIndex()}'><i class='icon--trashbin'></i></a>`
        },
      },
    ]

    const table = new Tabulator("#contract-table", {
      layout: "fitDataFill",
      initialSort: [{ column: "end_date", dir: "asc" }],
      ajaxURL: this.data.get("api-url"),
      ajaxResponse: (_url, _params, response) => {
        this._updateWarningMessage(response)

        const arr = []

        response.data.forEach((row) => {
          row.attributes.id = row.id
          arr.push(row.attributes)
        })

        return { last_page: calculateTotalPages(response, pageSize), data: arr }
      },
      ajaxError: (_xhr, _textStatus, _errorThrown) => {
        this._showError()
      },
      pagination: true,
      paginationMode: "remote",
      paginationSize: pageSize,
      ajaxProgressiveLoad: "scroll",
      columns: columnsLayout,
      locale: "sv",
      langs: tabulatorSV(),
    })

    window.contractsTable = table
  }

  _showError() {
    this.failedToLoadTarget.classList.remove("hidden")
  }

  _updateWarningMessage(response) {
    this.togglerItemTarget.innerHTML = ""

    response.data.forEach((row) => {
      const renewalsCount = response.meta.impending_renewals

      if (
        row.attributes.impending_renewal &&
        Date.parse(row.attributes.extension_date) >= Date.now()
      ) {
        this.alertContractsContainerTarget.classList.remove("hidden")
        this.alertContractsContainerTarget.querySelector(
          "p"
        ).innerHTML = `Det finns <strong>${renewalsCount}</strong> avtal som behöver ses över inom 2 månader`

        const listItem = document.createElement("li")
        listItem.setAttribute("data-tabulator-row-id", row.id)
        listItem.innerHTML = `<span>${row.attributes.description} måste sägas upp för omförhandling innan ${row.attributes.extension_date} för att inte automatiskt förlängas. <a href="${row.attributes.anbudskollen_url}">Få hjälp</a></span>`

        this.togglerItemTarget.appendChild(listItem)
      }
    })
  }

  _toggleDocuments() {
    document
      .querySelector(".toggler--contracts")
      .addEventListener("click", () => {
        const toggler = document.querySelector(".dropdown--contracts")
        if (toggler.classList.contains("__active")) {
          toggler.classList.remove("__active")
        } else {
          toggler.classList.add("__active")
        }
      })
  }

  _setUpLitePicker() {
    this.datepicker = new Litepicker({
      element: document.getElementById("litepicker"),
      lang: "sv-SV",
      firstDay: 1,
      minDays: 1,
      numberOfMonths: 2,
      numberOfColumns: 2,
      splitView: true,
      singleMode: false,
      showTooltip: true,
      mobileFriendly: true,
      zIndex: 9999999,
      minDate: new Date("2000-01-01"),
      startDate: new Date(),
    })
    this.datepicker.on("selected", this._formatDates.bind(this))
  }

  _formatDates(startDate, endDate) {
    document.getElementById("start_date").value = format(
      startDate.dateInstance,
      "yyyy-MM-dd"
    )
    document.getElementById("end_date").value = format(
      endDate.dateInstance,
      "yyyy-MM-dd"
    )
    // Trigger event to set "has_value" class. See DynamicLabelDecorator.
    document.getElementById("litepicker").dispatchEvent(new Event("input"))
  }
}

import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import { tabulatorSV } from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import {
  AjaxModule,
  DataTreeModule,
  DownloadModule,
  ExportModule,
  Tabulator,
} from "tabulator-tables"
import * as XLSX from "xlsx"

Tabulator.registerModule([
  AjaxModule,
  DataTreeModule,
  DownloadModule,
  ExportModule,
])

Tabulator.registerModule([AjaxModule, DataTreeModule])

export default class extends ApplicationController {
  static targets = ["period"]

  connect() {
    this.financialYears = JSON.parse(this.data.get("financial-years"))
    this.startDate = this.financialYears[this.periodTarget.value][0]
    this.endDate = this.financialYears[this.periodTarget.value][1]
    this.tabulator = this._initializeTabulator()
  }

  changePeriod(event) {
    this.startDate = this.financialYears[this.periodTarget.value][0]
    this.endDate = this.financialYears[this.periodTarget.value][1]
    const url = `${this.data.get("api-url")}&start=${this.startDate}&end=${
      this.endDate
    }`
    this.tabulator.setData(url)
  }

  _toLocateDateString(date) {
    return date.toLocaleDateString("sv-se")
  }

  _initializeTabulator() {
    const table = new Tabulator("#finance-balance", {
      height: "100%",
      layout: this._windowInnerWidth(),
      layoutColumnsOnNewData: true,
      dataTree: true,
      dataTreeStartExpanded: [true, false],
      dataTreeExpandElement: "<span class='icon-plus'></span>",
      dataTreeCollapseElement: "<span class='icon-minus'></span>",
      dataTreeBranchElement: false,
      ajaxResponse: this._transformResponse.bind(this),
      columns: [
        {
          title: "",
          field: "name",
          widthGrow: 2.2,
        },
        {
          title: "Ingående balans",
          field: "opening_balance",
          hozAlign: "left",
          widthGrow: 0.7,
          formatter: this._formatCurrency("opening_balance"),
        },
        {
          title: "Förändring",
          field: "balance_difference",
          hozAlign: "left",
          widthGrow: 0.7,
          formatter: this._formatCurrency("balance_difference"),
        },
        {
          title: "Utgående balans",
          field: "closing_balance",
          hozAlign: "left",
          widthGrow: 0.7,
          formatter: this._formatCurrency("closing_balance"),
        },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })

    table.on("tableBuilt", () => {
      const url = `${this.data.get("api-url")}&start=${this.startDate}&end=${
        this.endDate
      }`
      this.tabulator.setData(url)
    })
    table.on("dataTreeRowExpanded", function (row, level) {
      this.redraw() /*without this table cuts rows*/
    })

    this.balanceTable = table
    window.XLSX = XLSX
    return table
  }

  _formatCurrency(field) {
    return (cell, _formatterParams) =>
      Math.round(cell.getData()[field]).toLocaleString("sv-se", {
        useGrouping: true,
      })
  }

  _transformResponse(url, params, response) {
    const transformedResponse = response.data.attributes.items.map(
      this._transformItem.bind(this)
    )
    return transformedResponse
  }

  exportTable(event) {
    event.preventDefault()

    this.balanceTable.download("xlsx", "Balans.xlsx", {
      sheetName: "Balans",
    })
  }

  _transformItem(item) {
    let transformedItem = {
      name: item.data.attributes.name,
      opening_balance: item.data.attributes.opening_balance,
      balance_difference: item.data.attributes.balance_difference,
      closing_balance: item.data.attributes.closing_balance,
    }
    if (item.data.attributes.children) {
      transformedItem._children = item.data.attributes.children.map(
        this._transformItem.bind(this)
      )
    }
    return transformedItem
  }

  _windowInnerWidth() {
    const windowWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )
    if (windowWidth >= 768) {
      return "fitColumns"
    } else {
      return "fitDataFill"
    }
  }
}

import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import { setupFollowModal } from "../modules/setup_follow_modal"
import { setupBrokerModal } from "../modules/setup_broker_modal"
import { setupPremiseMap } from "../modules/setup_premise_map"
import { setupFeeSlider } from "../modules/setup_fee_slider"
import { setupFeeChart } from "../modules/setup_fee_chart"
import {
  setupBrokerCarousel,
  setupCarousel,
  setupPhotoCarousel,
} from "../modules/setup_carousel"
import { setupDocumentFiltering } from "../modules/setup_document_filtering"
import { setupApartmentsChart } from "../../../../shared_assets/app/webpack_assets/modules/setup_apartments_chart"
import { setupNeighborData } from "../../../../shared_assets/app/webpack_assets/modules/setup_neighbor_data"
import { updateSectionUrl } from "../modules/update_section_url"
import { scrollTo } from "../modules/setup_scroll"
import { setupModal } from "../../../../shared_assets/app/webpack_assets/shared/setup_modal"

export default class extends ApplicationController {
  static targets = [
    "mapBtn",
    "placeholderMap",
    "infoOrgButton",
    "infoApartmentsButton",
    "informationsParentContainer",
    "informationsContainer",
    "apartmentsContainer",
  ]

  connect() {
    this._setupOrganizationNavbar()
    setupFollowModal()

    this._initGoogleCharts()
    this._initSectionScripts()
  }

  displayApartments(event) {
    event.preventDefault()

    this.apartmentsContainerTarget.classList.add("apartments--fullview")
    this.apartmentsContainerTarget.classList.remove("apartmentsgradient")

    this._fadeOut(this.infoApartmentsButtonTarget)
  }

  enableMapScroll() {
    if (!window.mapboxContainer) {
      return
    }

    setTimeout(() => {
      window.mapboxContainer.dragging.enable()
      window.mapboxContainer.touchZoom.enable()
      window.mapboxContainer.doubleClickZoom.enable()
      window.mapboxContainer.scrollWheelZoom.enable()
    }, 1000)
  }

  disableMapScroll() {
    if (!window.mapboxContainer) {
      return
    }

    window.mapboxContainer.dragging.disable()
    window.mapboxContainer.touchZoom.disable()
    window.mapboxContainer.doubleClickZoom.disable()
    window.mapboxContainer.scrollWheelZoom.disable()
  }

  onClickLoadMap() {
    document.querySelector("#premise-map2").classList.remove("hidden")
    setupPremiseMap("premise-map2")
    this.mapBtnTarget.remove()
    this.placeholderMapTarget.remove()
  }

  _initSectionScripts() {
    setupFeeChart()
    setupFeeSlider()
    this._setupInformationsImagesModal()
    setupCarousel()
    setupBrokerCarousel()
    setupPhotoCarousel()
    setupBrokerModal()
    setupApartmentsChart()
    setupCarousel()
    setupNeighborData()
    this.disableMapScroll()
    setupModal("authentication-modal")
    setupDocumentFiltering()
  }

  _initGoogleCharts() {
    google.charts.load("current", {
      packages: ["corechart", "bar", "table"],
      language: "sv",
    })
  }

  _fadeOut(element) {
    element.style.transition = "0.7s"
    element.style.opacity = 0
  }

  _setupInformationsImagesModal() {
    setupModal("informations-images-modal")

    const imagePlaceholder = document.getElementById(
      "informations-images-image"
    )

    document
      .querySelectorAll(".informations-images-modal__button")
      .forEach((button) => {
        button.addEventListener("click", (event) => {
          const img = document.createElement("img")
          img.src = event.currentTarget.getAttribute("data-url")

          imagePlaceholder.innerHTML = ""
          imagePlaceholder.appendChild(img)
        })
      })
  }

  _setupOrganizationNavbar = () => {
    document
      .querySelectorAll("header .menu--submenu a")
      .forEach((tab, index) => {
        const path = tab.getAttribute("data-path")
        const section = document.querySelector(`section[data-name='${path}']`)

        if (tab.getAttribute("data-type") && section) {
          tab.addEventListener("click", (e) => {
            e.preventDefault()

            scrollTo(section)
            updateSectionUrl(path)

            this._highlightMenu()
          })
        }
      })
  }
}

export function sanitizeXlsxExport(sheet, headerColumns) {
  XLSX.utils.sheet_add_aoa(
    sheet,
    [headerColumns],
    {
      origin: 0,
    }
  )

  let range = XLSX.utils.decode_range(sheet['!ref'])
  for (let Row = range.s.r; Row <= range.e.r; ++Row) {
    for (let Column = range.s.c; Column <= range.e.c; ++Column) {
      let cellref = XLSX.utils.encode_cell({
        c: Column,
        r: Row,
      })

      if (!sheet[cellref]) continue

      let cell = sheet[cellref]

      if (cell.t === 'b') {
        cell.t = 's'
        cell.v = cell.v.toString()
      }

      if (!(cell.t === 's')) continue

      cell.v = cell.v.replace('[', '')
      cell.v = cell.v.replace(']', '')
      cell.v = cell.v.replace(/"/g, '');
      cell.v = cell.v.replace('null', '')
      cell.v = cell.v.replace(',null', '')
      cell.v = cell.v.replace('true', 'Ja')
      cell.v = cell.v.replace('false', 'Nej')

      if (cell.v == ',') cell.v = ''
    }
  }
}

import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  calculateTotalPages,
  tabulatorSV,
} from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { TabulatorFull as Tabulator } from "tabulator-tables"
import defaultURLGenerator from "tabulator-tables/src/js/modules/Ajax/defaults/urlGenerator"
import * as XLSX from "xlsx"

export default class extends ApplicationController {
  static targets = [
    "totalsContainer",
    "totalsCountPlaceholder",
    "total",
    "invoiceSum",
    "paid",
    "unpaid",
    "filterYears",
    "filterMonths",
  ]

  connect() {
    this._initializeTabulator()
    this._initializeYears()
    this._initializeMonths()
  }

  changeFilter() {
    this.table.setData()
  }

  exportTable(event) {
    event.preventDefault()

    const year = this.filterYearsTarget.value
    const month = this.filterMonthsTarget.value
    const organizationName = this.data.get("organizationName")
    const fileName = `Debiteringslista_${year}_${month}.xlsx`

    window.debitTable.addData([{}], true)
    window.debitTable.download("xlsx", fileName, {
      documentProcessing: function (workbook) {
        const sheet = workbook.Sheets.Sheet1
        const firstRow = `Debiteringslista ${year}-${month} for ${organizationName}`

        XLSX.utils.sheet_add_aoa(
          sheet,
          [
            [firstRow, "", "", "", "", ""],
            [
              "Period",
              "Förfallodatum",
              "Avimottagare",
              "OCR",
              "Fakturerat belopp",
              "Status",
            ],
          ],
          {
            origin: 0,
          }
        )

        const range = XLSX.utils.decode_range(sheet["!ref"])
        for (let row = range.s.r; row <= range.e.r; ++row) {
          for (let cell = range.s.c; cell <= range.e.c; ++cell) {
            const cellRef = XLSX.utils.encode_cell({
              c: cell,
              r: row,
            })

            if (!sheet[cellRef]) continue

            const cell = sheet[cellRef]

            if (!(cell.t === "s" || cell.t === "str")) continue
            if (cell.v === "overdue") cell.v = "Försenad"
            if (cell.v === "paid") cell.v = "Betald"
            if (cell.v === "unpaid") cell.v = "Obetald"
          }
        }

        return workbook
      },
    })

    window.debitTable.getRows()[0].delete()
  }

  _initializeYears() {
    let max = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    // If we are in december, we want to show the next year as well
    if (currentMonth === 12) {
      max = max + 1
    }
    const min = max - 2
    var select = this.filterYearsTarget

    for (var i = min; i <= max; i++) {
      var option = document.createElement("option")
      option.value = i
      option.innerHTML = i

      select.appendChild(option)
    }

    select.value = max
  }

  _initializeMonths() {
    var monthNames = [
      "Januari",
      "Februari",
      "Mars",
      "April",
      "Maj",
      "Juni",
      "Juli",
      "Augusti",
      "September",
      "Oktober",
      "November",
      "December",
    ]
    var select = this.filterMonthsTarget

    for (var i = 0; i < monthNames.length; i++) {
      var option = document.createElement("option")
      option.value = i + 1
      option.innerHTML = monthNames[i]

      select.appendChild(option)
    }
    select.value = new Date().getMonth() + 1
  }

  _initializeTabulator() {
    const pageSize = 25
    const url = this.data.get("api-url")
    const self = this

    const table = new Tabulator("#debit-table", {
      height: "100%",
      layout: "fitDataFill",
      layoutColumnsOnNewData: true,
      addRowPos: "top",
      ajaxURL: url,
      ajaxParams: {
        status: "paid,unpaid,overdue",
      },
      ajaxURLGenerator: function (url, config, params) {
        params.period = `${self.filterYearsTarget.value}-${self.filterMonthsTarget.value}`
        return defaultURLGenerator(url, config, params)
      },
      ajaxResponse: function (_url, _params, response) {
        self._printTotals(response)
        self.response = response

        self._populateFooter(response.meta)

        return {
          last_page: calculateTotalPages(response, pageSize),
          data: response.data.map((row) => row.attributes),
        }
      },

      // Pagination
      pagination: true,
      paginationMode: "remote",
      paginationSizeSelector: [25, 50, 100],
      paginationSize: pageSize,
      dataSendParams: { size: "page_size" },

      // Sorting
      sortMode: "remote",
      initialSort: [{ column: "period", dir: "asc" }],

      footerElement: this._footer(),

      columns: [
        {
          title: "Period",
          field: "period",
          width: 130,
          frozen: true,
        },
        {
          title: "Due Date",
          field: "due_date",
          visible: false,
          download: true,
          formatter: (cell, _formattedParams, _onRendered) => {
            const data = cell.getData()
            data.due_date = new Date(data.due_date).toLocaleDateString("sv-SV")
          },
        },
        {
          title: "Namn",
          field: "resident_name",
          width: 300,
        },
        {
          title: "ocr",
          field: "ocr",
          visible: false,
          download: true,
        },
        {
          title: "Belopp (SEK)",
          field: "price",
          widthGrow: 0.8,
          formatter: (cell, _formattedParams, _onRendered) => {
            const value = parseInt(cell.getValue())

            return value.toLocaleString("sv-se", {
              useGrouping: true,
            })
          },
        },
        {
          title: "Status",
          field: "status",
          width: 150,
          formatter: (cell, _formattedParams, _onRendered) => {
            switch (cell.getValue()) {
              case "overdue":
                return "<span class='overdue'></span><span>Försenad</span>"
              case "unpaid":
                return "<span class='unpaid'></span><span>Obetald</span>"
              case "paid":
                return "<span class='paid'></span><span>Betald</span>"
              default:
                return cell.getValue()
            }
          },
        },
        {
          title: "Avi",
          field: "preview_url",
          width: 150,
          download: false,
          headerSort: false,
          formatter: (cell, _formattedParams, _onRendered) => {
            return `<a href='${cell.getValue()}' target='_blank'><span class='invoice'></span></a>`
          },
        },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })
    this.table = window.debitTable = table
    window.XLSX = XLSX
  }

  _printTotals(response) {
    const meta = response.meta
    const showNotificatiion = !!(meta && meta.unpaid && meta.unpaid > 0)

    if (showNotificatiion) {
      this.totalsContainerTarget.classList.remove("hidden")
      this.totalsCountPlaceholderTarget.innerHTML = `${response.meta.unpaid} obetalda fakturor`
    } else {
      this.totalsContainerTarget.classList.add("hidden")
    }
  }

  _populateFooter(meta) {
    this.totalTarget.innerHTML =
      meta.total !== undefined
        ? meta.total.toLocaleString("sv-se", {
            useGrouping: true,
          })
        : ""
    this.invoiceSumTarget.innerHTML =
      meta.invoice_sum !== undefined
        ? meta.invoice_sum.toLocaleString("sv-se", {
            useGrouping: true,
          }) + " SEK"
        : ""
    this.paidTarget.innerHTML =
      meta.paid !== undefined
        ? meta.paid.toLocaleString("sv-se", {
            useGrouping: true,
          })
        : ""
    this.unpaidTarget.innerHTML =
      meta.unpaid !== undefined
        ? meta.unpaid.toLocaleString("sv-se", {
            useGrouping: true,
          })
        : ""
  }

  _footer() {
    return `<div class="footer">
      <div class='result'>
        Resultat
      </div>
      <div class='left-bill-block'>
        <ul>
          <li>
            <span class='bill'>Skickade fakturor under peroden:</span>
            <span data-board-finance-debit-target="total"></span>
          </li>
          <li>
            <span class='bill'>Total summa för perioden:</span>
            <span data-board-finance-debit-target="invoiceSum"></span>
          </li>
        </ul>
      </div>
      <div class='right-bill-block'>
        <div class='facture'>
          <ul>
            <li>
              <span class='bill'>Obetalda fakturor:</span>
              <span data-board-finance-debit-target="unpaid"></span>
            </li>
            <li>
              <span class='bill'>Betalda fakturor:</span>
              <span data-board-finance-debit-target="paid"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>`
  }
}

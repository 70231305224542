export const loadActiveTabContents = (tabName, containerName, initFunc) => {
  const parentContainer = document.querySelector(containerName).closest(".tab")

  document.querySelector(tabName).addEventListener("click", () => {
    setTimeout(() => {
      const { top, bottom } = parentContainer.getBoundingClientRect()

      if (window.innerHeight > top && bottom > 0) {
        initFunc()
      }
    }, 30)
  })
}

function drawFeeChart() {
  const data = new google.visualization.DataTable()
  const tooltipHtml = `
    <div class="{{graphType}}">
      <p>{{name}}</span></p>
      <p>Årsavgift per m² {{number}} kr</p>
    </div>`
  const tooltipTemplate = Handlebars.compile(tooltipHtml)

  data.addColumn("string", "name")
  data.addColumn("number", "Årsavgift per m²")
  data.addColumn({ type: "string", role: "style" })
  data.addColumn({ type: "number", role: "annotation" })
  data.addColumn({ type: "string", role: "tooltip", p: { html: true } })

  const orgFee = Math.round(window.graph_data.org_fee) || null
  const averageFee = Math.round(window.graph_data.average_fee) || null

  const contextBRF = {
    name: window.graph_data.simplified_name,
    number: orgFee,
    graphType: "context-brf",
  }
  const contextCity = {
    name: window.graph_data.county,
    number: averageFee,
    graphType: "context-city",
  }

  data.addRows([
    [
      window.graph_data.simplified_name,
      window.graph_data.org_fee,
      "#59277E",
      orgFee,
      tooltipTemplate(contextBRF),
    ],
  ])

  if (averageFee !== null) {
    data.addRows([
      [
        window.graph_data.county,
        window.graph_data.average_fee,
        "#D7D3C8",
        averageFee,
        tooltipTemplate(contextCity),
      ],
    ])
  }

  const options = {
    legend: "none",
    vAxis: {
      viewWindow: {
        min: 0,
      },
      gridlines: {
        color: "transparent",
      },
      textStyle: {
        color: "#666666",
        fontName: "Lato",
        fontSize: "14",
      },
      formatOptions: { groupingSymbol: " " },
      baselineColor: "#cccccc",
    },
    hAxis: {
      textStyle: {
        color: "#666666",
        fontName: "Lato",
        fontSize: "14",
      },
    },
    colors: ["#59277e", "#d7d3c8"],
    animation: {
      startup: true,
      duration: 500,
      easing: "out",
    },
    fontSize: "16",
    fontName: "Lato",
    tooltip: { isHtml: true },
  }

  const chart = new google.visualization.ColumnChart(
    document.getElementById("monthlyfee--graph")
  )
  chart.draw(data, options)

  window.onresize = () => {
    chart.draw(data, options)
  }
}

export function setupFeeChart() {
  if (!window.graph_data) {
    return
  }

  google.charts.setOnLoadCallback(drawFeeChart)
}

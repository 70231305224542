export class BlocksGenerator {
  constructor(apiData, container) {
    this.container = container
    this.apiData = apiData
  }

  _blockClassName(className) {
    return className === "__notice" ? className : "__blue"
  }

  build() {
    this.container.innerHTML = ""

    this.apiData.forEach((data) => {
      const [sort, amount, label, linkUrl, className] = data

      const block = document.createElement("div")
      block.classList.add(this._blockClassName(className))

      const link = document.createElement("a")
      link.setAttribute("href", linkUrl)

      const content = `
      <span class="arrow">→</span>
      <span class="title">${sort}</span>
      <span class="number">${this._parseValue(amount).toLocaleString("sv-se", {
        useGrouping: true,
      })}</span>
      <span class="label">${label}</span>
      `

      link.innerHTML = content
      if (className === "__disabled") link.classList.add(className)

      block.appendChild(link)

      this.container.appendChild(block)
    })
  }

  _parseValue(value) {
    return isNaN(parseInt(value)) ? "" : parseInt(value)
  }
}

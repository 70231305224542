export function setupGenderChart() {
  const females = $("#styrelse-donutchart--gender").data("females")
  const males = $("#styrelse-donutchart--gender").data("males")

  const sum = females + males
  let malePercentage = 0
  let femalePercantage = 0

  if (!(sum === 0)) {
    malePercentage = Math.round((males / sum) * 100)
    femalePercantage = Math.round((females / sum) * 100)
  }

  const femalesDisplayString = {
    displayString: "Andel kvinnor: " + femalePercantage + "%",
  }
  const maleDisplayString = {
    displayString: "Andel män: " + malePercentage + "%",
  }

  google.charts.setOnLoadCallback(drawChart)

  function drawChart() {
    const data = new google.visualization.DataTable()
    const tooltipHtml = "<span>{{displayString}}</span>"
    const tooltipTemplate = Handlebars.compile(tooltipHtml)

    data.addColumn("string", "gender")
    data.addColumn("number", "value")
    data.addColumn({ type: "string", role: "tooltip", p: { html: true } })

    data.addRows([
      [
        femalesDisplayString.displayString,
        females,
        tooltipTemplate(femalesDisplayString),
      ],
      [
        maleDisplayString.displayString,
        males,
        tooltipTemplate(maleDisplayString),
      ],
    ])

    const options = {
      pieHole: 0.7,
      pieSliceText: "none",
      colors: ["#CCCCCC", "#59277E"],
      legend: {
        textStyle: { fontSize: 15 },
        position: "bottom",
        alignment: "center",
      },
      chartArea: {
        right: 20,
        left: 20,
      },
      tooltip: { isHtml: true },
    }
    const chart = new google.visualization.PieChart(
      document.getElementById("styrelse-chart--gender")
    )
    chart.draw(data, options)
  }
}

export class BalancesGraph {
  constructor(container) {
    this.container = container
    this.arrayOfData = [
      ["Månad", "Utveckling", { label: "Balans", role: "tooltip" }],
    ]
  }

  plot() {
    google.charts
      .load("visualization", { packages: ["corechart"] })
      .then(() => {
        this._drawChart()
      })
  }

  assemble(time, value) {
    const balance = `Balans: ${value.toLocaleString("sv-se", {
      useGrouping: true,
    })} SEK (${new Date(time).toLocaleDateString("sv-se")})`

    this.arrayOfData.push([new Date(time), parseInt(value), balance])
  }

  _drawChart() {
    const data = new google.visualization.arrayToDataTable(
      this.arrayOfData,
      false
    )
    const formatter = new google.visualization.NumberFormat({
      fractionDigits: 0,
      groupingSymbol: " ",
    })

    formatter.format(data, 1)

    const chart = new google.visualization.LineChart(this.container)
    chart.draw(data, this._options(data))
  }

  _options(data) {
    const dateRange = data.getColumnRange(0)
    const oneMonth = 2629800000
    const ticksAxisH = []
    let month = -1

    for (
      let i = dateRange.min.getTime();
      i <= dateRange.max.getTime();
      i = i + oneMonth
    ) {
      const tick = new Date(i)
      if (month !== tick.getMonth()) {
        ticksAxisH.push({
          v: tick,
          f: translateMonth(tick.getMonth().toString()),
        })
        month = tick.getMonth()
      }
    }

    function translateMonth(index) {
      const months = [
        "Jan",
        "Feb",
        "Mars",
        "Apr",
        "Maj",
        "Juni",
        "Juli",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dec",
      ]

      return months[index]
    }

    return {
      width: 280,
      height: 250,
      hAxis: {
        ticks: ticksAxisH,
        textPosition: "bottom",
        textStyle: {
          fontSize: 10,
        },
      },
      vAxis: {
        viewWindow: {
          min: 0,
        },
        gridlines: { color: "#f0f0f0" },
        format: "short",
      },
      colors: ["#59277e"],
      legend: { position: "none" },
      curveType: "function",
      chartArea: {
        top: "8%",
        width: "70%",
        height: "70%",
      },
    }
  }
}

import { calculateTotalPages } from "../../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"

export const buildPaginatedResidentTable = async (apiUrl, table, pageSize) => {
  const transformResponse = (response) => {
    const arr = []

    response.data.forEach((row) => {
      row.attributes.id = row.id
      arr.push(row.attributes)
    })

    return arr
  }

  await initTableWithPagedData(table, apiUrl, transformResponse, pageSize)
}

export const initTableWithPagedData = async (
  table,
  apiUrl,
  transformResponse,
  pageSize
) => {
  const response = await fetch(apiUrl).then((res) => res.json())
  const totalPages = calculateTotalPages(response, pageSize)

  table.setData(transformResponse(response))

  for (let i = 2; i <= totalPages; i++) {
    fetch(apiUrl + `&page=${i}`).then((res) => {
      res.json().then((data) => {
        table.addData(transformResponse(data))
      })
    })
  }
}

import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  canAddObject,
  resetInfiniteScroll,
  switchClass,
} from "../../modules/board_room/switch_icon"
import Sortable from "sortablejs"

export default class extends ApplicationController {
  static targets = [
    "folderName",
    "errorText",
    "folderList",
    "dialogForm",
    "dialogEditForm",
    "footer",
    "folderNameUpdate",
    "reorderIcon",
    "orderUpdate",
    "pagination",
  ]

  connect() {
    setupModal("folder-modal")
    this.sortable = Sortable.create(this.folderListTarget, {
      onEnd: this._endSortable.bind(this),
      disabled: true,
    })
    this.orderByUpdate = false
    this.pages = this._pages()
  }

  onCreateFolder(event) {
    this._clearForm()
    const [data, status, xhr] = event.detail

    this._inquiryFormHandler(data.status, JSON.parse(data.response))
  }

  onBeforeEdit(e) {
    this.editRow = e.currentTarget.closest("tr")
  }

  onEditFolder(event) {
    event.preventDefault()
    const [data, status, xhr] = event.detail

    this.footerTarget.innerHTML = data.html
    this.dialogEditFormTarget.classList.toggle("falldown")
  }

  onUpdateFolder(e) {
    const [data, status, xhr] = e.detail

    if (data.status == 200 && this.editRow) {
      this.editRow.innerHTML = JSON.parse(data.response).html
      this.editRow = null
      this.dialogEditFormTarget.classList.toggle("falldown")
    }
  }

  onBeforeDelete(e) {
    this.elementToDelete = e.currentTarget.closest("tr")
  }

  onDelete(e) {
    const [data, status, xhr] = e.detail

    if (data.status == 204 && this.elementToDelete) {
      this.elementToDelete.remove()
      this.elementToDelete = null
    }
  }

  onCloseEditDialog(e) {
    e.preventDefault()
    this.dialogEditFormTarget.classList.toggle("falldown")
  }

  onSwitchReorder(e) {
    let state = this.sortable.option("disabled")

    switchClass(this.reorderIconTarget.classList, state)
    this.sortable.option("disabled", !state)
  }

  onOrderByUpdate(e) {
    this._switchSorting()

    const url =
      e.currentTarget.getAttribute("data-url") + `?order=${this.orderByUpdate}`

    this.railsFetch(url).then((result) => {
      this.folderListTarget.innerHTML = result.html
      resetInfiniteScroll(this, result.pagination)
    })
  }

  _switchSorting() {
    this.orderByUpdate = !this.orderByUpdate

    switchClass(this.orderUpdateTarget.classList, this.orderByUpdate)
  }

  _inquiryFormHandler(status, result) {
    if (status == 201) {
      if (canAddObject(this, this.folderListTarget))
        this.folderListTarget.innerHTML += result.html
      this.dialogFormTarget.classList.toggle("falldown")
      document.body.classList.toggle("falldown")
    } else {
      this._showError(JSON.stringify(result.message))
    }
  }

  _showError(message) {
    this.errorTextTarget.classList.remove("hidden")
    this.errorTextTarget.innerHTML =
      '<div class="body">' + "<p>" + message + "</p></div>"
  }

  _clearForm() {
    this.errorTextTarget.classList.add("hidden")
    this.errorTextTarget.innerHTML = ""
    this.folderNameTarget.value = ""
  }

  _endSortable(event) {
    const url = event.item.dataset.url
    const newIndex = event.newIndex + 1
    const payload = {}
    payload["position"] = newIndex

    return this.railsUpdate(url, payload)
  }

  _pages() {
    return this.paginationTarget.querySelector("a[rel='next']") == null ? 1 : 0
  }
}

import {
  AjaxModule,
  DataTreeModule,
  DownloadModule,
  EditModule,
  ExportModule,
  FilterModule,
  FormatModule,
  InteractionModule,
  Tabulator,
} from "tabulator-tables"

Tabulator.registerModule([
  AjaxModule,
  EditModule,
  InteractionModule,
  FilterModule,
  FormatModule,
  DataTreeModule,
  DownloadModule,
  ExportModule,
])

export default class {
  #elementId
  #ajaxURL
  #redrawOn
  #onDataProcessed
  #onCellEdited
  #onFiltering
  #onFiltered
  #hideZeroValues
  #expandedStatuses
  #layout

  constructor(elementId, url, options) {
    this.#elementId = elementId
    this.#ajaxURL = url
    if (options) {
      this.#redrawOn = options.redrawOn
      this.#onDataProcessed = options.onProcessed
      this.#onCellEdited = options.onCellEdited
      this.#onFiltering = options.onFiltering
      this.#onFiltered = options.onFiltered
      this.#hideZeroValues = options.hideZeroValues
      this.#layout = options.layout
    }
    this.connect()
  }

  connect() {
    let options = {
      ajaxURL: this.#ajaxURL,
      ajaxResponse: this._transformResponse.bind(this),
      rowFormatter: this._formatRow.bind(this),
      dataTree: true,
      dataTreeCollapseElement: "<span class='icon-minus'></span>",
      dataTreeExpandElement: "<span class='icon-plus'></span>",
      dataTreeBranchElement: false,
      dataTreeStartExpanded: this._isExpanded.bind(this),
      height: "100%",
      layout: this.#layout,
    }
    this.tabulator = new Tabulator(this.#elementId, options)
    this.tabulator.on("cellEdited", this._cellEdited.bind(this))
    this.tabulator.on("dataProcessed", this._dataProcessed.bind(this))
    this.tabulator.on("dataFiltering", this._dataFiltering.bind(this))
    this.tabulator.on("dataFiltered", this._dataFiltered.bind(this))
    this.tabulator.on("dataTreeRowExpanded", this._dataTreeExpanded.bind(this))
    this.tabulator.on(
      "dataTreeRowCollapsed",
      this._dataTreeCollapsed.bind(this)
    )
    this._setRedrawEvents()
  }

  _dataTreeExpanded(row, level) {
    this._writeExpandStatus(row.getData().id, true)
    this.tabulator.redraw()
  }

  _dataTreeCollapsed(row, level) {
    this._writeExpandStatus(row.getData().id, false)
    this.tabulator.redraw()
  }

  firstColumnValues() {
    return this.allDataFlat.map((row) => row[0])
  }

  setFilter(field, type, value) {
    if (value == "") {
      this.tabulator.clearFilter()
      this.tabulator.redraw()
    } else {
      this.tabulator.setFilter(this._filterTree.bind(this), {
        field: field,
        type: type,
        value: value,
      })
    }
  }

  _isExpanded(row, level) {
    return this._fetchExpandStatus(row.getData().id)
  }

  _fetchExpandStatus(rowId) {
    if (!this.#expandedStatuses) {
      this.#expandedStatuses = {}
    }
    return this.#expandedStatuses[rowId]
  }

  _writeExpandStatus(rowId, value) {
    if (!this.#expandedStatuses) {
      this.#expandedStatuses = {}
    }
    this.#expandedStatuses[rowId] = value
  }

  _filterTree(data, filter) {
    if (data[filter.field] === filter.value) {
      return true
    }

    if (data["_children"] && data["_children"].length > 0) {
      for (var i in data["_children"]) {
        if (this._filterTree(data["_children"][i], filter)) {
          this.tabulator.getRow(data.id).treeExpand()
          return true
        }
      }
    }

    return false
  }

  _setRedrawEvents() {
    if (this.#redrawOn) {
      let timeout = () => this.tabulator.redraw(true)
      let eventListener = () => setTimeout(timeout.bind(this), 30)
      document
        .querySelector(this.#redrawOn[0])
        .addEventListener(this.#redrawOn[1], eventListener.bind(this))
    }
  }

  _transformResponse(url, params, response) {
    if (response.data === undefined) return {}

    let headerRow = response.data.attributes.rows.filter(
      (r) => r.data.type === "header_row"
    )[0]
    let dataRows = response.data.attributes.rows.filter(
      (r) => r.data.type !== "header_row"
    )

    let columns = headerRow.data.attributes.cells.reduce(
      this._headersReducer.bind(this),
      []
    )
    this.tabulator.setColumns(columns)

    this.allDataFlat = dataRows.map(this._rowMapper.bind(this))
    let result = this.allDataFlat.reduce((accumulator, row) => {
      const key = row.parent_row_id || "none"
      if (!accumulator[key]) {
        accumulator[key] = []
      }
      accumulator[key].push(row)
      return accumulator
    }, {})
    result = result["none"].map((parentRow) => {
      parentRow._children = result[parentRow.id]
      return parentRow
    })
    return result
  }

  _headersReducer(object, cell) {
    let header = {
      field: cell.data.attributes.column_index.toString(),
      title:
        cell.data.attributes.value === "blank"
          ? ""
          : cell.data.attributes.value,
      editor: true,
      editable: this._editCheck,
    }
    if (cell.data.attributes.column_index === 0) {
      header.formatter = "html"
      header.tooltip = true
    } else {
      header.formatter = "money"
      header.formatterParams = {
        thousand: " ",
        precision: false,
      }
    }
    object.push(header)
    return object
  }

  _rowMapper(row) {
    let rowObject = row.data.attributes.cells.reduce(
      this._dataRowReducer.bind(this),
      { attributes: {} }
    )
    rowObject.id = row.data.id
    rowObject.type = row.data.type
    rowObject.parent_row_id = row.data.attributes.parent_row_id
    return rowObject
  }

  _dataRowReducer(object, cell) {
    let column = cell.data.attributes.column_index.toString()
    object[column] = cell.data.attributes.value

    object.attributes[column] = {
      cellId: cell.data.id,
      editable: cell.data.attributes.editable,
    }

    return object
  }

  _editCheck(cell) {
    let isEditable =
      cell.getData().attributes[cell.getColumn().getField()].editable
    return isEditable
  }

  _cellEdited(cell) {
    let self = this
    let value = cell.getValue()
    let cellId = cell.getData().attributes[cell.getColumn().getField()].cellId
    let formData = new FormData()
    formData.append("value", value)
    fetch(`/register/financials/budget_cells/${cellId}`, {
      method: "PATCH",
      body: formData,
    })
      .then((response) => response.json())
      .then((_) => {
        if (self.#onCellEdited) {
          self.#onCellEdited()
        }
        window.dispatchEvent(new CustomEvent("update-data"))
      })
  }

  setData(data) {
    this.tabulator.replaceData()
  }

  _dataProcessed() {
    if (this.#onDataProcessed) {
      this.#onDataProcessed()
    }
    // Custom row height CSS hides some table content. Redrawing fixes it
    this.tabulator.redraw()
  }

  _formatRow(row) {
    switch (row.getData().type) {
      case "group_row":
        row.getElement().classList.add("group")
        if (this.#hideZeroValues) {
          // Hide group row if all children zeros
          let children = row.getTreeChildren()
          const allZeros = children
            .map((row) => row.getCells().slice(1))
            .flat()
            .every((cell) => cell.getValue() === 0)
          if (allZeros) {
            row.getElement().classList.add("hidden")
          }
        }
        break
      case "result_row":
        row.getElement().classList.add("result")
        break
      default:
        if (this.#hideZeroValues) {
          // Hide row if all zeros
          const cellsWithValues = row.getCells().slice(1)
          const values = cellsWithValues.map((c) => c.getValue())
          if (values.every((v) => v === 0)) {
            row.getElement().classList.add("hidden")
          }
        }
    }
  }

  _dataFiltering(filters) {
    if (this.#onFiltering && filters.length !== 0) {
      this.tabulator
        .getRows()
        .forEach((row) => this.#onFiltering(row.getElement()))
    }
  }

  _dataFiltered(filters, rows) {
    if (this.#onFiltered && filters.length !== 0) {
      if (filters[0].value === "") {
        this.tabulator
          .getRows()
          .forEach((row) => this.#onFiltering(row.getElement()))
      } else {
        rows.map((row) => {
          const parentRow = row.getTreeParent()
          if (parentRow) {
            this.#onFiltered(parentRow.getElement())
          }
          this.#onFiltered(row.getElement())
        })
      }
      this.tabulator.redraw()
    }
  }
}

import { BRFSearch } from "../../../../shared_assets/app/web_components/searchbar/search"

export default class extends BRFSearch {
  constructor(selector, options) {
    super()

    this.selector = selector
    this.onSelection = options.onSelection
    this.src = options.src
    this.reset = options.reset
  }

  _inputChanged(event) {
    if (event.target.value === "") {
      this.reset()
    }
  }
}

import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import { tabulatorSV } from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { TabulatorFull as Tabulator } from "tabulator-tables"
import { PremiumsChart } from "../../modules/insurance/premiums_chart"
import { InsuranceGrid } from "../../modules/insurance/insurance_grid"
import * as Sentry from "@sentry/browser"

export default class extends ApplicationController {
  static targets = [
    "gridOne",
    "gridTwo",
    "insuranceHistory",
    "insuranceDamages",
    "premiumsChart",
    "failedToLoad",
  ]

  connect() {
    this._fetchApiData()
    this._initializeInsuranceTabulator()
    this._initializeDamagesTabulator()
    this._redrawOnActiveTab()
  }

  _initializeInsuranceTabulator() {
    const columnsLayout = [
      { title: "Startdatum", field: "starts_at", width: 150 },
      { title: "Slutdatum", field: "ends_at" },
      {
        title: "Bolag",
        field: "company",
        cssClass: "centerAlignedContent",
        formatter: function (cell) {
          const data = cell.getValue()
          if (data) {
            return data
          }
        },
      },
      {
        title: "Premie",
        field: "premium",
        cssClass: "centerAlignedContent",
        formatter: function (cell) {
          const data = parseInt(cell.getValue())
          return data.toLocaleString("sv-se", { useGrouping: true })
        },
      },
      {
        title: "Självrisk grund",
        field: "deductible",
        cssClass: "centerAlignedContent",
        formatter: function (cell) {
          const data = parseInt(cell.getValue())
          if (data) {
            return data.toLocaleString("sv-se", { useGrouping: true }) + " SEK"
          }
        },
      },
      {
        title: "Självrisk vatten",
        field: "deductible_water",
        cssClass: "centerAlignedContent",
        formatter: function (cell) {
          const data = parseInt(cell.getValue())
          if (data) {
            return data.toLocaleString("sv-se", { useGrouping: true }) + " SEK"
          }
        },
      },
      {
        title: "BRF-tillägg",
        field: "brf_supplement",
        cssClass: "centerAlignedContent",
        formatter: (cell, _formattedParams, _onRendered) => {
          let data = cell.getValue()
          data === true ? (data = "Ja") : (data = "Nej")
          return data
        },
      },
      {
        title: "Underlag",
        field: "document_url",
        headerSort: false,
        cssClass: "centerAlignedContent",
        formatter: (cell, _formattedParams, _onRendered) => {
          const data = cell.getValue()
          if (data) {
            return `<a href="${data}" target="_blank"><i class="icon--download"></i></a>`
          }
        },
      },
    ]

    const table = new Tabulator(this.insuranceHistoryTarget, {
      layout: "fitDataFill",
      layoutColumnsOnNewData: true,
      initialSort: [{ column: "starts_at", dir: "desc" }],
      columns: columnsLayout,
      locale: "sv",
      langs: tabulatorSV(),
    })

    window.insuranceTable = table
  }

  _initializeDamagesTabulator() {
    const columnsLayout = [
      { title: "Datum", field: "date_of_occurrence", widthGrow: 2 },
      {
        title: "Skadenummer",
        field: "external_ref",
        widthGrow: 2,
        formatter: function (cell) {
          const data = cell.getValue()
          if (data) {
            return data
          }
        },
      },
      {
        title: "Skadetyp",
        field: "kind",
        widthGrow: 2,
        formatter: function (cell) {
          const data = cell.getValue()
          if (data) {
            return data
          }
        },
      },
    ]

    const table = new Tabulator(this.insuranceDamagesTarget, {
      pagination: true,
      paginationMode: "local",
      paginationSize: 50,
      layout: "fitColumns",
      layoutColumnsOnNewData: true,
      initialSort: [{ column: "date_of_occurrence", dir: "desc" }],
      columns: columnsLayout,
      locale: "sv",
      langs: tabulatorSV(),
    })

    window.damagesTable = table
  }

  _redrawOnActiveTab() {
    document
      .querySelector("[data-tab=insurance-history]")
      .addEventListener("click", () => {
        setTimeout(() => {
          window.insuranceTable.redraw(true)
        }, 30)
      })

    document
      .querySelector("[data-tab=insurance-damages]")
      .addEventListener("click", () => {
        setTimeout(() => {
          window.damagesTable.redraw(true)
        }, 30)
      })
  }

  _lastTenItems(dataSet) {
    return dataSet.slice(Math.max(dataSet.length - 5, 0))
  }

  async _fetchApiData() {
    const [apiInsuranceData, apiDamagesData] = await Promise.all([
      this._insuranceCall(),
      this._damagesCall(),
    ])

    this._setupPremiumData(
      this._filterData(apiInsuranceData),
      this._filterData(apiDamagesData)
    )
    this._setupGridData(this._filterData(apiInsuranceData))

    window.insuranceTable.setData(
      this._lastTenItems(this._filterData(apiInsuranceData))
    )
    window.damagesTable.setData(this._filterData(apiDamagesData))
  }

  async _insuranceCall() {
    const apiData = []
    let keepGoing = true
    let page = 1

    try {
      while (keepGoing) {
        const respInsurance = await this.railsFetch(
          `${this.data.get("ins-api-url")}&page=${page}`,
          true
        )
        if (
          respInsurance.data.length <= respInsurance.meta.total &&
          respInsurance.data.length
        ) {
          keepGoing = true
        } else {
          keepGoing = false
        }

        await apiData.push.apply(apiData, respInsurance.data)
        page += 1
      }

      return apiData
    } catch (error) {
      Sentry.captureException(error)
      this.failedToLoadTarget.classList.remove("hidden")
    }
  }

  async _damagesCall() {
    const apiData = []
    let keepGoing = true
    let page = 1

    try {
      while (keepGoing) {
        const respDamage = await this.railsFetch(
          `${this.data.get("dam-api-url")}&page=${page}`,
          true
        )
        if (
          respDamage.data.length <= respDamage.meta.total &&
          respDamage.data.length
        ) {
          keepGoing = true
        } else {
          keepGoing = false
        }

        apiData.push.apply(apiData, respDamage.data)
        page += 1
      }

      return apiData
    } catch (error) {
      Sentry.captureException(error)
      this.failedToLoadTarget.classList.remove("hidden")
    }
  }

  _filterData(response) {
    const arr = []

    response.forEach((row) => {
      row.attributes.id = row.id
      arr.push(row.attributes)
    })

    return arr
  }

  _setupPremiumData(insurancedata, damagedata) {
    try {
      if ((insurancedata, damagedata)) {
        const premiumChart = new PremiumsChart(
          this.premiumsChartTarget,
          insurancedata,
          damagedata
        )

        premiumChart.prepareData()
        premiumChart.plot()
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  _setupGridData(data) {
    const insuranceGrid = new InsuranceGrid(
      this.gridOneTarget,
      this.gridTwoTarget,
      data
    )

    insuranceGrid.build()
  }
}

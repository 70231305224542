import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  dateFormatter,
  tabulatorSV,
} from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { TabulatorFull as Tabulator } from "tabulator-tables"

export default class extends ApplicationController {
  static targets = ["footer", "dialogModal"]

  connect() {
    this._initializeTabulator()
  }

  _initializeTabulator() {
    const pageSize = 50
    const table = new Tabulator("#board-parking-objects-table", {
      layout: "fitDataFill",
      layoutColumnsOnNewData: true,
      ajaxURL: this.data.get("api-url"),
      paginationDataSent: { size: "page_size" },
      ajaxResponse: (_url, _params, response) => {
        return this._transformResponse(response.properties, pageSize)
      },
      pagination: true,
      paginationMode: "local",
      paginationSize: pageSize,
      paginationSizeSelector: [10, 25, 50, 100],
      initialSort: [{ column: "localized_property_type", dir: "asc" }],
      columns: [
        { title: "Objekt", field: "property_number" },
        { title: "Adress", field: "address" },
        {
          title: "Avgift (SEK)",
          field: "combined_fee",
          hozAlign: "center",
          formatter: function (cell) {
            const data = cell.getData()

            if (data.combined_fee) {
              const int = parseInt(data.combined_fee, 10)
              return int.toLocaleString("sv-se", { useGrouping: true }) + " kr"
            } else {
              return ""
            }
          },
        },
        {
          title: "Innehavare",
          field: "resident_names",
          headerSort: false,
          hozAlign: "center",
          width: 150,
        },
        {
          title: "Startdatum",
          field: "bookingStart",
          formatter: dateFormatter,
          hozAlign: "center",
        },
        {
          title: "Uppsagd",
          headerSort: false,
          width: 150,
          formatter: (cell, _formattedParams, _onRendered) => {
            const data = cell.getData()

            if (data.bookingEnd) {
              return `Ja (${data.bookingEnd})`
            } else if (data.bookingStart) {
              return `
              <div class='parkings'>
                <span class='grey'>Nej</span>
                <a href='${data.edit_path}' data-action='board-register-parking#showDialog' class='button--icons'>
                  <span class='icon-uppsagd'></span>
                </a>
              </div>
            `
            }
          },
        },
        {
          title: "Avtal",
          headerSort: false,
          formatter: (cell, _formattedParams, _onRendered) => {
            const data = cell.getData()

            if (data.has_file == true) {
              return `
              <div class='parkings'>
                <a href='${data.file_path}' target=”_blank” class='button--icons'><span class='icon-doc'></span></a>
              </div>
            `
            } else if (data.show_path) {
              return `
              <div class='parkings'>
                <a href='${data.show_path}' class='button--icons' data-action='board-register-parking#showDialog'><span class='icon-doc'></span></a>
              </div>
            `
            }
          },
        },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })

    window.parkingsTable = table
  }

  showDialog(e) {
    e.preventDefault()

    const url = e.currentTarget.getAttribute("href")
    const response = this.railsFetch(url)

    response.then((value) => {
      this.footerTarget.innerHTML = value.html
      this.dialogModalTarget.classList.toggle("falldown")
    })
  }

  onCloseEditDialog(e) {
    e.preventDefault()
    this.dialogModalTarget.classList.toggle("falldown")
  }

  onUpdate(e) {
    const [data, status, xhr] = e.detail

    if (data.status == 200) {
      window.parkingsTable.replaceData()
      this.dialogModalTarget.classList.toggle("falldown")
    }
  }

  _transformResponse(response) {
    const arr = []

    response.data.forEach((row) => {
      row.attributes.bookingStart = row.attributes.parking_place.data
        ? row.attributes.parking_place.data.attributes.booking_start
        : null
      row.attributes.bookingEnd = row.attributes.parking_place.data
        ? row.attributes.parking_place.data.attributes.booking_end
        : null
      arr.push(row.attributes)
    })
    return arr
  }
}

import { default as BaseBudgetController } from "./register_budget_controller.js"
import * as XLSX from "xlsx"

export default class extends BaseBudgetController {
  static targets = ["isLoading", "searchContainer"]

  connect() {
    this.table = this._buildTable("budget-year", {
      hideZeroValues: true,
      layout: "fitDataFill",
    })
    this.searchContainerTarget.querySelector("input").disabled = true
    window.XLSX = XLSX
  }

  exportTable(event) {
    event.preventDefault()

    this.table.tabulator.download("xlsx", "Budget.xlsx")
  }
}

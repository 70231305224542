export function tabulatorSV() {
  return {
    sv: {
      ajax: {
        loading: "Laddar...",
        error: "Tekniskt fel. Försök igen senare.",
      },
      groups: {
        item: "item",
        items: "items",
      },
      pagination: {
        first: "Första",
        first_title: "Första sidan",
        last: "Sista",
        last_title: "Sista sidan",
        prev: "Föregående",
        prev_title: "Föregående sida",
        next: "Nästa",
        next_title: "Nästa sida",
        page_size: "Visa antal",
      },
      headerFilters: {
        default: "Filtrera",
        columns: {
          name: "Filtrera namn...",
        },
      },
    },
  }
}

export const selectColumns = (columns) => {
  const menu = []
  columns.shift()
  for (const column of columns) {
    //create checkbox element
    const icon = document.createElement("i")
    icon.classList.add("fas")
    icon.classList.add(column.isVisible() ? "chosen-dot" : "unchosen-dot")

    //build label
    const label = document.createElement("span")
    const title = document.createElement("span")

    title.textContent = " " + column.getDefinition().title

    label.appendChild(icon)
    label.appendChild(title)

    //create menu item
    menu.push({
      label: label,
      action: function (e) {
        //prevent menu closing
        e.stopPropagation()

        //toggle current column visibility
        column.toggle()

        //change menu item icon
        if (column.isVisible()) {
          icon.classList.remove("unchosen-dot")
          icon.classList.add("chosen-dot")
        } else {
          icon.classList.remove("chosen-dot")
          icon.classList.add("unchosen-dot")
        }
      },
    })
  }
  return menu
}

export const nullToEmptyString = (value, _data, _type, _params, _column) => {
  return value || ""
}

export function calculateTotalPages(response, pageSize) {
  const total = response.meta ? response.meta.total : response.length
  const numPages = (total + pageSize - 1) / pageSize
  return parseInt(numPages)
}

export function floatFormatter(cell, formatterParams, onRendered) {
  if (cell.getValue()) {
    return parseFloat(cell.getValue())
  }
  return ""
}

export function boolFormatter(cell, formatterParams, onRendered) {
  if (cell.getValue() === true) {
    return "Ja"
  } else {
    return "Nej"
  }
}

export function currencyFormatter(cell, formatterParams, onRendered) {
  if (cell.getValue()) {
    return cell.getValue().toLocaleString("sv-se", { useGrouping: true })
  } else {
    return ""
  }
}

export function dateFormatter(date) {
  if (date && date.getValue()) {
    return new Date(date.getValue()).toISOString().slice(0, 10)
  }
  return ""
}

export function minMaxFilterEditor(
  cell,
  onRendered,
  success,
  cancel,
  editorParams
) {
  var end

  var container = document.createElement("span")

  //create and style inputs
  var start = document.createElement("input")
  start.setAttribute("type", "number")
  start.setAttribute("placeholder", "Min")
  start.setAttribute("min", 0)
  start.setAttribute("max", 100)
  start.style.padding = "4px"
  start.style.width = "50%"
  start.style.boxSizing = "border-box"

  start.value = cell.getValue()

  function buildValues() {
    success({
      start: start.value,
      end: end.value,
    })
  }

  function keypress(e) {
    if (e.keyCode == 13) {
      buildValues()
    }

    if (e.keyCode == 27) {
      cancel()
    }
  }

  end = start.cloneNode()
  end.setAttribute("placeholder", "Max")

  start.addEventListener("change", buildValues)
  start.addEventListener("blur", buildValues)
  start.addEventListener("keydown", keypress)

  end.addEventListener("change", buildValues)
  end.addEventListener("blur", buildValues)
  end.addEventListener("keydown", keypress)

  container.appendChild(start)
  container.appendChild(end)

  return container
}

export function minMaxFilterFunction(
  headerValue,
  rowValue,
  rowData,
  filterParams
) {
  //headerValue - the value of the header filter element
  //rowValue - the value of the column in this row
  //rowData - the data for the row being filtered
  //filterParams - params object passed to the headerFilterFuncParams property

  if (rowValue) {
    if (headerValue.start != "") {
      if (headerValue.end != "") {
        return rowValue >= headerValue.start && rowValue <= headerValue.end
      } else {
        return rowValue >= headerValue.start
      }
    } else {
      if (headerValue.end != "") {
        return rowValue <= headerValue.end
      }
    }
  }

  return true //must return a boolean, true if it passes the filter.
}

export function presenceFilterFunction(
  headerValue,
  rowValue,
  rowData,
  filterParams
) {
  return !!rowValue === headerValue
}

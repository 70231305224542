import Litepicker from "litepicker"

export default class {
  constructor(elements, financialYears, minmaxBudgetYears, callback) {
    this.financialYears = financialYears
      .map((arr) => [new Date(arr[0]), new Date(arr[1])])
      .map((arr) => [
        // Need to respect timezone. Fin. year dates are considered to be in UTC,
        // but datepicker's dates are passes in current TZ. They both must be in
        // the same TZ.
        new Date(this._dateToTimeInCurrentTimezone(arr[0])),
        // Default time created from date is 00:00 which is beginning of day. We
        // want to include the whole day, so let's add 1 day minus 1 millisecond
        new Date(this._dateToTimeInCurrentTimezone(arr[1]) + 86_399_999),
      ])
    this._datepickerMinTime = this.getFinancialYearFor(
      new Date(minmaxBudgetYears[0])
    ).start
    this._datepickerMaxTime = this.getFinancialYearFor(
      new Date(minmaxBudgetYears[1])
    ).end
    this._callback = callback
    const pickerOptions = this._defaultOptions()
    this.startPicker = new Litepicker({
      ...pickerOptions,
      ...{ element: elements[0] },
    })
    this.endPicker = new Litepicker({
      ...pickerOptions,
      ...{ element: elements[1] },
    })
  }

  getDate() {
    return this.picker.getDate()
  }

  setDate(date) {
    this.picker.setDate(date)
  }

  getFinancialYearFor(date) {
    const financialYear = this.financialYears.find((fy) => {
      return date >= fy[0] && date <= fy[1]
    })
    if (financialYear) {
      return {
        start: financialYear[0],
        end: financialYear[1],
      }
    } else {
      const year = date.getFullYear()
      return {
        start: new Date(year, 0, 1),
        end: new Date(year, 11, 31, 23, 59, 59, 59),
      }
    }
  }

  _defaultOptions() {
    return {
      lang: "sv-SV",
      firstDay: 1,
      minDays: 1,
      numberOfMonths: 1,
      numberOfColumns: 1,
      singleMode: true,
      showTooltip: false,
      mobileFriendly: true,
      zIndex: 9999999,
      minDate: new Date(this._datepickerMinTime),
      maxDate: new Date(this._datepickerMaxTime),
      setup: (picker) => {
        picker.on("preselect", (date) => {
          this._pickerPreselected(picker, date)
        })

        picker.on("selected", (date) => {
          this._pickerDateSelected(picker)
        })
      },
    }
  }

  _dateToTimeInCurrentTimezone(date) {
    return date.getTime() + date.getTimezoneOffset() * 60_000
  }

  _pickerPreselected(picker, date) {
    this.pickerSelectInitiator = picker
    if (picker == this.startPicker) {
      this._adjustEndDate(date.dateInstance)
    } else {
      this._adjustStartDate(date.dateInstance)
    }
  }

  _adjustStartDate(endDate) {
    const startDate = this.startPicker.getDate().dateInstance
    const financialYear = this.getFinancialYearFor(endDate)
    if (
      startDate < financialYear.start ||
      financialYear.end < startDate ||
      startDate > endDate
    ) {
      this.startPicker.setDate(financialYear.start)
    }
  }

  _adjustEndDate(startDate) {
    const endDate = this.endPicker.getDate().dateInstance
    const financialYear = this.getFinancialYearFor(startDate)
    if (
      endDate < financialYear.start ||
      financialYear.end < endDate ||
      endDate < startDate
    ) {
      this.endPicker.setDate(financialYear.end)
    }
  }

  _pickerDateSelected(picker) {
    if (picker == this.pickerSelectInitiator) {
      this._callback()
      this.pickerSelectInitiator = undefined
    }
  }
}

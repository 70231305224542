export class DynamicLabelDecorator {
  constructor(inputs) {
    this.inputs = inputs
  }

  init(inputs) {
    ;(inputs || this.inputs).forEach((input) => {
      this.#decorate(input)
      input.addEventListener("input", () => {
        this.#decorate(input)
      })
      input.addEventListener("blur", () => {
        this.#decorate(input)
      })
      input.addEventListener("focusout", () => {
        this.#decorate(input)
      })
    })
  }

  #decorate(input) {
    if (input.value.length || input.dataset.hasValue == "true") {
      input.classList.add("has_value")
    } else {
      input.classList.remove("has_value")
    }
  }
}

export const residenceTemplate = (resident, property) => {
  const rooms = property.data.attributes.rooms
    ? property.data.attributes.rooms + " RoK"
    : ""
  const size = property.data.attributes.area
    ? property.data.attributes.area + "m" + "2".sup()
    : ""
  return `
  <h5 class='margin-t-24'>${property.data.attributes.address}</h5>
  <div>
    <ul class='margin-t-8'>
      <li>
        Objektsnummer:
        <span>${property.data.attributes.property_number}</span>
      </li>
      <li>
        Objekt:
        <span>${property.data.attributes.localized_property_type}</span>
      </li>
      <li>
        Storlek:
        <span>${size}</span>
      </li>
    </ul>
    <ul class='margin-t-8'>
      <li>
        Månadsavgift:
        <span>${Math.round(property.data.attributes.annual_fee / 12)} SEK</span>
      </li>
      <li>
        Antal rum:
        <span>${rooms}</span>
      </li>
    </ul>
    <ul class='margin-t-8'>
      <li>
        Delägare:
        <br>
        <span>${displayOwners(property)}</span>
      </li>
    </ul>
  </div>
  <div class="margin-t-8 checkbox clear">
    <input data-property-id="${
      property.data.id
    }" type="checkbox" id="make-billable-${property.data.id}" ${checkboxChecked(
    resident,
    property
  )} class="has_value" data-action="click->resident-payments#displayPaymentSection" data-resident-payments-target="makeBillable">
    <label for="make-billable"> Jag är betalningsansvarig för framtida avier</label>
  </div>
  `
}

export const otherTemplate = (resident, property) => {
  return `
  <h5 class='margin-t-24'>${property.data.attributes.address}</h5>
  <div>
    <ul class='margin-t-8'>
      <li>
        Objektsnummer:
        <span>${property.data.attributes.property_number}</span>
      </li>
      <li>
        Objekt:
        <span>${property.data.attributes.localized_property_type}</span>
      </li>
    </ul>
    <ul class='margin-t-8'>
      <li>
        Månadsavgift:
        <span>${Math.round(
          property.data.attributes.annual_lease_fee / 12
        )} SEK</span>
      </li>
    </ul>
    <ul class='margin-t-8'>
      <li>
        Delägare:
        <br>
        <span>${displayOwners(property)}</span>
      </li>
    </ul>
  </div>
  <div class="margin-t-8 checkbox clear">
    <input data-property-id="${
      property.data.id
    }" type="checkbox" id="make-billable-${property.data.id}" ${checkboxChecked(
    resident,
    property
  )} class="has_value" data-action="click->resident-payments#displayPaymentSection" data-resident-payments-target="makeBillable">
    <label for="make-billable"> Jag är betalningsansvarig för framtida avier</label>
  </div>
`
}

const displayOwners = (property) => {
  const payer = property.data.attributes.billable
  const owners = []

  property.included.forEach((p) => {
    if (p.id == payer) {
      owners.push(`${p.attributes.name} (betalningsansvarig)`)
    } else {
      owners.push(p.attributes.name)
    }
  })
  return owners.sort().join("<br>")
}

const checkboxChecked = (resident, property) => {
  return property.data.attributes.billable == resident.data.id
    ? "checked disabled=disabled"
    : ""
}

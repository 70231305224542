import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import { tabulatorSV } from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { TabulatorFull as Tabulator } from "tabulator-tables"

export default class extends ApplicationController {
  connect() {
    this._initializeTabulator()
    this._redrawOnActiveTab()
  }

  _initializeTabulator() {
    const pageSize = 50
    const table = new Tabulator("#board-parking-garage-table", {
      layout: "fitDataFill",
      layoutColumnsOnNewData: true,
      ajaxURL: this.data.get("api-url"),
      paginationDataSent: { size: "page_size" },
      ajaxResponse: (_url, _params, response) => {
        return this._transformResponse(response.queue, pageSize)
      },
      pagination: true,
      paginationMode: "local",
      paginationSize: pageSize,
      paginationSizeSelector: [10, 25, 50, 100],
      initialSort: [{ column: "localized_property_type", dir: "asc" }],
      columns: [
        { title: "Innehavare", field: "resident_name" },
        { title: "Placera", field: "position" },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })

    window.parkingGaragesTable = table
  }

  _transformResponse(response) {
    const arr = []

    response.data.forEach((row) => {
      arr.push(row.attributes)
    })
    return arr
  }

  _redrawOnActiveTab() {
    document
      .querySelector("[data-tab=parking-garages]")
      .addEventListener("click", () => {
        setTimeout(() => {
          window.parkingGaragesTable.redraw(true)
        }, 30)
      })
  }
}

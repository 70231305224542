export function switchClass(target, attr) {
  attr === true ? target.add("active") : target.remove("active")
}

export function resetInfiniteScroll(object, pagination) {
  let infinite = infiniteScrollController(object)
  infinite.loading = false
  infinite.paginationTarget.innerHTML = pagination
}

export function canAddObject(object, tbody) {
  const loading = infiniteScrollController(object).loading
  const trLength = tbody.getElementsByTagName("tr").length

  // if the number of lines is less than 15, will add a new item
  if (trLength < 15) {
    infiniteScrollController(object).loading = true

    return true
  }
  return loading == undefined || object.pages == 1 || loading == true
}

function infiniteScrollController(object) {
  return object.application.getControllerForElementAndIdentifier(
    object.element,
    "infinite-scroll"
  )
}

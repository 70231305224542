import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  calculateTotalPages,
  dateFormatter,
  tabulatorSV,
} from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { TabulatorFull as Tabulator } from "tabulator-tables"

export default class extends ApplicationController {
  connect() {
    this._initializeTabulator()
  }

  _initializeTabulator() {
    const table = new Tabulator("#surcharges-history-table", {
      layout: "fitColumns",
      layoutColumnsOnNewData: true,
      ajaxURL: this.data.get("api-url"),
      dataSendParams: { size: "page_size" },
      ajaxResponse: (_url, _params, response) => {
        return this._transformResponse(response)
      },
      pagination: true,
      paginationMode: "remote",
      paginationSize: 10,
      paginationSizeSelector: [10, 25, 50, 100],
      columns: [
        {
          title: "Datum skapat",
          field: "created_at",
          headerSort: false,
          formatter: dateFormatter,
        },
        {
          title: "Skapad av",
          field: "user",
          headerSort: false,
        },
        {
          title: "Avser",
          field: "residents",
          headerSort: false,
        },
        {
          title: "Objekt",
          field: "properties",
          headerSort: false,
        },
        {
          title: "Period",
          field: "start_date",
          headerSort: false,
        },
        {
          title: "Typ",
          field: "boardroom_kind",
          headerSort: false,
        },
        {
          title: "Belopp",
          field: "fee",
          headerSort: false,
          hozAlign: "right",
          formatter: "money",
          formatterParams: {
            symbol: " SEK",
            symbolAfter: true,
            precision: false,
            thousand: " ",
          },
        },
        {
          title: "Beskrivning",
          field: "description",
          headerSort: false,
        },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })

    this.surchargesHistoryTable = table
  }

  _transformResponse(response) {
    const pageSize = this.surchargesHistoryTable.getPageSize()
    const arr = []

    response.data.forEach((row) => {
      arr.push(row.attributes)
    })

    return { last_page: calculateTotalPages(response, pageSize), data: arr }
  }
}

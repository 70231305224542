export function setupNeighborData() {
  if (typeof window.neighborDataUrl == "undefined") {
    return
  }

  const parentContainer = document.getElementById("neighbor-data")
  if (parentContainer === null) {
    return
  }

  fetch(window.neighborDataUrl)
    .then((response) => response.text())
    .then((data) => {
      parentContainer.innerHTML = data

      if (parentContainer.querySelectorAll("ul li").length) {
        setupNeighborTabs()
      }
    })
}

const setupNeighborTabs = () => {
  const tabs = document.querySelectorAll("#neighbor-data li")
  const categories = [
    "kommunikationer",
    "rekreation",
    "skola",
    "ovrigt",
    "vatten",
  ]

  document
    .querySelector("#neighbor-data button")
    .addEventListener("click", () => {
      document.querySelector("#neighbor-data nav").classList.toggle("__active")
    })

  categories.forEach((category) => {
    const categoryTab = document.querySelector(
      `.neighbordata__tab--${category}`
    )
    if (!categoryTab) {
      return
    }

    categoryTab.addEventListener("click", (e) => {
      e.preventDefault()

      tabs.forEach((tab) => tab.classList.remove("__active"))
      e.currentTarget.classList.add("__active")

      document
        .querySelectorAll(".neighbordata__content")
        .forEach((content) =>
          content.classList.remove("neighbordata__content--visible")
        )
      document
        .querySelector(`.neighbordata__content--${category}`)
        .classList.add("neighbordata__content--visible")
    })
  })
}

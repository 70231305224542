import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"

export default class extends ApplicationController {
  static targets = ["entries", "pagination", "folderList"]

  initialize() {
    this.loading = false
  }

  scroll() {
    const body = document.body
    const html = document.documentElement
    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )

    if (window.pageYOffset >= height - window.innerHeight - 400) {
      if (this.loading) return
      this.loading = true
      this._loadMore()
    }
  }

  _loadMore() {
    let nextPage = this.paginationTarget.querySelector("a[rel='next']")

    if (nextPage == null) return

    const url = nextPage.href

    this.railsFetch(url).then((result) => {
      this.loading = false
      this.folderListTarget.insertAdjacentHTML("beforeend", result.html)
      this.paginationTarget.innerHTML = result.pagination
      if (result.pagy.page == result.pagy.pages) this.loading = true
    })
  }
}

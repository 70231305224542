/* eslint-disable no-restricted-globals */
export function updateSectionUrl(section) {
  const queryParamsIdx = location.href.indexOf("?")
  const queryParams =
    queryParamsIdx > -1 ? location.href.substring(queryParamsIdx) : ""
  const path =
    window.pagePath + (section.length != 0 ? `/${section}` : "") + queryParams

  // Only execute logic if we're actually changing page
  if (
    typeof window.location.href.endsWith !== "function" ||
    window.location.href.endsWith(path)
  ) {
    return
  }

  // Update history/current URL
  if (typeof history.pushState !== "undefined") {
    history.replaceState({}, "", path)
  } else {
    console.log("Browser does not support history")
  }

  // Trigger GoogleAnalytics page view
  if (typeof ga !== "undefined") {
    ga("send", "pageview")
  }
}

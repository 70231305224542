import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  calculateTotalPages,
  tabulatorSV,
} from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { TabulatorFull as Tabulator } from "tabulator-tables"
import { LoanTimeChart } from "../../modules/loan/loan_time_chart"
import { LoanPerSqmChart } from "../../modules/loan/loan_per_sqm_chart"
import * as Sentry from "@sentry/browser"

export default class extends ApplicationController {
  static targets = [
    "lastUpdatedAt",
    "averageMonthlyFixation",
    "averageLoanRate",
    "loanTimeChart",
    "loanPerSqmChart",
    "failedToLoad",
    "notCustomer",
  ]

  connect() {
    this._initializeTabulator()
  }

  print(e) {
    window.print()
  }

  _initializeTabulator() {
    const pageSize = 50

    new Tabulator("#brf-loans-table", {
      layout: "fitDataFill",
      printRowRange: "selected",
      addRowPos: "top",
      initialSort: [{ column: "amount", dir: "asc" }],
      ajaxURL: this.data.get("api-url"),
      ajaxResponse: (_url, _params, response) => {
        const arr = []

        response.data.forEach((row) => {
          row.attributes.id = row.id
          arr.push(row.attributes)
        })

        this._setupCustomerData(arr)
        return { last_page: calculateTotalPages(response, pageSize), data: arr }
      },
      ajaxError: (_xhr, _textStatus, _errorThrown) => {
        this.data.get("customer")
          ? this._showError()
          : this.notCustomerTarget.classList.remove("hidden")
      },
      ajaxConfig: {
        credentials: "include",
      },
      pagination: true,
      paginationMode: "remote",
      paginationSize: pageSize,
      columns: [
        {
          title: "Lånebelopp",
          field: "amount",
          frozen: true,
          formatter: function (cell) {
            const data = cell.getData()

            if (data.amount) {
              const int = parseInt(data.amount, 10)
              return int.toLocaleString("sv-se", { useGrouping: true }) + " SEK"
            } else {
              return "Okänd"
            }
          },
        },
        {
          title: "Ränta",
          field: "interest_rate",
          cssClass: "centerAlignedContent",
          formatter: function (cell) {
            const data = cell.getData()

            if (data.interest_rate) {
              return data.interest_rate + "%"
            } else {
              return "Okänd"
            }
          },
        },
        {
          title: "Kreditgivare",
          field: "lender",
          cssClass: "centerAlignedContent",
          formatter: (cell, _formattedParams, _onRendered) => {
            const data = cell.getData()

            if (data.lender) {
              return data.lender
            } else {
              return "Okänd"
            }
          },
        },
        {
          title: "Lånenummer",
          field: "external_ref",
          cssClass: "centerAlignedContent",
          formatter: (cell, _formattedParams, _onRendered) => {
            const data = cell.getData()

            if (data.external_ref) {
              return data.external_ref
            } else {
              return "Okänd"
            }
          },
        },
        {
          title: "Villkor",
          field: "months_fixation",
          cssClass: "centerAlignedContent",
          formatter: (cell, _formattedParams, _onRendered) => {
            const data = cell.getData()

            if (data.months_fixation) {
              if (data.months_fixation % 12 == 0) {
                return data.months_fixation / 12 + " år"
              } else {
                return data.months_fixation + " mån"
              }
            } else {
              return "Okänd"
            }
          },
        },
        {
          title: "Förfallodag",
          field: "due_date",
          cssClass: "centerAlignedContent",
          formatter: (cell, _formattedParams, _onRendered) => {
            const data = cell.getData()

            if (data.due_date) {
              return data.due_date
            } else {
              return "Okänd"
            }
          },
        },
        {
          title: "Räntekostnad",
          field: "interest_expense",
          cssClass: "centerAlignedContent",
          formatter: (cell, _formattedParams, _onRendered) => {
            const data = cell.getData()

            if (data.amount && data.interest_rate) {
              return (
                parseInt(
                  (data.amount * data.interest_rate) / 100
                ).toLocaleString("sv-se", { useGrouping: true }) + " SEK"
              )
            } else {
              return "Okänd"
            }
          },
        },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })
  }

  _setupCustomerData(response) {
    try {
      if (response) {
        this._lastUpdatedAt(response)
        this._averageLoanRate(response)
        this._averageMonthlyFixation(response)

        const loansChart = new LoanTimeChart(response, this.loanTimeChartTarget)
        loansChart.parseFixation()
        loansChart.plot()

        const loansSqmChart = new LoanPerSqmChart(
          response,
          this.loanPerSqmChartTarget,
          this.data.get("debt"),
          this.data.get("area")
        )
        loansSqmChart.prepareData()
        loansSqmChart.plot()
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  _showError() {
    this.failedToLoadTarget.classList.remove("hidden")
  }

  _lastUpdatedAt(data) {
    const updatedSFDates = []
    data.forEach((data) => {
      return updatedSFDates.push(data.updated_at)
    })

    if (updatedSFDates.length > 0) {
      this.lastUpdatedAtTarget.innerHTML = `Senast uppdaterad: ${updatedSFDates.pop()}`
      this.lastUpdatedAtTarget.classList.remove("hidden")
    }
  }

  _averageLoanRate(data) {
    const debt = JSON.parse(this.data.get("debt"))
    let timesRateAmount = 0
    let totalLoanAmount = 0

    data.forEach((data) => {
      timesRateAmount += parseInt(data.amount * data.interest_rate)
      totalLoanAmount += parseInt(data.amount)

      return { timesRateAmount, totalLoanAmount }
    })

    if ((timesRateAmount / totalLoanAmount).toFixed(2) > 0) {
      this.averageLoanRateTarget.innerHTML = `Genomsnittlig ränta: <strong>${(
        timesRateAmount / totalLoanAmount
      ).toFixed(2)}%</strong> (${new Date().getFullYear()})`
    } else {
      const lastAvailableYear = debt[debt.length - 1]

      if (lastAvailableYear) {
        this.averageLoanRateTarget.innerHTML = `Genomsnittlig ränta: <strong>${lastAvailableYear[3]}</strong> (${lastAvailableYear[0]})`
      } else {
        return (this.averageLoanRateTarget.innerHTML = "Data inte tillgänglig")
      }
    }
  }

  _averageMonthlyFixation(data) {
    let monthsFixation = 0

    data.forEach((data) => {
      monthsFixation += data.months_fixation
      return monthsFixation
    })

    if (data.every((month) => month === data[0]) && data[0] != undefined) {
      return (this.averageMonthlyFixationTarget.innerHTML = `Genomsnittlig bindingtid: <strong>${
        data[0]
      } mån</strong> (${new Date().getFullYear()})`)
    } else if (monthsFixation !== 0) {
      const count = data.filter((el) => el.months_fixation != undefined).length
      return (this.averageMonthlyFixationTarget.innerHTML = `Genomsnittlig bindingtid: <strong>${(
        monthsFixation / count
      ).toFixed(0)} mån</strong> (${new Date().getFullYear()})`)
    } else {
      return (this.averageMonthlyFixationTarget.innerHTML =
        "Data inte tillgänglig")
    }
  }
}

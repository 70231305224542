import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import { tabulatorSV } from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { TabulatorFull as Tabulator } from "tabulator-tables"

export default class extends ApplicationController {
  connect() {
    this._initializeTabulator()
  }

  _initializeTabulator() {
    const pageSize = 50
    const id = this.data.get("id")

    new Tabulator("#board-parking-storage-table-" + id, {
      layout: "fitDataFill",
      layoutColumnsOnNewData: true,
      ajaxURL: this.data.get("api-url"),
      paginationDataSent: { size: "page_size" },
      ajaxResponse: (_url, _params, response) => {
        return this._transformResponse(response.queue, pageSize)
      },
      pagination: true,
      paginationMode: "local",
      paginationSize: pageSize,
      paginationSizeSelector: [10, 25, 50, 100],
      initialSort: [{ column: "localized_property_type", dir: "asc" }],
      columns: [
        { title: "Innehavare", field: "resident_name", width: 150 },
        { title: "Placera", field: "position", width: 150 },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })
  }

  _transformResponse(response) {
    const arr = []

    response.data.forEach((row) => {
      arr.push(row.attributes)
    })
    return arr
  }
}

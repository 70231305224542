export class LoanTimeChart {
  constructor(apiData, container) {
    this.apiData = apiData
    this.container = container
    this.totalsByPeriod = {
      other: 0,
      3: 0,
      12: 0,
      24: 0,
      36: 0,
      48: 0,
      72: 0,
      84: 0,
    }
  }

  parseFixation() {
    Object.keys(this.totalsByPeriod).forEach((key) => {
      this.apiData
        .filter((row) => this._normalizeRowFixation(row.months_fixation) == key)
        .forEach((filtered) => {
          this.totalsByPeriod[key] =
            this.totalsByPeriod[key] + parseInt(filtered.amount)
        })
    })
  }

  plot() {
    if (this.apiData.length != 0) {
      google.charts
        .load("visualization", { packages: ["corechart"] })
        .then(() => {
          this._drawChart()
        })
    } else {
      this.container.innerHTML =
        "<div class='no--data'><p class='no-margin'>Läs mer om hur du får full tillgång till denna plattform<a href='https://sv.allabrf.se/brf-lan-gruppupphandling'> här</a></p></div>"
    }
  }

  _normalizeRowFixation(value) {
    return value !== null ? value : "other"
  }

  _drawChart() {
    let sumAboveFourYears = 0
    const years = [48, 72, 84]
    years.forEach((i) => (sumAboveFourYears += this.totalsByPeriod[i]))

    const data = google.visualization.arrayToDataTable([
      ["Bindningstid", "MÅN"],
      ["Okänd", this.totalsByPeriod["other"]],
      ["3 MÅN", this.totalsByPeriod["3"]],
      ["1 År", this.totalsByPeriod["12"]],
      ["2 År", this.totalsByPeriod["24"]],
      ["3 År", this.totalsByPeriod["36"]],
      ["4+ År", sumAboveFourYears],
    ])

    const formatter = new google.visualization.NumberFormat({
      fractionDigits: 0,
      groupingSymbol: " ",
    })
    formatter.format(data, 1)

    const options = {
      pieHole: 0.6,
      width: 400,
      height: 300,
      pieSliceText: "none",
      colors: [
        "#ded4e5",
        "#bda9cb",
        "#9b7db2",
        "#8b68a5",
        "#7a5298",
        "#59277e",
      ],
      legend: {
        textStyle: { fontSize: 13 },
        position: "bottom",
        alignment: "center",
      },
      chartArea: {
        right: 0,
        left: 0,
        top: "9%",
        width: "80%",
        height: "60%",
      },
    }

    const chart = new google.visualization.PieChart(this.container)
    chart.draw(data, options)
  }
}

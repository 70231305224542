import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import * as Sentry from "@sentry/browser"

export default class extends ApplicationController {
  static targets = [
    "statusPlaceholder",
    "form",
    "signButton",
    "cancelButton",
    "placeForm",
    "bodyContainer",
    "footerContainer",
    "signContainer",
  ]

  connect() {
    window.signPollingId = null
    window.bankidReference = null
  }

  sign(e) {
    e.preventDefault()

    this._disableSign()

    const formData = new FormData(this.formTarget)
    const response = this.railsCreate(this.data.get("url"), formData, true)

    this._handleSignResponse(response)
  }

  cancel() {
    clearInterval(signPollingId)

    const url = this.data.get("url") + "/cancel"
    this.railsCreate(url, { reference: bankidReference })

    this.statusPlaceholderTarget.innerText = ""

    this._enableSign()
  }

  _handleSignResponse(response) {
    response.then((value) => {
      try {
        switch (value.status) {
          case "unauthorized":
            return (window.location.href = this.data.get("auth-url"))
          case "failed":
            this.statusPlaceholderTarget.innerText = value.response.message
            this._enableSign()
            break
          case "success":
            const reference = value.response.order_ref

            this._pollStatus(reference, value.document_id)
            break
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    })
  }

  _pollStatus(reference, document_id) {
    const url = this.data.get("status-url")
    let i = 0

    signPollingId = setInterval(() => {
      const response = this.railsCreate(url, { reference: reference }, false)
      response.then((value) => {
        try {
          switch (value.status) {
            case "complete":
              let formData = new FormData(this.placeFormTarget)
              const placeUrl = this.data.get("place-url")

              formData.append("signing_id", document_id)

              const placeResponse = this.railsCreate(placeUrl, formData, true)

              this._handlePlaceSet(placeResponse)
              break
            case "failed":
              this._stopPolling(value.message)
              break
            case "pending":
              this.statusPlaceholderTarget.innerText = value.message
              break
            case "default":
              this._stopPolling("Någonting gick fel, prova igen")
              break
          }
        } catch (error) {
          Sentry.captureException(error)

          this._stopPolling("Någonting gick fel, prova igen")
        }
      })
      if (i === 60 * 3) {
        this._stopPolling("Någonting gick fel, prova igen")
      }
      i++
    }, 2000)
  }

  _handlePlaceSet(response) {
    response.then((value) => {
      window.currentContainer.innerHTML = value.html

      this._stopPolling("")
      this._handlePlaceInfo()
    })
  }

  _handlePlaceInfo() {
    const url = this.data.get("template-url")

    this.railsFetch(url).then((response) => {
      this.signContainerTarget.innerHTML = response.html
    })
  }

  _stopPolling(statusText) {
    clearInterval(signPollingId)

    this.statusPlaceholderTarget.innerText = statusText
    this._enableSign()
  }

  _disableSign() {
    this.signButtonTarget.classList.add("hidden")

    this.formTarget.classList.add("hidden")
    document
      .querySelector("#sign-document-container .spinner__background")
      .classList.remove("hidden")

    this.cancelButtonTarget.classList.remove("hidden")
    this.bodyContainerTarget.classList.add("hidden")
    this.footerContainerTarget.classList.add("hidden")
  }

  _enableSign() {
    this.signButtonTarget.classList.remove("hidden")

    this.formTarget.classList.remove("hidden")
    document
      .querySelector("#sign-document-container .spinner__background")
      .classList.add("hidden")

    this.cancelButtonTarget.classList.add("hidden")
    this.footerContainerTarget.classList.remove("hidden")
  }
}

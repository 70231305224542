import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import { setupNewOrganizationSlider } from "../modules/setup_carousel"

export default class extends ApplicationController {
  connect() {
    setupNewOrganizationSlider()
  }

  changeSectionTab(e) {
    e.preventDefault()

    document.querySelectorAll(".tabs__container a").forEach((el) => {
      el.classList.remove("active")
    })

    e.currentTarget.classList.add("active")

    const speculantsSlide = document.querySelector(
      ".tabs-carousel__slide.speculants"
    )
    const residentsSlide = document.querySelector(
      ".tabs-carousel__slide.residents"
    )
    const boardSlide = document.querySelector(".tabs-carousel__slide.board")
    const tabClass = e.currentTarget.getAttribute("data-slide-target")

    switch (tabClass) {
      case "speculants":
        speculantsSlide.style.transform = "translateX(0)"
        residentsSlide.style.transform = "translateX(100%)"
        boardSlide.style.transform = "translateX(200%)"
        break
      case "residents":
        speculantsSlide.style.transform = "translateX(-200%)"
        residentsSlide.style.transform = "translateX(-100%)"
        boardSlide.style.transform = "translateX(200%)"
        break
      case "board":
        speculantsSlide.style.transform = "translateX(-300%)"
        residentsSlide.style.transform = "translateX(-200%)"
        boardSlide.style.transform = "translateX(-200%)"
        break
      default:
        break
    }
  }
}

import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"

export default class extends ApplicationController {
  static targets = [
    "success",
    "failure",
    "payoutReviews",
    "submit"
  ]

  success(event) {
    let [data, _status, _xhr] = event.detail

    this.payoutReviewsTarget.innerHTML = data.html

    document.querySelector("#payout-request-modal .footer").remove()

    if (data.message !== undefined && data.message !== "") {
      this.successTarget.innerText = data.message
      this.successTarget.classList.remove("hidden")

      document.querySelector("#payout-request-modal .content").scrollTo(0, 0)
    }

    this.failureTarget.classList.add("hidden")
  }

  failure(event) {
    let [data, _status, _xhr] = event.detail

    if (data.message !== undefined && data.message !== "") {
      this.failureTarget.innerText = data.message
      this.failureTarget.classList.remove("hidden")

      document.querySelector("#payout-request-modal .content").scrollTo(0, 0)
    }
  }

  toggleSubmitButtons(event) {
    const confirmationCheckboxChecked = event.currentTarget.checked

    this.submitTargets.forEach((target) => {
      target.disabled = !confirmationCheckboxChecked
    })
  }
}

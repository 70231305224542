export class PremiumsChart {
  constructor(container, premiumData, damageData) {
    this.container = container
    this.premiumData = premiumData
    this.damageData = damageData
    this.labelsArr = []
    this.sortedData = []
  }

  prepareData() {
    this.labelsArr.push(["År", "Premie", "Skador"])

    this._graphDataset()
  }

  _getFullYear(el) {
    return new Date(el).getFullYear()
  }

  _lastFiveItems(dataSet) {
    return dataSet.slice(Math.max(dataSet.length - 5, 0))
  }

  _graphDataset = () => {
    const dataArray = []
    const joinedArr = this._lastFiveItems(this.damageData).concat(
      this._lastFiveItems(this.premiumData)
    )

    joinedArr.map((el) => {
      dataArray.push({
        year:
          this._getFullYear(el["starts_at"]) ||
          this._getFullYear(el["date_of_occurrence"]),
        premium: 0,
        damage_count: 0,
      })
    })

    const removeDuplicateYears = dataArray.reduce((arr, item) => {
      if (!arr.some((obj) => obj.year === item.year)) {
        arr.push(item)
      }
      return arr
    }, [])

    removeDuplicateYears.forEach((el) => {
      let sumDamages = 0
      let sumPremiums = 0

      this.damageData
        .filter(
          (dd) => this._getFullYear(dd["date_of_occurrence"]) == el["year"]
        )
        .forEach((_el) => (sumDamages += 1))
      this.premiumData
        .filter((dd) => this._getFullYear(dd["starts_at"]) == el["year"])
        .forEach((_el) => (sumPremiums += _el["premium"]))

      el["damage_count"] = sumDamages
      el["premium"] = sumPremiums
    })

    this.sortedData = removeDuplicateYears.sort((a, b) => {
      return a.year < b.year ? -1 : a.year > b.year ? 1 : 0
    }, [])
  }

  plot() {
    google.charts
      .load("visualization", { packages: ["corechart"] })
      .then(() => {
        this._drawChart()
      })
  }

  _drawChart() {
    this.sortedData.map((el) => {
      this.labelsArr.push([
        el["year"].toString(),
        el["premium"],
        el["damage_count"],
      ])
    })

    const data = new google.visualization.arrayToDataTable(this.labelsArr)
    const chart = new google.visualization.AreaChart(this.container)

    chart.draw(data, this._options(data))
  }

  _options(data) {
    const premiumMax =
      data.getColumnRange(1).max !== 0 ? data.getColumnRange(1).max : 5000
    const damagesMax =
      data.getColumnRange(2).max !== 0 ? data.getColumnRange(2).max : 10

    return {
      pointSize: 5,
      areaOpacity: 0,
      series: {
        0: { targetAxisIndex: 0, pointShape: "circle" },
        1: { targetAxisIndex: 1, pointShape: "circle" },
      },
      vAxes: [
        {
          viewWindowMode: "explicit",
          viewWindow: { min: 0, max: premiumMax },
          baseline: { color: "#F6F6F6" },
          format: "short",
          gridlines: { color: "#E5E5E5", count: 4 },
          minorGridlines: { color: "transparent" },
        },
        {
          viewWindowMode: "explicit",
          viewWindow: { min: 0, max: damagesMax },
          baseline: { color: "transparent" },
          format: "short",
          gridlines: { color: "transparent", count: 2 },
        },
      ],
      colors: ["#999999", "#59277e"],
      legend: {
        textStyle: { fontSize: 13 },
        position: "bottom",
        alignment: "center",
      },
      chartArea: {
        top: "9%",
        width: "80%",
        height: "55%",
      },
    }
  }
}

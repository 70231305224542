import * as Sentry from "@sentry/browser"
import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import { DynamicLabelDecorator } from "../../../../shared_assets/app/webpack_assets/shared/dynamic_label_decorator"

export default class extends ApplicationController {
  static targets = ["form", "saveButton", "containerDiv", "finishedDiv"]

  connect() {
    const inputs = document.querySelectorAll(".value-size-check input")
    const dynamicLabelDecorator = new DynamicLabelDecorator(inputs)

    setupModal("feature-modal")
    this._slideTitle()
    dynamicLabelDecorator.init()
  }

  save(e) {
    e.preventDefault()

    const formData = new FormData(this.formTarget)
    const url = this.formTarget.action
    const response = this.railsCreate(url, formData, true)

    this._handleSaveResponse(response)
  }

  _handleSaveResponse(response) {
    response.then((value) => {
      try {
        this._showFinished()
      } catch (error) {
        Sentry.captureException(error)
      }
    })
  }

  _showFinished() {
    this.finishedDivTarget.classList.remove("hidden")
    this.containerDivTarget.classList.add("hidden")
  }

  _slideTitle() {
    document.getElementById("feature-campaign-title").innerText =
      " Nu är det klart!"
  }
}

import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import * as Sentry from "@sentry/browser"

export default class extends ApplicationController {
  static targets = [
    "statusPlaceholder",
    "form",
    "footerContainer",
    "signButton",
    "cancelButton",
    "orgNumberField",
    "documentTypeField",
    "signedCallbackField",
    "signContainer",
  ]

  connect() {
    window.signPollingId = null
    window.bankidReference = null

    this._slideTitle()
  }

  sign(e) {
    e.preventDefault()

    this._disableSign()

    const formData = new FormData(this.formTarget)
    const response = this.railsCreate(this.data.get("url"), formData, true)

    this._handleSignResponse(response)
  }

  cancel() {
    clearInterval(signPollingId)

    const url = this.data.get("url") + "/cancel"
    this.railsCreate(url, { reference: bankidReference })

    this.statusPlaceholderTarget.innerText = ""

    this._enableSign()
  }

  _handleSignResponse(response) {
    response.then((value) => {
      try {
        switch (value.status) {
          case "unauthorized":
            return (window.location.href = this.data.get("auth_url"))
          case "failed":
            this.statusPlaceholderTarget.innerText = value.response.message
            this._enableSign()
            break
          case "success":
            bankidReference = value.response.order_ref

            this._pollStatus(bankidReference, value.document_id)
            break
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    })
  }

  _handleDocumentSigned(document_id) {
    const url =
      this.signedCallbackFieldTarget.value + "?document_id=" + document_id

    this.railsFetch(url).then((response) => {
      // Remove form
      this.signContainerTarget.children[0].remove()
      this.footerContainerTarget.remove()
      this.signContainerTarget.insertAdjacentHTML(
        "beforeend",
        response.body.innerHTML
      )
    })
  }

  _pollStatus(reference, document_id) {
    const url = this.data.get("url") + "/status"
    let i = 0

    signPollingId = setInterval(() => {
      const response = this.railsCreate(url, { reference: reference }, false)
      response.then((value) => {
        try {
          switch (value.status) {
            case "complete":
              this._stopPolling("")
              this._handleDocumentSigned(document_id)
              break
            case "failed":
              this._stopPolling(value.message)
              break
            case "pending":
              this.statusPlaceholderTarget.innerText = value.message
              break
            case "default":
              this._stopPolling("Någonting gick fel, prova igen")
              break
          }
        } catch (error) {
          Sentry.captureException(error)

          this._stopPolling("Någonting gick fel, prova igen")
        }
      })
      if (i === 60 * 3) {
        this._stopPolling("Någonting gick fel, prova igen")
      }
      i++
    }, 2000)
  }

  _stopPolling(statusText) {
    clearInterval(signPollingId)

    this.statusPlaceholderTarget.innerText = statusText
    this._enableSign()
  }

  _disableSign() {
    this.signButtonTarget.classList.add("hidden")

    this.formTarget.classList.add("hidden")
    document
      .querySelector("#sign-document-container .spinner__background")
      .classList.remove("hidden")

    this.footerContainerTarget.classList.remove("sign_document--sticky-footer")
    this.cancelButtonTarget.classList.remove("hidden")
  }

  _enableSign() {
    this.footerContainerTarget.classList.add("sign_document--sticky-footer")
    this.signButtonTarget.classList.remove("hidden")

    this.formTarget.classList.remove("hidden")
    document
      .querySelector("#sign-document-container .spinner__background")
      .classList.add("hidden")

    this.cancelButtonTarget.classList.add("hidden")
  }

  _slideTitle() {
    if (document.querySelector("#feature-modal .finished")) {
      document.getElementById("feature-campaign-title").innerText = "Signerat"
    } else {
      document.getElementById("feature-campaign-title").innerText =
        "Signera fullmakt"
    }
  }
}

import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"

export default class extends ApplicationController {
  static targets = [ "content" ]

  static values = {
    url: String,
  }

  #initClonableInputsEvent

  initialize() {
    this.#initClonableInputsEvent = new window.CustomEvent("InitClonableInputs")
  }

  connect() {
    this.show(this.urlValue)
  }

  showYear(event) {
    this.show(this.urlValue + "?year=" + event.params.year)
  }

  show(url) {
    this.railsFetch(url).then(
      ((response) => {
        this.contentTarget.innerHTML = response.body.innerHTML
        document.dispatchEvent(this.#initClonableInputsEvent)
      }).bind(this)
    )
  }

  onPostSuccess(event) {
    this.contentTarget.innerHTML = event.detail[0].body.innerHTML
    document.dispatchEvent(this.#initClonableInputsEvent)

    const firstErrorElement = this.element.querySelector("li.error")
    const scrollToOptions = { top: 0, left: 0, behavior: "smooth" }
    if (firstErrorElement) {
      scrollToOptions["top"] = firstErrorElement.offsetTop
      scrollToOptions["left"] = firstErrorElement.offsetLeft
    }
    window.scrollTo(scrollToOptions)
  }
}

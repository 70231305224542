import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import { DynamicLabelDecorator } from "../../../../../shared_assets/app/webpack_assets/shared/dynamic_label_decorator"

export default class extends ApplicationController {
  static targets = ["form", "billingAddressSwitch", "formSuccess", "formError"]

  connect() {
    const inputs = document.querySelectorAll(
      "#invoice-delivery .value-size-check input"
    )
    const dynamicLabelDecorator = new DynamicLabelDecorator(inputs)

    setupModal("invoice-delivery")
    dynamicLabelDecorator.init()

    this._activateFieldset()
  }

  switchBillingAddress() {
    document
      .querySelectorAll("fieldset[data-tab=mail] input:not([type=checkbox])")
      .forEach((el) => {
        el.toggleAttribute("disabled")
      })
  }

  switchFieldset(e) {
    const deliveryMethod = e.target.value
    this._activateFieldset(deliveryMethod)
  }

  submitSuccess() {
    this.formTarget.classList.add("hidden")
    this.formSuccessTarget.classList.remove("hidden")

    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  submitError() {
    this.formTarget.classList.add("hidden")
    this.formErrorTarget.classList.remove("hidden")

    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  _activateFieldset(deliveryMethod = null) {
    deliveryMethod = deliveryMethod
      ? deliveryMethod
      : this.formTarget.querySelector("input[checked]").value
    this.formTarget.querySelectorAll("fieldset").forEach((fieldset) => {
      if (fieldset.getAttribute("data-tab") == deliveryMethod) {
        fieldset.classList.remove("hidden")
      } else {
        fieldset.classList.add("hidden")
      }
    })
  }
}

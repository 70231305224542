/* eslint-disable no-param-reassign */
//
// Call this when you set up a new modal.
// params:
// modalName, name of modal. Note that id, buttonclass and modal closeid must have proper naming.
// options, onButtonClick is a customizable function. Example would be that we load in new content when activating the modal.
// default function is just to activate and display the modal.
//

export function setupModal(modalName, options) {
  const modal = document.getElementById(modalName)
  const body = document.querySelector("body")

  if (!modal) {
    return
  }

  // Default function for modal, can be overridden for custom modal load functions
  options = options || {}
  const onButtonClick =
    options.onButtonClick ||
    ((e) => {
      e.preventDefault()

      modal.classList.add("falldown")
      body.classList.add("falldown")
    })

  // Get the button that opens the modal
  // Construct is needed for IE, would complain otherwise
  const btnConstruct = `.${modalName}__button`
  const btns = document.querySelectorAll(btnConstruct)
  // Get the <span> element that closes the modal
  const closeButton = document.querySelectorAll(`.${modalName}__close`)
  const closeEvent = new window.CustomEvent("modal-closed")

  function handleCloseEvent(e) {
    // Prevent body from jumping on close
    e.preventDefault()

    modal.dispatchEvent(closeEvent)
    modal.classList.remove("falldown")
    body.classList.remove("falldown")
  }

  // When the user clicks on the button, open the modal
  Array.prototype.slice.call(btns).forEach((btn) => {
    btn.addEventListener("click", onButtonClick)
  })

  // When the user clicks on <span> (x), close the modal
  closeButton.forEach((button) => {
    button.addEventListener("click", (e) => {
      handleCloseEvent(e)
    })
  })

  document.addEventListener("keyup", (e) => {
    if (e.keyCode === 27) {
      if (options.preventClosingByKey) {
        return
      }
      handleCloseEvent(e)
    }
  })

  // When the user clicks anywhere outside of the modal, close it
  window.addEventListener("mousedown", (e) => {
    if (e.target === modal) {
      if (options.preventClosingFromOutside) {
        return
      }
      handleCloseEvent(e)
    }
  })
}

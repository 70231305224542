import { ApplicationController } from "../support/application_controller"
import * as Sentry from "@sentry/browser"

export default class extends ApplicationController {
  static targets = [
    "modal",
    "modalTitle",
    "modalBody",
    "modalFooter",
    "modalCloseConfirmation",
  ]

  connect() {
    const sliderButtons = document.querySelectorAll(
      "[data-feature-onboarding='true']"
    )
    for (const button of sliderButtons) {
      button.addEventListener("click", (e) => {
        e.preventDefault()

        this._assignDataFromTrigger(button)
        this._fetchCampaign(button)
      })
    }
  }

  embedExternalResource() {
    const response = this.railsFetch(this.data.get("external-resource-url"))
    response.then((value) => {
      if (value.status === "unauthorized") {
        this.modalBodyTarget.innerHTML =
          "Du behöver logga in med BankID för att få tillgång till denna funktion."
        this.modalBodyTarget.classList.add("text-center")
      } else {
        this.modalTarget.classList.add("embedded")
        this.modalBodyTarget.innerHTML = ""
        this.modalBodyTarget.insertAdjacentHTML(
          "beforeend",
          value.body.innerHTML
        )
      }
    })
  }

  openInnerModal(e) {
    e.preventDefault()
    this._toggleConfirmationForm()
  }

  closeInnerModal(e) {
    e.preventDefault()
    this._toggleConfirmationForm()
  }

  _toggleModal() {
    document.getElementById("feature-modal").classList.toggle("falldown")
    document.body.classList.toggle("falldown")
  }

  _fetchCampaign(button) {
    const url =
      this.data.get("fetchUrl") +
      `?feature_name=${button.getAttribute("data-slug")}`

    const response = this.railsFetch(url)
    this._handleResponse(response)
  }

  _handleResponse(response) {
    response.then((value) => {
      try {
        if (value.hasOwnProperty("content")) {
          this.modalBodyTarget.innerHTML = ""

          if (value.close_confirmation) {
            this._enableCloseConfirmation()
          } else {
            this._disableCloseConfirmation()
          }

          value.content.forEach((element, i) => {
            if (!element.title) {
              element.title = ""
            }

            const klass = i > 0 ? "hidden" : "active"
            const slideHTML = `<div class='onboarding_modal__body-slide ${klass}' data-title='${element.title}'>${element.contents}</div>`

            this.modalBodyTarget.insertAdjacentHTML("beforeend", slideHTML)
          })

          this._updateTitle(0)
          this.modalFooterTarget.classList.add("hidden")

          setupModal("feature-modal", {
            preventClosingFromOutside: true,
            preventClosingByKey: true,
          })

          this.modalTarget.classList.remove("embedded")
          this._toggleModal()
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    })
  }

  _assignDataFromTrigger(button) {
    if (button.getAttribute("data-url")) {
      this.data.set("external-resource-url", button.getAttribute("data-url"))
    }
  }

  _updateTitle(slideIndex) {
    const element = this._slideByIndex(slideIndex)
    this.modalTitleTarget.innerHTML = element.getAttribute("data-title")
  }

  _enableCloseConfirmation() {
    this.modalBodyTarget.setAttribute(
      "data-action",
      "click->feature-campaign#openInnerModal"
    )
    this.modalCloseConfirmationTarget.setAttribute(
      "data-action",
      "feature-campaign#openInnerModal"
    )
    this.modalCloseConfirmationTarget.classList.remove("feature-modal__close")
  }

  _disableCloseConfirmation() {
    this.modalBodyTarget.removeAttribute("data-action")
    this.modalCloseConfirmationTarget.removeAttribute("data-action")
    this.modalCloseConfirmationTarget.classList.add("feature-modal__close")
  }

  _slideByIndex(slideIndex) {
    return document.querySelectorAll(
      "#feature-modal .onboarding_modal__body-slide"
    )[slideIndex]
  }

  _toggleConfirmationForm() {
    document
      .querySelector(".feature_modal__close-confirmation")
      .classList.toggle("falldown")
  }
}

import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import { BRFSearch } from "../../../../shared_assets/app/web_components/searchbar/search"
import { setupNeighborData } from "../../../../shared_assets/app/webpack_assets/modules/setup_neighbor_data"

window.setupNeighborData = setupNeighborData

export default class extends ApplicationController {
  connect() {
    if (document.querySelector(".search-box")) {
      this._setupSearch()
    }
  }

  _setupSearch() {
    const search = new BRFSearch()

    search.dropdownEvents()
    search.append()
  }
}

// For the economy section slider that checks monthly fee * square meters
export function setupFeeSlider() {
  if ($("#slider-value").length > 0) {
    var slider = $("#fee_slider")
    var value = $("#slider-value")
    var size = $("#slider-size")

    slider.on("input", function (event) {
      var squaremeters = event.target.value
      var fee = value.data().feePerM2
      size.html(squaremeters)
      value.html(
        Math.round((fee * squaremeters) / 12.0).toLocaleString("sv-se", {
          useGrouping: true,
        }) + " kr"
      )
    })
  }

  if ($("#fee-slider").length > 0) {
    var slider = $("#fee-slider")

    updateSubscriptionSum(slider)

    slider.on("input", function (event) {
      if (slider.val() == "250") {
        togglePriceVisibility(true)
      } else {
        togglePriceVisibility(false)
        updateSubscriptionSum(slider)
      }
    })
  }

  function updateSubscriptionSum(slider) {
    var qty = slider.val()
    var per_unit = 30
    var base_fee = 4000

    var yearly_total = qty * per_unit + base_fee
    var total_sum = Math.ceil(yearly_total / 12 / qty)

    $("#per-year").html(yearly_total)
    $("#total").html(isFinite(total_sum) ? total_sum : 0)
  }

  function togglePriceVisibility(hidden) {
    if (hidden) {
      $(".range-item__result").addClass("hidden")
      $("#ring-for-offer").removeClass("hidden")
    } else {
      $(".range-item__result").removeClass("hidden")
      $("#ring-for-offer").addClass("hidden")
    }
  }
}

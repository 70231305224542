export function setupDocumentFiltering() {
  const highlightParent = (currentLink, targetLevel) => {
    const targetGroup = currentLink.getAttribute("data-access-group")
    const tabRow = currentLink.parentNode.parentNode.querySelectorAll(
      `.tabs__content[data-level=${targetLevel}]`
    )

    tabRow.forEach((tab) => {
      if (tab.getAttribute("data-access-group") == targetGroup) {
        tab.classList.remove("hidden")

        if (!tab.querySelectorAll(".tabs__link.__active").length) {
          const link = tab.querySelector(".tabs__link")
          if (link) {
            link.classList.add("__active")
          }
        }

        const subCategory = tab.querySelector(".tabs__link.__active")
        if (subCategory) {
          highlightChild(
            subCategory,
            subCategory.getAttribute("data-filtering")
          )
        }
      } else {
        tab.classList.add("hidden")
      }
    })
  }

  const highlightChild = (currentLink, filterGroup) => {
    const tabRow = currentLink
      .closest(".tabs__content")
      .querySelectorAll(".tabs__content")

    tabRow.forEach((tab) => {
      if (tab.getAttribute("data-filtering") == filterGroup) {
        tab.classList.remove("hidden")
      } else {
        tab.classList.add("hidden")
      }
    })
  }

  document.querySelectorAll(".tabs__link").forEach((link) => {
    link.addEventListener("click", (event) => {
      const currentLink = event.currentTarget
      const targetLevel = currentLink.getAttribute("data-level")
      const filterGroup = currentLink.getAttribute("data-filtering")

      document
        .querySelectorAll(`.tabs__link[data-level=${targetLevel}]`)
        .forEach((link) => {
          link.classList.remove("__active")
        })

      currentLink.classList.add("__active")

      if (targetLevel == "parent") {
        highlightParent(currentLink, targetLevel)
      } else if (targetLevel == "children" && filterGroup) {
        highlightChild(currentLink, filterGroup)
      }
    })
  })
}

import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  calculateTotalPages,
  dateFormatter,
  tabulatorSV,
} from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { TabulatorFull as Tabulator } from "tabulator-tables"
import defaultURLGenerator from "tabulator-tables/src/js/modules/Ajax/defaults/urlGenerator"

export default class extends ApplicationController {
  connect() {
    this._initializeTabulator()
  }

  changeFilter() {
    this.table.setData()
  }

  _initializeTabulator() {
    const pageSize = 25
    const url = this.data.get("api-url")
    const self = this

    const table = new Tabulator("#customer-fees-table", {
      height: "100%",
      layout: "fitDataFill",
      layoutColumnsOnNewData: true,
      addRowPos: "top",
      ajaxURL: url,
      ajaxURLGenerator: function (url, config, params) {
        return defaultURLGenerator(url, config, params)
      },
      ajaxResponse: function (_url, _params, response) {
        self.response = response

        return {
          last_page: calculateTotalPages(response, pageSize),
          data: response.data.map((row) => row.attributes),
        }
      },

      // Pagination
      pagination: true,
      paginationMode: "remote",
      paginationSizeSelector: [25, 50, 100],
      paginationSize: pageSize,
      dataSendParams: { size: "page_size" },

      footerElement: this._footer(),

      columns: [
        {
          title: "Datum",
          field: "created_at",
          formatter: dateFormatter,
        },
        {
          title: "Produkt",
          field: "customer_service_name",
        },
        {
          title: "Beskrivning",
          field: "description",
        },
        {
          title: "Pris per enhet (ex moms)",
          field: "customer_service_price_per_unit",
          hozAlign: "right",
          formatter: "money",
          formatterParams: {
            precision: false,
            thousand: " ",
          },
        },
        {
          title: "Antal",
          field: "service_units_count",
          hozAlign: "right",
        },
        {
          title: "Summa (inkl moms)",
          field: "amount_including_vat",
          hozAlign: "right",
          formatter: "money",
          formatterParams: {
            precision: false,
            thousand: " ",
          },
        },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })
    this.table = window.customerFeesTable = table
  }

  _footer() {
    return `<div class="footer">
    </div>`
  }
}

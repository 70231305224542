import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import autoComplete from "@tarekraafat/autocomplete.js"

export default class extends ApplicationController {
  static targets = [
    "form",
    "formSuccess",
    "formError",
    "propertyField",
    "propertyIdField",
  ]

  connect() {
    this.initializeAutoComplete()
  }

  submitSuccess() {
    this.formTarget.reset()
    this.formTarget.classList.add("hidden")
    this.formErrorTarget.classList.add("hidden")
    this.formSuccessTarget.classList.remove("hidden")

    setTimeout(() => {
      window.location.reload()
    }, 5000)
  }

  submitError() {
    this.formErrorTarget.classList.remove("hidden")
    window.scrollTo(0, 0)
  }

  initializeAutoComplete() {
    const apiUrl = this.propertyFieldTarget.dataset.apiUrl
    const autoCompleteJS = new autoComplete({
      data: {
        src: async (query) => {
          const response = await fetch(
            `${apiUrl}?query=${encodeURIComponent(query)}`
          )
          const parsedResponse = await response.json()
          const transformedResponse = this._transformResponse(parsedResponse)

          return transformedResponse
        },
        cache: false,
      },
      selector: "#surcharge-form__property-field",
      searchEngine: (_query, record) => {
        // https://github.com/TarekRaafat/autoComplete.js/issues/284
        // The results are already filtered in the backend.
        // That's why the search engine is disabled and the record is returned as it is.
        return record
      },
      debounce: 500,
      placeHolder: "Sök...",
      threshold: 2,
      resultsList: {
        render: true,
        tag: "ul",
        position: "afterend",
        noResults: true,
        class: "autoComplete_list",
        element: (list, data) => {
          if (!data.results.length) {
            const message = document.createElement("li")

            message.setAttribute("class", "no_result")
            message.setAttribute("tabindex", "1")
            message.innerHTML = "Inga resultat hittades."

            list.prepend(message)
          }
        },
      },
      resultItem: {
        element: (item, data) =>
          (item.innerHTML = this._propertyTitle(data.value)),
        tag: "li",
      },
    })
    autoCompleteJS.input.addEventListener("selection", (event) => {
      this.propertyFieldTarget.value = this._propertyTitle(
        event.detail.selection.value
      )

      this.propertyIdFieldTarget.value = event.detail.selection.value.id
    })
  }

  _transformResponse(response) {
    const transformedResponse = []
    response.data.forEach((row) => {
      transformedResponse.push(row.attributes)
    })
    return transformedResponse
  }

  _propertyTitle(value) {
    return [value.localized_property_type, value.external_id, value.residents].join(", ")
  }
}

import { default as BaseBudgetController } from "./register_budget_controller.js"

export default class extends BaseBudgetController {
  static targets = ["isLoading"]

  connect() {
    this.cashflowTable = this._buildTable("budget-cashflow", {
      uriAttrName: "cashflow-api-url",
      tabName: "budget-analysis",
      layout: "fitDataTable",
    })
    this.feeAnalysisTable = this._buildTable("budget-fee-analysis", {
      uriAttrName: "fee-analysis-api-url",
      tabName: "budget-analysis",
      layout: "fitDataTable",
    })
  }

  _setupSearch() {}

  exportTable(event) {
    event.preventDefault()

    const sheets = {
      kassaflödestabell: true,
      "Avgift Analystabell": this.feeAnalysisTable.tabulator,
    }

    this.cashflowTable.tabulator.download("xlsx", "Budgetanalys.xlsx", {
      sheets: sheets,
    })
  }

  updateData() {
    this.cashflowTable.setData()
    this.feeAnalysisTable.setData()
  }
}

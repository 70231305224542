/*
Setup the "follow organization" modal.
Visitors can follow organizations even if they don"t have an account or arent signed in.
To check against this (for a browser session at least), we store follow information in sessionStorage.

Note that the logic for checking if a user is logged in and has already subscribed is located in the relevant .haml file
*/
export function setupFollowModal() {
  var parsleyForm = $("#follow-form").parsley()

  $("#follow-modal-role").change(function () {
    if ($("#follow-modal-role").val() === "boardmember") {
      $("#bofokus-checkbox").removeClass("hidden")
      return
    }

    $("#bofokus-checkbox").addClass("hidden")
  })

  if (sessionStorage.getItem("following-" + window.orgSlug)) {
    disableFollowButton()
  } else {
    setupModal("follow-modal")

    if (
      !sessionStorage.getItem("following-" + window.orgSlug) &&
      !sessionStorage.getItem("autofollow")
    ) {
      try {
        window.openFollowModal = setTimeout(function () {
          var followButton = document.querySelector(".follow-modal__button")
          if (followButton) {
            followButton.click()
          }
        }, 15000)

        $(document).on("click", "follow-modal__button", function () {
          clearTimeout(openFollowModal)
        })

        sessionStorage.setItem("autofollow", true)
      } catch (error) {
        if (error.name !== "QuotaExceededError") {
          throw error
        }
      }
    }

    $("#follow-form").submit(function () {
      if (!parsleyForm.isValid()) {
        parsleyForm.validate()
        return false
      }

      // Pick up the URL from the form
      $.post($(this).attr("action"), $(this).serializeArray())
        .done(function (response) {
          $("#follow-contact-body").addClass("hidden")
          $("#follow-contact-response").removeClass("hidden")

          if (response.status == "error") {
            $("#follow-contact-response").html(
              '<div class="body">' + "<p>" + response.message + "</p></div>"
            )
          } else {
            $("#follow-contact-response").html(
              '<div class="body"><div class="modal_checkmark--green">&#10003;<div/>' +
                "<p>" +
                response.message +
                "</p></div>"
            )
            disableFollowButton()
          }

          try {
            sessionStorage.setItem("following-" + window.orgSlug, "true")
          } catch (error) {
            if (error.name !== "QuotaExceededError") {
              throw error
            }
          }
        })
        .fail(function (response) {
          if (response.responseJSON) {
            $(".follow__requesterror").html(response.responseJSON.text)
          }
        })

      return false
    })
  }
}

function disableFollowButton() {
  $(".follow-modal__button").prop("disabled", true).addClass("__disabled")
}

export class LoanPerSqmChart {
  constructor(apiData, container, yearlyDebt, totalArea) {
    this.apiData = apiData
    this.container = container
    this.yearlyDebt = JSON.parse(yearlyDebt)
    this.totalArea = totalArea
    this.arrayOfData = []
  }

  prepareData() {
    this.arrayOfData.push([
      "År",
      "Lån per m2",
      "Ränta",
      { label: "Ränta", role: "tooltip" },
    ])

    this._parseLoansFromKpis()
    this._parseLoansFromApi()
  }

  plot() {
    google.charts
      .load("visualization", { packages: ["corechart"] })
      .then(() => {
        this._drawChart()
      })
  }

  _parseYear() {
    return new Date().getFullYear().toString()
  }

  _parseLoansFromKpis() {
    this.yearlyDebt.forEach((arr) => {
      this.arrayOfData.push(arr)
    })
  }

  _parseLoansFromApi() {
    let loanSum = 0
    let averageInterestSum = 0

    this.apiData.forEach((row) => {
      loanSum += row.amount
      averageInterestSum += (row.interest_rate / 100) * row.amount
    })

    const debtPerSqm = parseInt(loanSum / this.totalArea)
    const interest = averageInterestSum / loanSum

    this.arrayOfData.push([
      this._parseYear(),
      debtPerSqm,
      interest,
      `${(interest * 100).toFixed(2)}%`,
    ])
  }

  _drawChart() {
    google.charts
      .load("visualization", { packages: ["corechart"] })
      .then(() => {
        const data = new google.visualization.arrayToDataTable(this.arrayOfData)
        const formatter = new google.visualization.NumberFormat({
          fractionDigits: 0,
          groupingSymbol: " ",
        })

        formatter.format(data, 1)

        const options = {
          seriesType: "bars",
          series: {
            0: { targetAxisIndex: 0 },
            1: { targetAxisIndex: 1, type: "line" },
          },
          vAxes: [
            {
              minValue: 0,
              format: "short",
              gridlines: { color: "#E5E5E5" },
              minorGridlines: { color: "transparent" },
            },
            {
              minValue: 0,
              format: "percent",
              gridlines: { color: "transparent" },
            },
          ],
          axes: {
            y: {
              1: {
                side: "right",
                label: "Ränta",
              },
            },
          },
          width: 400,
          height: 300,
          colors: ["#bda9cb", "#59277e"],
          legend: {
            textStyle: { fontSize: 13 },
            position: "bottom",
            alignment: "center",
          },
          bar: {
            groupWidth: "40%",
          },
          chartArea: {
            top: "8%",
            width: "80%",
            height: "55%",
          },
        }

        const chart = new google.visualization.ComboChart(this.container)
        chart.draw(data, options)
      })
  }
}

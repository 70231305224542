import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import { default as BoardBudgetTable } from "../modules/register/board_budget_table"
import { default as BudgetSearch } from "../../../../../shared_assets/app/web_components/searchbar/budget_search"

export default class extends ApplicationController {
  _buildTable(name, options = {}) {
    const uriAttrName = options.uriAttrName || "api-url"
    this.name = name
    return new BoardBudgetTable(`#${name}-table`, this.data.get(uriAttrName), {
      redrawOn: [`[data-tab=${options.tabName || name}]`, "click"],
      onProcessed: this._dataProcessed.bind(this),
      onFiltering: this._beforeFiltering.bind(this),
      onFiltered: this._filtered.bind(this),
      hideZeroValues: options.hideZeroValues,
      layout: options.layout,
    })
  }

  _dataProcessed() {
    this._hideProgress()
    this._setupSearch()
  }

  _hideProgress() {
    this.isLoadingTarget.classList.add("hidden")
  }

  _setupSearch() {
    const search = new BudgetSearch(`.${this.name}-autocomplete`, {
      onSelection: this._filter.bind(this),
      src: this.table.firstColumnValues(),
      reset: this._reset.bind(this),
    })

    search.dropdownEvents()
    search.append()
    this.searchContainerTarget.querySelector("input").disabled = false
  }

  _filter(_input, value) {
    this.table.setFilter("0", "like", value)
  }

  _beforeFiltering(rowElement) {
    rowElement.classList.remove("visible")
  }

  _filtered(rowElement) {
    rowElement.classList.add("visible")
  }

  _reset() {
    this._filter({ selection: { value: "" } })
  }

  updateData() {
    this.table.setData()
  }
}

/* eslint-disable class-methods-use-this */
import {Controller} from "stimulus"
import Rails from "@rails/ujs"

export class ApplicationController extends Controller {
  getControllerByIdentifier(identifier) {
    return this.application.controllers.find(
      (controller) => controller.context.identifier === identifier
    )
  }

  railsCreate(url, dataObj, isFormData = false) {
    return new Promise((resolve, reject) => {
      let data

      if (isFormData) {
        data = dataObj
      } else {
        data = new FormData()

        Object.entries(dataObj).forEach((entry) => {
          data.append(entry[0], entry[1])
        })
      }

      Rails.ajax({
        url,
        type: "POST",
        data,
        success: (response) => {
          resolve(response)
        },
        error: (_jqXHR, _textStatus, errorThrown) => {
          reject(errorThrown)
        },
      })
    })
  }

  railsUpdate(url, dataObj, isFormData = false) {
    return new Promise((resolve, reject) => {
      let data

      if (isFormData) {
        data = dataObj
      } else {
        data = new FormData()

        Object.entries(dataObj).forEach((entry) => {
          data.append(entry[0], entry[1])
        })
      }

      Rails.ajax({
        url,
        type: "PATCH",
        data,
        success: (response) => {
          resolve(response)
        },
        error: (_jqXHR, _textStatus, errorThrown) => {
          reject(errorThrown)
        },
      })
    })
  }

  railsDelete(url) {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        url,
        type: "DELETE",
        success: (response) => {
          resolve(response)
        },
        error: (_jqXHR, _textStatus, errorThrown) => {
          reject(errorThrown)
        },
      })
    })
  }

  railsFetch(url, with_credentials = false) {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        url,
        type: "GET",
        withCredentials: with_credentials,
        success: (response) => {
          resolve(response)
        },
        error: (_jqXHR, _textStatus, errorThrown) => {
          reject(errorThrown)
        },
      })
    })
  }
}

import { updateSectionUrl } from "./update_section_url"

export function setupScrollTop(scrollButton) {
  // For initialization, we must have an element where the display is _not_ set to none
  // This means that we have to setup all event handlers first, and _then_ set display:none (fadeOut) as the page loads.
  window.addEventListener("scroll", () => {
    if (window.pageYOffset > 350) {
      scrollButton.classList.remove("fadeOut")
    } else {
      scrollButton.classList.add("fadeOut")
    }
  })

  window.addEventListener("DOMContentLoaded", () => {
    scrollButton.addEventListener("click", () => {
      updateSectionUrl("")
      window.scrollTo({ top: 0, behavior: "smooth" })
    })

    // Sometimes you reload a page or go back, and you are already scrolled down a page as it loads, then we don't
    // want to fadeOut out the .scrollButton circle, so we check against pageYOffset one more time.
    if (window.pageYOffset <= 350) {
      scrollButton.classList.add("fadeOut")
    }
  })
}

export function scrollTo(element) {
  const bodyRect = document.body.getBoundingClientRect().top
  const elementRect = element.getBoundingClientRect().top
  const elementPosition = elementRect - bodyRect

  const headerOffset = document.querySelector("header").offsetHeight + 20

  window.scrollTo({ top: elementPosition - headerOffset, behavior: "smooth" })
}

import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import {
  nullToEmptyString,
  tabulatorSV,
} from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { TabulatorFull as Tabulator } from "tabulator-tables"
import * as XLSX from "xlsx"

export default class extends ApplicationController {
  static targets = ["depositsInTransit", "payoutsInTransit", "failedToLoad"]

  connect() {
    this.railsFetch(this.data.get("url")).then((response) => {
      if (response.data) {
        this._displaySumInTransit(response.in_transit)
        this._initializeTabulator(response.data)
      } else {
        this._showWarning()
      }
    })
  }

  _displaySumInTransit(in_transit) {
    if (in_transit.deposits_in_transit > 0) {
      const amount = parseFloat(in_transit.deposits_in_transit)
      this.depositsInTransitTarget.innerText = `Summa pågående inbetalningar: ${amount.toLocaleString(
        "sv-se",
        { useGrouping: true }
      )} SEK`
    }

    if (in_transit.payouts_in_transit > 0) {
      const amount = parseFloat(in_transit.payouts_in_transit)

      this.payoutsInTransitTarget.innerHTML = `Bekräftade utbetalningar: ${amount.toLocaleString(
        "sv-se",
        { useGrouping: true }
      )} SEK`
    }
  }

  _initializeTabulator(data) {
    this.depositTable = new Tabulator("#account-statement-table", {
      data: data,
      layout: "fitColumns",
      height: "100vh",
      columns: [
        {
          title: "Betaldatum",
          field: "paidAt",
          cssClass: "leftAlignedHeader",
          headerSort: false,
          formatter: function (cell) {
            const data = cell.getData()
            if (data.paidAt === "0001-01-01") {
              return "-"
            }
            return new Date(data.paidAt).toLocaleDateString("sv-se")
          },
        },
        {
          title: "Bokföringsdatum",
          field: "bookedAt",
          cssClass: "leftAlignedHeader",
          headerSort: false,
          formatter: function (cell) {
            const data = cell.getData()
            if (data.bookedAt === "0001-01-01") {
              return "-"
            }
            return new Date(data.bookedAt).toLocaleDateString("sv-se")
          },
        },
        {
          title: "Belopp",
          field: "amount",
          formatter: function (cell) {
            const data = cell.getData()
            const amount = parseFloat(data.amount)
            return (
              amount.toLocaleString("sv-se", { useGrouping: true }) + " SEK"
            )
          },
        },
        {
          title: "Typ",
          field: "paymentKind",
          cssClass: "leftAlignedHeader",
          formatter: function (cell) {
            const data = cell.getData()
            switch (data.paymentKind.toLowerCase()) {
              case "deposit":
                return "Inbetalning"
              case "withdrawal_reversal":
                return "Inbetalning"
              case "correction":
                return "Korrektion"
              case "payout":
              case "withdrawal":
                return "Utbetalning"
              default:
                return data.paymentKind
            }
          },
        },
        {
          title: "OCR / Referens",
          field: "description",
          accessorDownload: nullToEmptyString,
        },
        {
          title: "Mottagare / Betalare",
          field: "creditorName",
        },
        {
          title: "Saldo",
          field: "balance",
          formatter: function (cell) {
            const data = cell.getData()
            const amount = parseFloat(data.balance)
            return (
              amount.toLocaleString("sv-se", { useGrouping: true }) + " SEK"
            )
          },
        },
      ],
      locale: "sv",
      langs: tabulatorSV(),
    })
    window.XLSX = XLSX
  }

  exportTable(event) {
    event.preventDefault()

    const table = this.depositTable
    table.setPage(1).then(function () {
      table.download("xlsx", "Kontoutdrag.xlsx")
      table.clearData()
      table.restoreRedraw()
    })
  }

  _showError() {
    this.failedToLoadTarget.classList.remove("hidden")
  }

  _showWarning() {
    document.getElementById("data-load-warning").classList.remove("hidden")
  }
}

import * as Sentry from "@sentry/browser"
import { ApplicationController } from "../../../../../../app/webpack_assets/support/application_controller"
import { BlocksGenerator } from "../../modules/dashboard/blocks_generator"
import { ExpensesGraph } from "../../modules/dashboard/expenses_graph"
import { SpecificationsGraph } from "../../modules/dashboard/specifications_graph"
import { calculateTotalPages } from "../../../../../shared_assets/app/webpack_assets/shared/tabulator_customizations"
import { BalancesGraph } from "../../modules/dashboard/balances_graph"

export default class extends ApplicationController {
  static targets = [
    "dashboardBlocks",
    "chartExpenses",
    "costSpecification",
    "accountDevelopment",
    "failedToLoad",
  ]

  connect() {
    this._fetchApiData()
    this._initiateDashboardBlocks()
    this._plotBalanceHistory()
  }

  toggleMenuDetails(e) {
    const element = e.target.parentNode

    if (element.classList.contains("__active")) {
      element.classList.remove("__active")
    } else {
      element.classList.add("__active")
    }
  }

  async _fetchApiData() {
    const fromDate = this.data.get("transactions-monthly-from")
    const toDate = this.data.get("transactions-monthly-to")
    const apiTransactions = await this._fetchTransactions()

    this._expensesGraph(
      this.chartExpensesTarget,
      apiTransactions,
      fromDate,
      toDate
    )
    this._specificationsGraph(this.costSpecificationTarget, apiTransactions)
  }

  async _fetchTransactions() {
    const arr = []
    const apiData = []
    const paginatedRequests = []
    const pageSize = 100

    try {
      const response = await this.railsFetch(
        this._paginatedUrl(1, pageSize),
        true
      )

      apiData.push(response.data)
      const totalPages = calculateTotalPages(response, pageSize)

      for (let i = 2; i <= totalPages; i++) {
        let request = this.railsFetch(
          this._paginatedUrl(i, pageSize),
          true
        ).then((response) => response.data)

        paginatedRequests.push(request)
      }

      const allData = await Promise.all(paginatedRequests).then((responses) => {
        responses.forEach((response) => {
          apiData.push(response)
        })

        return apiData
      })

      allData.flat().forEach((row) => {
        row.attributes.id = row.id
        arr.push(row.attributes)
      })

      return arr
    } catch (error) {
      Sentry.captureException(error)
      this.failedToLoadTarget.classList.remove("hidden")
    }
  }

  _paginatedUrl(page, pageSize) {
    return `${this.data.get(
      "transactions-api-url"
    )}&page=${page}&page_size=${pageSize}`
  }

  _expensesGraph(container, apiData, fromDate, toDate) {
    const expensesGraph = new ExpensesGraph(container)
    const expenses = []
    const earnings = []

    if (apiData && apiData.length > 0) {
      apiData.forEach((value) => {
        if (value.registered_at >= fromDate && value.registered_at <= toDate) {
          if (value.kind === "income") {
            earnings.push([
              new Date(value.registered_at)
                .toLocaleDateString("sv-SV", { month: "short" })
                .split(".")
                .join("")
                .toLocaleLowerCase(),
              value.amount,
            ])
          }
          if (value.kind === "expense") {
            expenses.push([
              new Date(value.registered_at)
                .toLocaleDateString("sv-SV", { month: "short" })
                .split(".")
                .join("")
                .toLocaleLowerCase(),
              value.amount,
            ])
          }
        }
      })

      expensesGraph.assemble(earnings, expenses)
      expensesGraph.plot()
    } else {
      this.failedToLoadTarget.classList.remove("hidden")
    }
  }

  _specificationsGraph(container, apiData) {
    const todaysDate = new Date().toLocaleDateString("sv-SV")
    const dataUpUntilToday = []

    if (apiData && apiData.length > 0) {
      apiData.forEach((value) => {
        if (value.registered_at <= todaysDate) {
          dataUpUntilToday.push(value)
        }
      })

      const specificationsGraph = new SpecificationsGraph(container)
      specificationsGraph.assemble(dataUpUntilToday)
      specificationsGraph.plot()
    } else {
      this.failedToLoadTarget.classList.remove("hidden")
    }
  }

  _plotBalanceHistory() {
    let balanceHistory = this.data.get("balance-history")
    balanceHistory = JSON.parse(balanceHistory || "{}")

    const balanceGraph = new BalancesGraph(this.accountDevelopmentTarget)

    Object.keys(balanceHistory).forEach((key) => {
      if (balanceHistory[key] != null) {
        balanceGraph.assemble(key, balanceHistory[key])
      }
    })

    balanceGraph.plot()
  }

  _initiateDashboardBlocks() {
    const customerData = JSON.parse(this.data.get("blocks-options"))
    const blocksGenerator = new BlocksGenerator(
      customerData,
      this.dashboardBlocksTarget
    )

    blocksGenerator.build()
  }
}

import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import { setupModal } from "../../../../shared_assets/app/webpack_assets/shared/setup_modal"
import { toggleNavbar } from "../../../../shared_assets/app/web_components/navbar/navbar.js"

export default class extends ApplicationController {
  connect() {
    toggleNavbar()
    setupModal("authentication-modal")
    document.addEventListener("partial-fetched", () => {
      this._setupDynamicLabels()
    })
  }

  clickOrder(e) {
    if (!this._userSignedIn()) {
      e.preventDefault()

      this._toggleAuthModal()
      this._setRedirectAttributes("brokersnapshots-orders-new")
    }
  }

  clickTransfer(e) {
    if (!this._userSignedIn()) {
      e.preventDefault()

      this._toggleAuthModal()
      this._setRedirectAttributes("brokersnapshots-transfers-new")
    }
  }

  _toggleAuthModal() {
    document.querySelector("#authentication-modal").classList.toggle("falldown")
  }

  _setRedirectAttributes(data) {
    this._bankIdAuth().setAttribute("data-bankid-auth-section-name", data)
    this._emailAuth().setAttribute("data-email-auth-section-name", data)
  }

  _userSignedIn() {
    return this.data.get("user-signed-in") == "true"
  }

  _bankIdAuth() {
    return document.querySelector(".modal-authentication-bankid")
  }

  _emailAuth() {
    return document.querySelector(".modal-authentication-email")
  }
}

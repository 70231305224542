import * as Sentry from "@sentry/browser"
import { ApplicationController } from "../../../../../app/webpack_assets/support/application_controller"
import { setupPremiseMap } from "../modules/setup_premise_map"

export default class extends ApplicationController {
  static targets = [
    "mapContainer",
    "klarnaPlaceholder",
    "subscriptionFlag",
    "singleFlag",
  ]

  connect() {
    /* 
          Extract address from the URL.
    
          Example:
          URL: https://www.allabrf.se/brfanalys/svenska-folkbyggens-brf-nr-9-i-goteborg/purchase?source=30305&address=skillnadsgatan+60B,+göteborg&price=3290000&rooms=3.0&fee=3543&living_area=58.0
          address: skillnadsgatan+60B,+göteborg
    
          Regexp: /[?&]address=(.+?)(&|$)/
    
          [?&]  : If address is the first query param, it starts with ?. Otherwise with &.
          (.+?) : Non-greedy
          (&|$) : If address is the last query param, match the end ($). Otherwise it ends with &.
        */
    const address = /[?&]address=(.+?)(&|$)/.exec(location.search)?.[1]
    if (address) {
      this._initMapboxFromAddress(address)
    }

    this._fetchKlarnaUI(this.data.get("subscription"))
  }

  subscription() {
    this.subscriptionFlagTarget.classList.add("active")
    this._fetchKlarnaUI(this.data.get("subscription"))

    this.singleFlagTarget.classList.remove("active")
  }

  single() {
    this.singleFlagTarget.classList.add("active")
    this._fetchKlarnaUI(this.data.get("single"))

    this.subscriptionFlagTarget.classList.remove("active")
  }

  _initMapboxFromAddress(address) {
    const url = `${this.data.get(
      "mapboxHost"
    )}/mapbox.places/${address}.json?access_token=${this.data.get(
      "mapboxKey"
    )}&&bbox=${this.data.get("mapBoundaries")}`

    this.railsFetch(url).then((response) => {
      const matchingResult = response.features.sort((x, y) => {
        x.relevance - y.relevance
      })[0]

      setupPremiseMap(
        this.mapContainerTarget.id,
        { markers: [matchingResult.center.reverse()] },
        this.data.get("mapboxKey")
      )
    })
  }

  _fetchKlarnaUI(product) {
    const data = {
      org_number: this.data.get("orgNumber"),
      product: product,
      current_url: window.location.href,
      first_purchase: this.data.get("firstPurchase"),
      user_id: this.data.get("userId"),
      manual_capture: this.data.get("manualCapture"),
      source: this.data.get("source"),
    }

    let response

    switch (data.product) {
      case "analysis_recurring":
        response = this.railsCreate(this.data.get("klarnaSubUrl"), data)
        break
      case "analysis":
        response = this.railsCreate(this.data.get("klarnaUrl"), data)
        break
    }

    this._handleResponse(response)
  }

  _handleResponse(response) {
    response.then((value) => {
      try {
        $("#klarna-placeholder").html(value.html)
      } catch (error) {
        Sentry.captureException(error)
        this.klarnaPlaceholderTarget.innerHTML =
          "Något gick fel, prova att ladda om sidan."
      }
    })
  }
}
